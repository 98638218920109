import { endpoints, getRequest, postRequest, putRequest } from '../helpers';

// !=========================!
//   !TERMS AND CONDITIONS!
// !=========================!

/**
 * Fetch tnc
 */
export async function fetchTermsAndConditions() {
  return await getRequest(endpoints.TERMSANDCONDITIONS);
}

/**
 * Post a new tnc
 * @param {json} body - Stringified JSON data containing tnc key-values
 */
export async function postTermsAndConditions(body) {
  return await postRequest(endpoints.TERMSANDCONDITIONS, body);
}

/**
 * Put the tnc
 * @param {string} id - Id of the tnc. This is a UUID value.
 * @param {json} body - Stringified JSON data containing tnc key-values
 */
export async function putTermsAndConditions(id, body) {
  return await putRequest(`${endpoints.TERMSANDCONDITIONS}/${id}`, body);
}
