/**
 * Discount in percentage
 * @param {number} partialValue - Discounted value (lowest value, e.g. 75)
 * @param {number} totalValue - Full value (highest value, e.g. 100)
 */
export function discountPercentage(partialValue, totalValue) {
  return 100 - ((partialValue * 100) / totalValue).toFixed(0); // only integer values
}

/**
 * Discount in EUR
 * @param {number} partialValue - Discounted value (lowest value, e.g. 75)
 * @param {number} totalValue - Full value (highest value, e.g. 100)
 */
export function discountAmount(partialValue, totalValue) {
  return (totalValue - partialValue).toFixed(2); // x,xx
}

/**
 * Convert text to HTML
 * @param {string} text - Processes `&lt;`, `&gt;`, `&amp;` and whitespaces (`\r`, `\n`)
 */
export function textToHTML(text) {
  if (text === undefined) return '';
  return text
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/(?:\r\n|\r|\n)/g, '<br>');
}

/**
 *
 * @param {string} country - Full text representation of a country
 * @param {string} postal - Text representation of a postal
 * @param {number} totalWeight - Sum of all weights
 */
export function calculateLeverageCosts(country, postal, allB, totalWeight) {
  if (country === 'Netherlands') {
    if (totalWeight < 10) {
      return 0;
    } else if (totalWeight >= 10 && totalWeight < 50) {
      return allB ? 4.95 : 6.95;
    } else if (totalWeight < 110) {
      return allB ? 6.95 : 9.95;
    } else if (totalWeight < 150) {
      return allB ? 9.95 : 12.95;
    } else if (totalWeight < 1000) {
      return allB ? 14.95 : 22.95;
    } else {
      return allB ? 20 : 30;
    }
  } else if (country === 'Belgium') {
    if (totalWeight < 10) {
      return 0;
    } else if (totalWeight >= 10 && totalWeight < 50) {
      return 6.95;
    } else if (totalWeight < 110) {
      return 9.95;
    } else if (totalWeight < 150) {
      return 12.95;
    } else if (totalWeight < 1000) {
      return 22.95;
    } else {
      if (
        postal.startsWith('4') ||
        postal.startsWith('5') ||
        postal.startsWith('6') ||
        postal.startsWith('7') ||
        postal.startsWith('13') ||
        postal.startsWith('14') ||
        postal.startsWith('15') ||
        postal.startsWith('16')
      ) {
        return 40;
      } else {
        return 30;
      }
    }
  }
  return undefined;
}
