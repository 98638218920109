import React, { useState, createContext } from 'react';

export const FilterContext = createContext();

export function FilterProvider(props) {
  const [filter, setFilter] = useState({
    brand: '',
    model: '',
    type: '',
    categoryId: '',
    subcategoryId: '',
    carId: '',
  });

  return (
    <FilterContext.Provider value={[filter, setFilter]}>
      {props.children}
    </FilterContext.Provider>
  );
}
