import React from 'react';

/**
 * A Button component that also returns an onClick action
 * @param {string} text - Text displayed in the button
 * @param {boolean} center - Boolean whether the text should be center aligned or not. If false, text will be left aligned.
 */
export default function TextHead({ text, center }) {
  return (
    <div>
      <h1 className={'my-4 text-2xl ' + (center ? 'text-center' : 'text-left')}>
        {text}
      </h1>
    </div>
  );
}
