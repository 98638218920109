import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { BasketContext } from '../../context/BasketContext';
import translate from '../../i18n/translate';
import { postRegister, postOverrideMyBasket } from '../../api';
import Select from 'react-select';
import { toastSuccess, toastError } from '../UIKit/Toastify';
import LabeledInputFieldText from '../UIKit/Inputs/LabeledInputFieldText';
import LabeledInputFieldNumber from '../UIKit/Inputs/LabeledInputFieldNumber';
import ButtonActionPrimary from '../UIKit/Buttons/ButtonActionPrimary';
import TextError from '../UIKit/Labels/TextError';
import TextLabel from '../UIKit/Labels/TextLabel';

export default function Register(props) {
  const [, setUser] = useContext(UserContext);
  const [, setBasket] = useContext(BasketContext);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [vat, setVat] = useState('');
  const [company, setCompany] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [box, setBox] = useState('');
  const [postal, setPostal] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState();
  const [defaultLanguage, setDefaultLanguage] = useState({
    value: 'nl-BE',
    label: 'Nederlands',
  });
  const [
    displayErrorPasswordNotMatching,
    setDisplayErrorPasswordNotMatching,
  ] = useState(false);
  const [displayErrorPasswordEmpty, setDisplayErrorPasswordEmpty] = useState(
    false
  );
  const [displayErrorBirthday, setDisplayErrorBirthday] = useState(false);

  const [birthdayDay, setBirthdayDay] = useState();
  const [birthdayMonth, setBirthdayMonth] = useState();
  const [birthdayYear, setBirthdayYear] = useState();

  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);

  useEffect(() => {
    let days = [];
    let months = [];
    let years = [];
    for (let iD = 1; iD <= 31; iD++) {
      days.push({ value: iD, label: iD });
    }
    for (let iM = 1; iM <= 12; iM++) {
      months.push({ value: iM, label: iM });
    }
    const currentYear = new Date().getFullYear();
    for (let iY = 1930; iY <= currentYear; iY++) {
      years.push({ value: iY, label: iY });
    }
    setAvailableDays(days);
    setAvailableMonths(months);
    setAvailableYears(years);
    setAvailableCountries([
      { value: 'Belgium', label: 'Belgium' },
      { value: 'Netherlands', label: 'Netherlands' },
      { value: 'Germany', label: 'Germany' },
      { value: 'France', label: 'France' },
      { value: 'Other', label: 'Other' },
    ]);
    setAvailableLanguages([
      { value: 'nl-BE', label: 'Nederlands' },
      { value: 'en-US', label: 'English' },
      { value: 'fr-BE', label: 'Français' },
      { value: 'de-DE', label: 'Deutsch' },
    ]);
  }, []);

  async function onSubmitPressed(e) {
    e.preventDefault();
    if (password.trim().length === 0 || confirmPassword.trim().length === 0) {
      setDisplayErrorPasswordEmpty(true);
      return;
    }
    if (password !== confirmPassword) {
      setDisplayErrorPasswordNotMatching(true);
      return;
    }
    if (
      birthdayDay === undefined ||
      birthdayMonth === undefined ||
      birthdayYear === undefined
    ) {
      setDisplayErrorBirthday(true);
      return;
    }
    setDisplayErrorPasswordNotMatching(false);
    setDisplayErrorPasswordEmpty(false);
    setDisplayErrorBirthday(false);
    const birthday = `${birthdayDay.value}-${birthdayMonth.value}-${birthdayYear.value}`;
    const postJson = await postRegister(
      JSON.stringify({
        email,
        password,
        firstName,
        lastName,
        phone,
        birthday,
        company,
        vat,
        street,
        number,
        box,
        postal,
        city,
        country: country.value,
        language: defaultLanguage.value,
      })
    );
    if (postJson.success === true) {
      setUser(postJson.user);
      await postBasket();
      toastSuccess(translate('toast_registerSuccess'));
      props.history.push('/shop');
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  async function postBasket() {
    const localStorageBasket = JSON.parse(localStorage.getItem('basket'));
    if (localStorageBasket && localStorageBasket.length > 0) {
      // local user has basket
      const basket = [...localStorageBasket];
      const resJson = await postOverrideMyBasket(JSON.stringify({ basket }));
      if (resJson.success === true) {
        setBasket(basket);
        localStorage.removeItem('basket');
      } else {
        toastError(translate('toast_basketUpdateFailed'));
        console.error(resJson);
      }
    }
  }

  function handlePasswordChange(value) {
    setDisplayErrorPasswordNotMatching(false);
    setDisplayErrorPasswordEmpty(false);
    setPassword(value);
  }
  function handleConfirmPasswordChange(value) {
    setDisplayErrorPasswordNotMatching(false);
    setDisplayErrorPasswordEmpty(false);
    setConfirmPassword(value);
  }

  return (
    <div className="container mx-auto mt-8 px-4">
      <div className="bg-white rounded shadow">
        <div className="border-b py-8 font-bold text-black text-center text-xl tracking-widest uppercase">
          {translate('signup_title')}
        </div>

        <form
          className="sm:mx-10 mx-4 my-8"
          onSubmit={(e) => onSubmitPressed(e)}
        >
          <div className="flex flex-wrap w-full">
            <div className="flex sm:flex-row flex-col w-full">
              <div className="sm:w-1/2 sm:pr-1">
                <LabeledInputFieldText
                  name="firstname"
                  type="text"
                  label={translate('firstName')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Jane"
                  required={true}
                />
              </div>
              <div className="sm:w-1/2 sm:pl-1">
                <LabeledInputFieldText
                  name="lastname"
                  type="text"
                  label={translate('lastName')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Doe"
                  required={true}
                />
              </div>
            </div>

            <div className="flex flex-wrap w-full">
              <div className="flex sm:flex-row flex-col w-full">
                <div className="sm:w-1/2 sm:pr-1">
                  <LabeledInputFieldText
                    name="company"
                    type="text"
                    label={translate('company')}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required={false}
                  />
                </div>

                <div className="sm:w-1/2 sm:pl-1">
                  <LabeledInputFieldText
                    name="vat"
                    type="text"
                    label={translate('vat')}
                    value={vat}
                    onChange={(e) => setVat(e.target.value)}
                    placeholder="BE123456"
                    required={false}
                  />
                </div>
              </div>
            </div>

            <div className="flex sm:flex-row flex-col w-full">
              <div className="sm:w-3/5">
                <LabeledInputFieldText
                  name="street"
                  type="text"
                  label={translate('street')}
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  required={true}
                />
              </div>
              <div className="sm:w-1/5 sm:px-2">
                <LabeledInputFieldNumber
                  name="street-number"
                  label={translate('streetNumber')}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  required={true}
                />
              </div>
              <div className="sm:w-1/5">
                <LabeledInputFieldText
                  name="box"
                  type="text"
                  label={translate('streetBox')}
                  value={box}
                  onChange={(e) => setBox(e.target.value)}
                  required={false}
                />
              </div>
            </div>

            <div className="flex w-full">
              <div className="w-1/3 pr-1">
                <LabeledInputFieldText
                  name="postal"
                  type="text"
                  label={translate('postal')}
                  value={postal}
                  onChange={(e) => setPostal(e.target.value)}
                  required={true}
                />
              </div>
              <div className="w-2/3 pl-1">
                <LabeledInputFieldText
                  name="city"
                  type="text"
                  label={translate('city')}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required={true}
                />
              </div>
            </div>
            <div className="w-full py-3">
              <TextLabel name="country" label={translate('country')} />
              <Select
                className="w-full"
                placeholder={translate('country')}
                value={country}
                onChange={(selectedOption) => setCountry(selectedOption)}
                options={availableCountries}
                isSearchable
              />
            </div>

            <LabeledInputFieldText
              name="email"
              type="email"
              label={translate('email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="jane@doe.com"
              required={true}
            />

            <LabeledInputFieldText
              name="phone"
              type="tel"
              label={translate('phone')}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="+12 34 56 78"
              required={true}
            />
          </div>
          <div className="w-full py-3">
            <TextLabel name="birthday" label={translate('signup_birthday')} />
            <div className="flex items-center" id="birthday" name="birthday">
              <Select
                className="w-1/3"
                placeholder="day"
                value={birthdayDay}
                onChange={(selectedOption) => setBirthdayDay(selectedOption)}
                options={availableDays}
                isSearchable
              />
              <span className="mx-1">-</span>
              <Select
                className="w-1/3"
                placeholder="month"
                value={birthdayMonth}
                onChange={(selectedOption) => setBirthdayMonth(selectedOption)}
                options={availableMonths}
                isSearchable
              />
              <span className="mx-1">-</span>
              <Select
                className="w-1/3"
                placeholder="year"
                value={birthdayYear}
                onChange={(selectedOption) => setBirthdayYear(selectedOption)}
                options={availableYears}
                isSearchable
              />
            </div>
          </div>

          <LabeledInputFieldText
            name="password"
            type="password"
            label={translate('password')}
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            placeholder="******************"
            required={true}
          />
          <LabeledInputFieldText
            name="confirm-password"
            type="password"
            label={translate('signup_confirmPassword')}
            value={confirmPassword}
            onChange={(e) => handleConfirmPasswordChange(e.target.value)}
            placeholder="******************"
            required={true}
          />

          <div className="w-full pt-3">
            <TextLabel name="language" label={translate('language')} />
            <Select
              className="w-40"
              value={defaultLanguage}
              onChange={(selectedOption) => setDefaultLanguage(selectedOption)}
              options={availableLanguages}
            />
          </div>

          <div className="py-6">
            {displayErrorPasswordNotMatching === true && (
              <div className="my-2">
                <TextError text={translate('error_passwordsNotMatching')} />
              </div>
            )}
            {displayErrorPasswordEmpty === true && (
              <div className="my-2">
                <TextError text={translate('error_passwordsEmpty')} />
              </div>
            )}
            {displayErrorBirthday === true && (
              <div className="my-2">
                <TextError text={translate('error_birthdayNotCorrect')} />
              </div>
            )}
            <ButtonActionPrimary text={translate('signUp')} type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}
