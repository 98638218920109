import {
  endpoints,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../helpers';

// !==========!
//   !BASKET!
// !==========!

/**
 * Get current logged in user basket data
 */
export async function fetchMyBasket() {
  return await getRequest(endpoints.BASKET);
}

/**
 * Get current logged in user basket product data
 */
export async function fetchMyBasketProducts() {
  return await getRequest(`${endpoints.BASKET}/products`);
}

/**
 * POST full basket data data
 * @param {json} body - Stringified JSON data containing basket key-values
 */
export async function fetchBasketFullProducts(body) {
  return await postRequest(`${endpoints.BASKET}/fullproducts`, body);
}

/**
 * Post a new basket item
 * @param {json} body - Stringified JSON data containing basket key-values
 */
export async function postMyBasketItem(body) {
  return await postRequest(endpoints.BASKET, body);
}

/**
 * Put basket of the current logged in user
 * @param {json} body - Stringified JSON data containing basket key-values
 */
export async function putMyBasket(body) {
  return await putRequest(endpoints.BASKET, body);
}

/**
 * Put or post basket of the current logged in user
 * @param {json} body - Stringified JSON data containing basket key-values
 */
export async function postOverrideMyBasket(body) {
  return await postRequest(`${endpoints.BASKET}/override`, body);
}

/**
 * Delete basket item of the current logged in user
 * @param {string} basketId - UUID representation of a basket id
 */
export async function deleteMyBasketItem(basketId) {
  return await deleteRequest(`${endpoints.BASKET}/${basketId}`);
}
