import { endpoints, postRequest } from '../helpers';

// !===========!
//   !PAYMENT!
// !===========!
/**
 * Post a new payment
 * @param {json} body - Stringified JSON data containing payment key-values
 */
export async function postPayment(body) {
  return await postRequest(`${endpoints.PAYMENTS}/create`, body);
}
