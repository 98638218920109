import { endpoints, getRequest, postRequest, deleteRequest } from '../helpers';

// !=============!
//   !PURCHASES!
// !=============!

/**
 * Fetch all purchases
 */
export async function fetchPurchases() {
  return await getRequest(endpoints.PURCHASES);
}

/**
 * Fetch a single purchase
 * @param {string} id - Id of a purchase. This is a UUID value.
 */
export async function fetchPurchase(id) {
  return await getRequest(`${endpoints.PURCHASES}/${id}`);
}

/**
 * Post a new purchase
 * @param {json} body - Stringified JSON data containing purchase key-values
 */
export async function postPurchase(body) {
  return await postRequest(endpoints.PURCHASES, body);
}

/**
 * Put a new purchase
 * @param {json} body - Stringified JSON data containing purchase key-values
 * @param {string} id - Id of a purchase item. This is a UUID value.
 */
export async function putPurchase(id, body) {
  return await postRequest(`${endpoints.PURCHASES}/${id}`, body);
}

/**
 * Delete a single purchase based on the given id value
 * @param {string} id - Id of a purchase item. This is a UUID value.
 */
export async function deletePurchase(id) {
  return await deleteRequest(`${endpoints.PURCHASES}/${id}`);
}
