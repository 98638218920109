import { Locales } from '../locales';

export default {
  [Locales.French]: {
    // navbar
    shop: 'Magasin',
    aboutUs: 'À propos de nous',
    gallery: 'Galerie',
    contact: 'Contact',
    approvedByCMA: 'Approuvé par CMA',
    login: 'Le login',

    // footer
    footer_termsAndConditions: 'Termes & Conditions',
    footer_followUs: 'Suivez nous',
    footer_poweredBy: 'Avec le soutien de',

    // contact
    contact_name: 'Nom',
    contact_message: 'Message',
    contact_whichCarQuestion: 'Sur quelle voiture est votre question?',
    contact_howDoYouKnowCMA: 'Comment connaissez-vous CMA?',
    contact_sendMeACopy: 'Envoie-moi une copie',
    contact_previousPurchase: 'Un achat antérieur',
    contact_aFriend: 'Un ami',
    contact_aCarMeet: 'Un rendez-vous automobile',

    // login
    login_title: 'Se Connecter',
    login_dontHaveAnAccount: "Vous n'avez pas de compte?",
    login_forgotPassword: 'Mot de passe oublié?',

    // sign up
    signup_title: 'Créer Un Compte',
    signup_confirmPassword: 'Confirmez le mot de passe',
    signup_birthday: 'Date de naissance',

    // forgot password
    forgotpassword_title: 'Mot De Passe Oublié',

    // shop
    shop_comeBackLater:
      "Aucun produit trouvé. Nous mettons à jour le magasin tous les jours. Reviens bientôt s'il te pLît!",

    // product details
    details_alwaysInStock: 'Toujours en stock',
    details_outOfStock: 'Épuisé!',
    details_xInStock: '{amount} sur stock',
    details_articleNr: "Numéro de l'article",
    details_details: 'détails',
    details_shop: 'magasin',
    details_askQuestion: 'Question sur ce produit',
    details_availableForCars: 'Convient aux voitures suivantes:',
    details_totalPrice: 'Prix ​​total',
    details_notAllVariantsSelected:
      'Veuillez sélectionner parmi tous les champs',
    details_selectAnOption: '-- sélectionner une option --',
    details_customerPriceWithVAT: 'Prix ​​client avec TVA',
    details_resellerPriceWithoutVAT: 'Prix ​​revendeur sans TVA',
    details_resellerPriceWithVAT: 'Prix ​​revendeur avec TVA',
    details_margeWithoutVAT: 'Marge sans TVA',
    details_freeShipment: 'Envoi gratuit',
    details_youMightAlsoLike: 'Vous pourriez aussi aimer',
    details_youMightAlsoNeed: 'Vous pourriez également avoir besoin',

    // admin
    admin_orders: 'Ordres',
    admin_users: 'Utilisateurs',
    admin_products: 'Produits',
    admin_settings: 'Réglages',
    admin_cars: 'Voitures',
    admin_social: 'Social',
    admin_aboutus: 'À propos de nous',
    admin_approvedBy: 'Approuvé par',
    admin_gallery: 'Galerie',
    admin_articleNumber: "Numéro de l'article",
    admin_productName: 'Nom',
    admin_price: 'Prix',
    admin_discount: 'Prix ​​bas',
    admin_variants: 'Variants',
    admin_extraInfo: 'informaitons suppl.',
    admin_termsAndConditions: 'Termes et conditions',
    admin_supplier: 'Fournisseur',
    admin_supplierProducts: 'Produits du fournisseur',
    admin_customer: 'Client',
    admin_generalImages: 'Images générales',
    admin_purchases: 'Achats',
    admin_sales: 'Vendre',
    admin_car: 'Auto',
    admin_productName: 'Nom du produit',

    // profile
    profile_general: 'Allgemeines',
    profile_addresses: 'Adresses',
    profile_changePassword: 'Changer le mot de passe',
    profile_createAddress: 'Créer une adresse',
    profile_noAddressAvailable:
      'Aucune adresse disponible pour le moment! Veuillez en créer un.',
    profile_defaultLanguageDescription:
      'Attention: lorsque vous changez la langue dans la section de navigation, cela ne mettra pas à jour la langue par défaut sur votre profil.',

    // basket
    basket_totalArticles: 'Total des articles',
    basket_deliveryCosts: 'Coûts de livraison',
    basket_total: 'Total',
    basket_paymentMethod: 'Mode de paiement',
    basket_delivery: 'Livraison',
    basket_pickUp: 'Ramasser',
    basket_addressOutOfReach:
      "L'adresse de livraison peut être en dehors de la zone de livraison possible. Veuillez contacter pour discuter des frais de livraison.",
    basket_basket: 'Panier',
    basket_noItems:
      "Il n'y a pas encore d'articles dans votre panier! S'il doit y avoir des articles dans votre panier, mais qu'ils ne sont pas visibles, il est possible que ces articles aient été retirés du magasin.",
    basket_confirmation: 'Confirmation',
    basket_orderProcessingTitle: '-|--> Le paiement est en cours de traitement',
    basket_orderSuccessTitle: '✅ Paiement réussi',
    basket_orderFailedTitle: '❌ Paiement échoué',
    basket_orderProcessingDescription:
      "Votre commande est en traitement. Vous pouvez voir l'état de votre commande dans votre profil.",
    basket_orderSuccessDescription:
      "Votre commande et votre paiement sont bien reçus! Nous commencerons votre commande dès que possible. Vous pouvez consulter l'état de votre commande dans votre profil.",
    basket_orderFailedDescription:
      'Un problème est survenu avec votre paiement. Veuillez vérifier votre commande. Vous pouvez le faire dans votre profil.',
    basket_loginToProceed:
      'Vous devez être connecté pour continuer cette commande.',
    basket_outsideDeliveryZone: 'Hors zone de livraison',
    basket_calculatedAtNextStep: "Calculé à l'étape suivante",
    basket_customer: 'Client',
    basket_proceedForCustomer: 'Procéder pour le client',

    // sidebar
    sidebar_profile: 'Profil',
    sidebar_admin: 'Admin',
    sidebar_orderHistory: 'Ordres',

    // toast
    toast_mailSuccess: 'Courrier envoyé avec succès',
    toast_mailFailed: 'E-mail envoyé a échoué',
    toast_passwordChangeSuccess: 'Mot de passe mis à jour avec succès',
    toast_passwordChangeFailed: 'La mise à jour du mot de passe a échoué',
    toast_addressAddSuccess: 'Adresse ajoutée avec succès',
    toast_addressDeleteSuccess: 'Adresse supprimée avec succès',
    toast_addressAddFailed: "Échec de l'ajout d'adresse",
    toast_basketUpdatedSuccess: 'Panier mis à jour avec succès',
    toast_basketUpdatedFailed: 'La mise à jour du panier a échoué',
    toast_generalInformationSuccess:
      'Allgemeine Informationen erfolgreich aktualisiert',
    toast_generalInformationError:
      'Échec de la mise à jour des informations générales',
    toast_adminOrderUpdatedSuccess: 'Commande mise à jour avec succès',
    toast_adminAboutUsUpdatedSuccess: 'À propos de nous mis à jour avec succès',
    toast_adminSocialDeleteSuccess: 'Social supprimé avec succès',
    toast_adminSocialAddSuccess: 'Social ajouté avec succès',
    toast_adminCarDeleteSuccess: 'Voiture supprimée avec succès',
    toast_adminCarAddSuccess: 'Voiture ajoutée avec succès',
    toast_adminCarUpdateSuccess: 'Voiture mise à jour avec succès',
    toast_adminCategoryDeleteSuccess: 'Catégorie supprimée avec succès',
    toast_adminCategoryAddSuccess: 'Catégorie mise à jour avec succès',
    toast_adminCategoryUpdateSuccess: 'Catégorie ajoutée avec succès',
    toast_adminSubcategoryDeleteSuccess: 'Sous-catégorie supprimée avec succès',
    toast_adminSubcategoryAddSuccess: 'Sous-catégorie ajoutée avec succès',
    toast_adminSubcategoryEditSuccess: 'Sous-catégorie mise à jour avec succès',
    toast_adminProductDeleteSuccess: 'Produit supprimé avec succès',
    toast_adminProductAddSuccess: 'Produit ajouté avec succès',
    toast_adminProductUpdateSuccess: 'Produit mis à jour avec succès',
    toast_adminGalleryDeleteSuccess: 'Galerie supprimée avec succès',
    toast_adminGalleryAddSuccess: 'Galerie ajoutée avec succès',
    toast_adminApprovedByUpdateSuccess: 'Approuvé par mis à jour avec succès',
    toast_adminApprovedByDeleteSuccess: 'Approuvé par supprimé avec succès',
    toast_adminApprovedByAddSuccess: 'Approuvé par ajouté avec succès',
    toast_registerSuccess: 'Inscription réussi',
    toast_basketUpdateFailed:
      "Connexion réussie, mais une erreur s'est produite lors de la mise à jour du panier.",
    toast_forgotPasswordSuccessful:
      'Réinitialisation du mot de passe envoyée avec succès à votre adresse e-mail',
    toast_defaultAddressSuccess: "L'adresse par défaut a bien été mise à jour",
    toast_logoutFailed: 'La déconnexion a échoué. Réessayez plus tard...',
    toast_adminUserUpdateSuccess: "L'utilisateur a bien été mis à jour",

    // orders
    orders_manageOrder: 'Gérer la commande',
    orders_generalInfo: 'Informations générales',
    orders_updated: 'Dernière mise à jour',
    orders_status: 'Statut',
    orders_order: 'Commander',
    orders_delivery: 'Livraison',
    orders_addressBuyer: 'Adresse acheteur',
    orders_products: 'Des produits',
    orders_product: 'Produit',
    orders_variants: 'Variantes',
    orders_amount: 'Montant',
    orders_total: 'Le total',
    orders_paymentMethod: 'Mode de paiement',
    orders_date: 'Date',
    orders_orderNumber: 'Numéro de commande',
    orders_completed: 'Complété',
    orders_orderDeletedSuccess: 'Commande supprimée avec succès',

    // generics
    email: 'Email',
    password: 'Mot de passe',
    description: 'La Description',
    loading: 'Chargement...',
    brand: 'Marque',
    model: 'Modèle',
    type: 'Type',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    company: 'Compagnie',
    vat: 'Numéro de TVA',
    phone: 'Téléphone',
    street: 'Rue',
    streetNumber: 'No',
    streetBox: 'Po',
    postal: 'Postal',
    city: 'Ville',
    country: 'Pays',
    highlighted: 'Produits en surbrillance',
    language: 'Langue',
    defaultLanguage: 'Langage par défaut',
    categories: 'Catégories',
    subcategories: 'Sous-catégories',
    category: 'Catégorie',
    subcategory: 'Subcatégorie',
    amount: 'Quantité',
    yes: 'Oui',
    no: 'Non',

    // buttons
    send: 'Envoyer',
    signIn: 'Connecter',
    signUp: "S'inscrire",
    signOut: 'Déconnexion',
    resetPassword: 'Réinitialiser Le Mot De Passe',
    back: 'Retour',
    inBasket: 'Dans Le Panier',
    admin: 'Admin',
    cancel: 'Annuler',
    save: 'Sauver',
    edit: 'Éditer',
    new: '+ Nouveau',
    makeDefault: 'Faire Défaut',
    questionAboutProduct: 'Une question sur ce produit?',
    delete: 'Supprimer',
    proceed: 'Procéder',
    pay: 'Payer',
    copy: 'Copie',
    filter: 'Filtre',
    details: 'Des détails',
    close: 'Proche',

    // errors
    error_fieldRequired: 'Ce champ est requis',
    error_passwordsNotMatching: 'Les mots de passe ne correspondent pas',
    error_passwordsEmpty: "L'un des mots de passe est vide",
    error_birthdayNotCorrect: 'Anniversaire invalide',
  },
};
