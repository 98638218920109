import React, { useState } from 'react';

/**
 * ==== TEMPLATE ====
 * |-----------------|
 * | 0 |  | 1 |  | 2 |
 * |                 |
 * | 3 |  | 4 |  | 5 |
 * |                 |
 * | 6 |  | 7 |  | 8 |
 * |-----------------|
 */
export default function PositionSelector(props) {
  const [selectedCorner, setSelectedCorner] = useState(0);

  function handlePositionClicked(index) {
    setSelectedCorner(index);
    props.positionChange(index);
  }

  return (
    <>
      <div className="w-32 h-32 flex flex-col justify-between content-between border border-2 border-solid border-black">
        <div className="m-0 p-0 flex flex-row justify-between">
          <button
            type="button"
            className={
              'h-5 w-5 hover:bg-green-700 ' +
              (selectedCorner === 0 ? 'bg-red-700' : 'bg-gray-700')
            }
            onClick={() => handlePositionClicked(0)}
          />
          <button
            type="button"
            className={
              'h-5 w-5 hover:bg-green-700 ' +
              (selectedCorner === 1 ? 'bg-red-700' : 'bg-gray-700')
            }
            onClick={() => handlePositionClicked(1)}
          />
          <button
            type="button"
            className={
              'h-5 w-5 hover:bg-green-700 ' +
              (selectedCorner === 2 ? 'bg-red-700' : 'bg-gray-700')
            }
            onClick={() => handlePositionClicked(2)}
          />
        </div>
        <div className="m-0 p-0 flex flex-row justify-between">
          <button
            type="button"
            className={
              'h-5 w-5 hover:bg-green-700 ' +
              (selectedCorner === 3 ? 'bg-red-700' : 'bg-gray-700')
            }
            onClick={() => handlePositionClicked(3)}
          />
          <button
            type="button"
            className={
              'h-5 w-5 hover:bg-green-700 ' +
              (selectedCorner === 4 ? 'bg-red-700' : 'bg-gray-700')
            }
            onClick={() => handlePositionClicked(4)}
          />
          <button
            type="button"
            className={
              'h-5 w-5 hover:bg-green-700 ' +
              (selectedCorner === 5 ? 'bg-red-700' : 'bg-gray-700')
            }
            onClick={() => handlePositionClicked(5)}
          />
        </div>
        <div className="m-0 p-0 flex flex-row justify-between">
          <button
            type="button"
            className={
              'h-5 w-5 hover:bg-green-700 ' +
              (selectedCorner === 6 ? 'bg-red-700' : 'bg-gray-700')
            }
            onClick={() => handlePositionClicked(6)}
          />
          <button
            type="button"
            className={
              'h-5 w-5 hover:bg-green-700 ' +
              (selectedCorner === 7 ? 'bg-red-700' : 'bg-gray-700')
            }
            onClick={() => handlePositionClicked(7)}
          />
          <button
            type="button"
            className={
              'h-5 w-5 hover:bg-green-700 ' +
              (selectedCorner === 8 ? 'bg-red-700' : 'bg-gray-700')
            }
            onClick={() => handlePositionClicked(8)}
          />
        </div>
      </div>
    </>
  );
}
