import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import translate from '../i18n/translate';
import { fetchSocials } from '../api';
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaFlickr,
  FaLinkedin,
} from 'react-icons/fa';

export default function Footer() {
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [flickr, setFlickr] = useState('');

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchSocials();
      if (getJson.success === false) {
        console.error('failed to get social data');
        console.error(getJson);
        return;
      }
      const filteredFacebook = getJson.filter(
        (social) => social.name === 'facebook'
      )[0];
      const filteredInstagram = getJson.filter(
        (social) => social.name === 'instagram'
      )[0];
      const filteredLinkedin = getJson.filter(
        (social) => social.name === 'linkedin'
      )[0];
      const filteredFlickr = getJson.filter(
        (social) => social.name === 'flickr'
      )[0];

      if (filteredFacebook) {
        setFacebook(filteredFacebook.url);
      }
      if (filteredInstagram) {
        setInstagram(filteredInstagram.url);
      }
      if (filteredFlickr) {
        setFlickr(filteredFlickr.url);
      }
      if (filteredLinkedin) {
        setLinkedin(filteredLinkedin.url);
      }
    }
    fetchData();
  }, []);

  return (
    <footer className="mt-40 flex sm:flex-row flex-col items-center justify-between flex-wrap bg-cmagray p-6 text-sm text-black">
      <div className="flex flex-col flex-shrink-0">
        <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto text-sm lg:flex-grow">
          <Link to="/about">
            <span className="mt-4 lg:inline-block lg:mt-0 hover:text-cmagreen mr-4">
              {translate('aboutUs')}
            </span>
          </Link>
          <Link to="/contact">
            <span className="mt-4 lg:inline-block lg:mt-0 hover:text-cmagreen mr-4">
              {translate('contact')}
            </span>
          </Link>
          <Link to="/termsandconditions">
            <span className="mt-4 lg:inline-block lg:mt-0 hover:text-cmagreen mr-4">
              {translate('admin_termsAndConditions')}
            </span>
          </Link>
        </div>
        <div className="flex w-full justify-start items-center my-2">
          <span className="font-semibold text-sm mr-2">
            {translate('footer_followUs')}
          </span>
          {facebook !== undefined && facebook.length ? (
            <a href={facebook} target="_blank" rel="noopener noreferrer">
              <FaFacebookSquare className="cursor-pointer h-8 w-8" />
            </a>
          ) : null}
          {instagram !== undefined && instagram.length ? (
            <a href={instagram} target="_blank" rel="noopener noreferrer">
              <FaInstagramSquare className="mx-1 cursor-pointer h-8 w-8" />
            </a>
          ) : null}
          {flickr !== undefined && flickr.length ? (
            <a href={flickr} target="_blank" rel="noopener noreferrer">
              <FaFlickr className="mx-1 cursor-pointer h-8 w-8" />
            </a>
          ) : null}
          {linkedin !== undefined && linkedin.length ? (
            <a href={linkedin} target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="mx-1 cursor-pointer h-8 w-8" />
            </a>
          ) : null}
        </div>
      </div>

      <div className="flex flex-shrink-0"></div>

      <div className="flex flex-col justify-between sm:items-end items-center flex-shrink-0">
        <span className="text-sm text-gray-800">
          {translate('footer_poweredBy')}
        </span>
        <a
          href="https://www.flandersinvestmentandtrade.com/"
          rel="noopener noreferrer"
        >
          <img
            loading="lazy"
            src="/images/fit_logo.png"
            alt="Flanders Investment &amp; Trade"
            className="h-16"
          />
        </a>
        <div className="flex justify-center items-center my-2">
          <img
            loading="lazy"
            src="/images/payments/bancontact_logo.png"
            alt="Bancontact"
            className="h-8"
          />
          <img
            loading="lazy"
            src="/images/payments/ideal_logo.png"
            alt="iDeal"
            className="h-8 pr-1"
          />
          <img
            loading="lazy"
            src="/images/payments/banktransfer_logo.png"
            alt="SEPA"
            className="h-8 pr-2"
          />
          <img
            loading="lazy"
            src="/images/payments/mastercard_logo.png"
            alt="Mastercard"
            className="h-8 pr-2"
          />
          <img
            loading="lazy"
            src="/images/payments/visa_logo.png"
            alt="Visa"
            className="h-6"
          />
        </div>
      </div>
    </footer>
  );
}
