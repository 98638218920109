import React, { useState, useEffect } from 'react';
import { fetchAboutUs } from '../api';
import { textToHTML } from '../utils';
import translate from '../i18n/translate';
import TextLoading from './UIKit/Labels/TextLoading';
import TextHead from './UIKit/Labels/TextHead';

export default function About(props) {
  const [aboutUs, setAboutUs] = useState({});
  const [translatedAboutUs, setTranslatedAboutUs] = useState('');

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchAboutUs();
      setAboutUs(getJson);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (aboutUs) {
      const language = localStorage.getItem('language');
      switch (language) {
        case 'en-US':
          setTranslatedAboutUs(aboutUs.aboutEn);
          break;
        case 'de-DE':
          setTranslatedAboutUs(aboutUs.aboutDe);
          break;
        case 'fr-BE':
          setTranslatedAboutUs(aboutUs.aboutFr);
          break;
        default:
          setTranslatedAboutUs(aboutUs.aboutNl);
      }
    }
  }, [aboutUs, props]);

  if (aboutUs.id === undefined) return <TextLoading />;

  return (
    <div className="container mx-auto mt-8 px-4">
      <TextHead text={translate('aboutUs')} center={true} />
      <p
        className="mt-4 text-center"
        dangerouslySetInnerHTML={{
          __html: textToHTML(translatedAboutUs),
        }}
      />
      <div className="flex sm:flex-row flex-col justify-center items-center my-3">
        <img loading="lazy" src="/images/about/cma_logo.jpg" alt="CMA Logo" />
        <img
          loading="lazy"
          src="/images/about/map.jpg"
          alt="Map"
          className="sm:px-4 sm:py-0 py-2"
        />
        <img
          loading="lazy"
          src="/images/about/itallstarted.jpg"
          alt="It all started here"
        />
      </div>
    </div>
  );
}
