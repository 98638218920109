import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { I18nProvider, Locales } from './i18n'; // localization
import { ProtectedRoute } from './auth/ProtectedRoute';

import { UserProvider } from './context/UserContext';
import { BasketProvider } from './context/BasketContext';
import { FilterProvider } from './context/FilterContext';

import Header from './components/Header.js';
import Footer from './components/Footer.js';

import About from './components/About.js';
import TermsAndConditions from './components/TermsAndConditions.js';
import Contact from './components/Contact.js';
import Approved from './components/Approved.js';
import Shop from './components/shop/Shop.js';
import ProductDetails from './components/shop/components/ProductDetails';

import Register from './components/auth/Register.js';
import Login from './components/auth/Login.js';
import ForgotPassword from './components/auth/ForgotPassword';
import Admin from './components/admin/Admin';

import User from './components/user/User';
import Checkout from './components/checkout/Checkout';
import CheckoutConfirmation from './components/checkout/components/Confirmation';

import CookieConsent from 'react-cookie-consent';

export default function App() {
  const [locale, setLocale] = useState(Locales.Dutch);
  const [headerLocale, setHeaderLocale] = useState();

  useEffect(() => {
    toast.configure();
    const language = localStorage.getItem('language');
    if (language) {
      setLocale(language);
    }
    setHeaderLocale(language);
  }, []);

  function handleLanguageChange(locale) {
    localStorage.setItem('language', locale);
    setLocale(locale);
  }

  return (
    <>
      <Router>
        <I18nProvider locale={locale}>
          <ToastContainer />
          <UserProvider>
            <BasketProvider>
              <Header
                handleLanguageChange={handleLanguageChange}
                selectedLocale={headerLocale}
              />
              <main className="mx-auto">
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/shop" />;
                  </Route>

                  <Route exact path="/about" component={About} />
                  <Route exact path="/contact" component={Contact} />
                  <Route
                    exact
                    path="/termsandconditions"
                    component={TermsAndConditions}
                  />
                  <Route exact path="/approved" component={Approved} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/sign-up" component={Register} />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />

                  <ProtectedRoute
                    exact
                    path="/admin"
                    component={Admin}
                    selectedLocale={locale}
                    roles={['admin']}
                  />
                  <ProtectedRoute
                    exact
                    path="/user"
                    component={User}
                    selectedLocale={locale}
                    roles={['user', 'reseller']}
                  />
                  <Route
                    exact
                    path="/checkout"
                    render={(props) => (
                      <Checkout {...props} selectedLocale={locale} />
                    )}
                  />
                  <Route
                    exact
                    path="/checkout/confirmation/:id"
                    component={CheckoutConfirmation}
                  />

                  <FilterProvider>
                    {/* using render here as we need to pass an extra prop value */}
                    <Route
                      exact
                      path="/shop"
                      render={(props) => (
                        <Shop {...props} selectedLocale={locale} />
                      )}
                    />
                    <Route
                      exact
                      path="/shop/:slug"
                      render={(props) => (
                        <ProductDetails {...props} selectedLocale={locale} />
                      )}
                    />
                  </FilterProvider>
                </Switch>
              </main>
            </BasketProvider>
          </UserProvider>

          <Footer />
        </I18nProvider>
      </Router>

      <CookieConsent location="bottom" style={{ background: '#206b67' }}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  );
}
