import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import Select from 'react-select';
import ImageSelectorWithWatermark from '../../UIKit/ImageSelector';
import GeneralImageSelector from '../../UIKit/GeneralImageSelector';
import { Link } from 'react-router-dom';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import ButtonActionOutlinePrimary from '../../UIKit/Buttons/ButtonActionOutlinePrimary';
import GeneralImagePickerModal from './modals/GeneralImagePickerModal';
import {
  fetchProducts,
  fetchProductCars,
  fetchCategories,
  fetchSubcategories,
  deleteProduct,
  postProduct,
  putProduct,
  putProductPhoto,
  fetchAllVariants,
  fetchAllProductVariants,
  fetchExtraInfos,
} from '../../../api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '80%',
    height: '90%',
    transform: 'translate(-50%, -50%)',
  },
};
const customStylesVariants = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '80%',
    height: '500px',
    transform: 'translate(-50%, -50%)',
  },
};
const customStylesVariantItems = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '70%',
    height: '400px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
}

export default function AdminProducts() {
  const forceUpdate = useForceUpdate();
  const [products, setProducts] = useState([]);
  const [productNames, setProductNames] = useState([]);
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [productCars, setProductCars] = useState([]);
  const [variants, setVariants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [extraInfos, setExtraInfos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalVariantsIsOpen, setModalVariantsIsOpen] = useState(false);
  const [modalVariantItemsIsOpen, setModalVariantItemsIsOpen] = useState(false);

  const [modalGeneralImagePickerIsOpen, setModalGeneralImagePickerIsOpen] =
    useState(false);
  const [
    modalGeneralImagePickerFileSelectorName,
    setModalGeneralImagePickerFileSelectorName,
  ] = useState('');

  const [price, setPrice] = useState(0);
  const [priceDiscount, setPriceDiscount] = useState(undefined);
  const [weight, setWeight] = useState(0);
  const [highlighted, setHighlighted] = useState(false);
  const [almostOutOfStock, setAlmostOutOfStock] = useState(false);
  const [alsoAtBimmer, setAlsoAtBimmer] = useState(false);
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionNl, setDescriptionNl] = useState('');
  const [descriptionFr, setDescriptionFr] = useState('');
  const [descriptionDe, setDescriptionDe] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [titleNl, setTitleNl] = useState('');
  const [titleFr, setTitleFr] = useState('');
  const [titleDe, setTitleDe] = useState('');
  const [articleNumber, setArticleNumber] = useState('');
  const [prefix, setPrefix] = useState('');
  const [selectedYouMightAlsoLike, setSelectedYouMightAlsoLike] = useState([]);
  const [selectedYouMightAlsoNeed, setSelectedYouMightAlsoNeed] = useState([]);
  const [selectedHighlightCars, setSelectedHighlightCars] = useState([]);
  const [selectedExtraInfos, setSelectedExtraInfos] = useState([]);
  const [selectedCars, setSelectedCars] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableSubcategories, setAvailableSubcategories] = useState([]);
  const [availableVariants, setAvailableVariants] = useState([]);
  const [availableVariantItems, setAvailableVariantItems] = useState([]);
  const [availableExtraInfos, setAvailableExtraInfos] = useState([]);

  const [productVariants, setProductVariants] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [addedVariants, setAddedVariants] = useState([]);
  const [displayingVariants, setDisplayingVariants] = useState([]);
  const [currentOpenVariantId, setCurrentOpenVariantId] = useState('');

  const [editingProduct, setEditingProduct] = useState({});
  const [shouldUpdateDisplayingVariants, setShouldUpdateDisplayingVariants] =
    useState(false);
  const [shouldUpdateArticleNumber, setShouldUpdateArticleNumber] =
    useState(false);

  const [filterBrand, setFilterBrand] = useState({
    value: 'placeholder',
    label: '- select brand -',
  });
  const [filterModel, setFilterModel] = useState({
    value: 'placeholder',
    label: '- select model -',
  });
  const [filterType, setFilterType] = useState({
    value: 'placeholder',
    label: '- select type -',
  });
  const [filterName, setFilterName] = useState('');
  const [filterAllBrands, setFilterAllBrands] = useState([]);
  const [filterAllModels, setFilterAllModels] = useState([]);
  const [filterAllTypes, setFilterAllTypes] = useState([]);

  const [fileMain, setFileMain] = useState({
    watermarkPosition: { x: 0, y: 0 },
    changed: false,
    deleted: false,
  });
  const [file1, setFile1] = useState({
    watermarkPosition: { x: 0, y: 0 },
    changed: false,
    deleted: false,
  });
  const [file2, setFile2] = useState({
    watermarkPosition: { x: 0, y: 0 },
    changed: false,
    deleted: false,
  });
  const [file3, setFile3] = useState({
    watermarkPosition: { x: 0, y: 0 },
    changed: false,
    deleted: false,
  });
  const [file4, setFile4] = useState({
    watermarkPosition: { x: 0, y: 0 },
    changed: false,
    deleted: false,
  });

  function openModal() {
    setModalIsOpen(true);
  }

  function openVariantsModal() {
    setModalVariantsIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setEditingProduct({});
    setModalIsOpen(false);
  }

  function closeVariantsModal() {
    setModalVariantsIsOpen(false);
  }

  function carNames(product) {
    if (productCars.length === 0) {
      return [];
    }
    const cars = productCars.filter((car) =>
      product.productCarId.includes(car.value)
    );
    const names = [];
    cars.forEach((car) => {
      names.push(car.label);
    });
    return names;
  }

  function parsedProductNames() {
    if (products.length === 0) {
      return [];
    }
    const names = [];
    products.forEach((product) => {
      names.push({ value: product.id, label: product.titleNl });
    });
    return names;
  }

  function closeVariantItemsModal() {
    setModalVariantItemsIsOpen(false);
  }

  function resetInputFields() {
    setPrice(0);
    setPriceDiscount('');
    setWeight(0);
    setHighlighted(false);
    setAlmostOutOfStock(false);
    setAlsoAtBimmer(false);
    setDescriptionNl('');
    setDescriptionEn('');
    setDescriptionDe('');
    setDescriptionFr('');
    setTitleNl('');
    setTitleEn('');
    setTitleDe('');
    setTitleFr('');
    setSelectedCars([]);
    setSelectedYouMightAlsoLike([]);
    setSelectedYouMightAlsoNeed([]);
    setSelectedExtraInfos([]);
    setSelectedHighlightCars([]);
    setSelectedCategory('');
    setSelectedSubcategory('');
    setPrefix('');
    setArticleNumber('');
    // do not delete available categories. They are the same for all products
    setAvailableSubcategories([]);
    setAvailableVariantItems([]);
    setSelectedVariants([]);
    setAddedVariants([]);
    setDisplayingVariants([]);
    setCurrentOpenVariantId('');
    mapVariantsToSelect();
    setShouldUpdateArticleNumber(false);
    setShouldUpdateDisplayingVariants(false);
    setEditingProduct({});
    setFileMain({
      watermarkPosition: { x: 0, y: 0 },
      changed: false,
      deleted: false,
    });
    setFile1({
      watermarkPosition: { x: 0, y: 0 },
      changed: false,
      deleted: false,
    });
    setFile2({
      watermarkPosition: { x: 0, y: 0 },
      changed: false,
      deleted: false,
    });
    setFile3({
      watermarkPosition: { x: 0, y: 0 },
      changed: false,
      deleted: false,
    });
    setFile4({
      watermarkPosition: { x: 0, y: 0 },
      changed: false,
      deleted: false,
    });
  }

  function handleUpdateProduct(product) {
    setEditingProduct(product);
    setProductData(product, false);
  }

  function handleCopyProduct(product) {
    setShouldUpdateArticleNumber(true);
    setEditingProduct({ subcategoryId: product.subcategoryId }); //set to later on set the required values when copying a product
    setProductData(product, true);
  }

  function setProductData(product, isFromCopy) {
    if (product.id !== undefined) {
      const cars = productCars.filter((car) =>
        product.productCarId.includes(car.value)
      );

      let extraInfoData = [];
      if (product.extraInfos) {
        extraInfoData = availableExtraInfos.filter((info) =>
          product.extraInfos.includes(info.value)
        );
      }

      let highlightedCarsData = [];
      if (product.highlightCars) {
        highlightedCarsData = cars.filter((car) =>
          product.highlightCars.includes(car.value)
        );
      }
      setSelectedHighlightCars(highlightedCarsData);

      setSelectedExtraInfos(extraInfoData);
      setSelectedCars(cars);
      setPrice(product.price);
      setPriceDiscount(product.priceDiscount ?? '');
      setWeight(product.weight);
      setHighlighted(product.highlighted);
      setAlmostOutOfStock(product.almostOutOfStock);
      setDescriptionNl(product.descriptionNl);
      setDescriptionEn(product.descriptionEn);
      setDescriptionDe(product.descriptionDe);
      setDescriptionFr(product.descriptionFr);
      setTitleNl(product.titleNl);
      setTitleEn(product.titleEn);
      setTitleDe(product.titleDe);
      setTitleFr(product.titleFr);
      setAlsoAtBimmer(product.bimmerproduct);
      //? prefix is set when adding the category and subcategory
      const category = categories.filter(
        (cat) => cat.id === product.categoryId
      )[0];
      if (category) {
        setSelectedCategory({
          value: category.id,
          label: category.nameNl,
        });
      }

      let pVariants = productVariants.filter(
        (variant) => variant.productId === product.id
      );
      pVariants = pVariants.map((variant) => {
        const priceDiscount = product.priceDiscount ?? undefined;
        if (
          !priceDiscount !== undefined &&
          !isNaN(priceDiscount) &&
          priceDiscount.length > 0
        ) {
          const fullProductPriceDiscounted =
            parseFloat(priceDiscount) + parseFloat(variant.price);
          variant.fullProductPriceDiscounted = fullProductPriceDiscounted;
        }
        const fullProductPrice =
          parseFloat(variant.price) + parseFloat(product.price);
        variant.fullProductPrice = fullProductPrice;
        return variant;
      });
      let finalVariants = [];
      pVariants.forEach((variant) => {
        variant.headers.forEach((header, index) => {
          let existingVariant = finalVariants.filter(
            (finalVariant) => finalVariant.label === header.nameNl
          )[0];
          const variantItem = variant.items[index];
          const masterVariant = variants.filter(
            (master) => master.nameNl === header.nameNl
          )[0]; // from fetched `variants`
          if (masterVariant === undefined) {
            return;
          }
          if (existingVariant === undefined) {
            // does not exist yet
            const mVariant = {
              label: header.nameNl,
              labelDe: header.nameDe,
              labelEn: header.nameEn,
              labelFr: header.nameFr,
              value: masterVariant.id,
              items: [variantItem],
            };
            finalVariants.push(mVariant);
            existingVariant = mVariant; // used for handleVariantSelectionChange later on this code block
          } else {
            // already exists
            const indexVariant = finalVariants.indexOf(existingVariant);
            //! check if item is already in the list
            const itemAlreadyExists = existingVariant.items.filter(
              (item) => item.nameNl === variantItem.nameNl
            )[0];

            if (itemAlreadyExists === undefined) {
              existingVariant.items.push(variantItem);
            }
            finalVariants[indexVariant] = existingVariant;
          }
          handleVariantSelectionChange(
            finalVariants,
            {
              action: 'select-option',
              option: existingVariant,
            },
            false
          );
        });
      });
      let availableVs = [];
      availableVariants.forEach((variant) => {
        const foundRow = finalVariants.filter(
          (fVariant) => fVariant.label === variant.label
        )[0];
        if (foundRow === undefined) {
          availableVs.push(variant);
        }
      });
      setAddedVariants(finalVariants);
      setDisplayingVariants(pVariants);
      setAvailableVariants(availableVs);
      if (isFromCopy === false) {
        const regexString = /-(.+)/.exec(product.articleNumber);
        if (regexString) {
          setArticleNumber(regexString[1]); // removing everything before - (included)
        } else {
          setArticleNumber(product.articleNumber);
        }
      }
      openModal();
    }
  }

  async function handleDeleteProduct(id) {
    // todo: ask confirmation
    const deleteJson = await deleteProduct(id);
    if (deleteJson.success === true) {
      const filteredProducts = products.filter((product) => {
        return product.id !== id;
      });
      setProducts(filteredProducts);
      setFetchedProducts(filteredProducts);
      toastSuccess(translate('toast_adminProductDeleteSuccess'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  useEffect(() => {
    async function fetchVariantData() {
      const getJson = await fetchAllVariants();
      const newVariants = getJson.map((variant) => {
        const data = JSON.parse(variant.data);
        data.sort((a, b) => (a.dutch > b.dutch ? 1 : -1));
        return {
          id: variant.id,
          nameNl: variant.nameNl,
          nameEn: variant.nameEn,
          nameFr: variant.nameFr,
          nameDe: variant.nameDe,
          prefix: variant.prefix,
          data,
        };
      });
      newVariants.sort((a, b) => (a.nameNl > b.nameNl ? 1 : -1));
      setVariants(newVariants);
    }
    fetchVariantData();
    fetchProductVariantData();
  }, []);

  async function fetchProductVariantData() {
    const getJson = await fetchAllProductVariants();
    setProductVariants(getJson);
  }

  useEffect(() => {
    async function fetchProductData() {
      const getJson = await fetchProducts();
      setProducts(getJson);
      setFetchedProducts(getJson);
    }
    async function fetchProductCarData() {
      const getJson = await fetchProductCars();
      const carBrands = [];
      const carModels = [];
      const carTypes = [];
      const selectCars = getJson.map((car) => {
        if (!carBrands.some((brand) => brand.label === car.brand)) {
          carBrands.push({ value: car.brand, label: car.brand });
        }
        if (!carModels.some((model) => model.label === car.model)) {
          carModels.push({ value: car.model, label: car.model });
        }
        if (!carTypes.some((type) => type.label === car.type)) {
          carTypes.push({ value: car.type, label: car.type });
        }

        let label = car.brand;
        if (car.model) {
          label = `${label} ${car.model}`;
        }
        if (car.type) {
          label = `${label} ${car.type}`;
        }
        return {
          value: car.id,
          label,
        };
      });
      setFilterAllBrands([...new Set(carBrands)]);
      setFilterAllModels([...new Set(carModels)]);
      setFilterAllTypes([...new Set(carTypes)]);
      setProductCars(selectCars);
    }
    async function fetchCategoryData() {
      const getJson = await fetchCategories();
      const selectCategories = getJson.map((category) => {
        return {
          value: category.id,
          label: category.nameNl,
        };
      });
      setCategories(getJson);
      setAvailableCategories(selectCategories);
    }
    async function fetchSubcategoryData() {
      const getJson = await fetchSubcategories();
      setSubcategories(getJson);
    }
    async function fetchExtraInfoData() {
      const getJson = await fetchExtraInfos();
      setExtraInfos(getJson);

      let availableData = getJson.map((info) => {
        return { value: info.id, label: info.prefix };
      });
      setAvailableExtraInfos(availableData);
    }
    fetchProductData();
    fetchProductCarData();
    fetchCategoryData();
    fetchSubcategoryData();
    fetchExtraInfoData();
  }, []);

  function mapVariantsToSelect() {
    let selectVariants = variants.map((variant) => {
      return {
        value: variant.id,
        label: variant.nameNl,
        labelEn: variant.nameEn,
        labelFr: variant.nameFr,
        labelDe: variant.nameDe,
      };
    });
    setAvailableVariants(selectVariants);
  }

  useEffect(() => {
    mapVariantsToSelect();
  }, [variants]);

  useEffect(() => {
    function updateAvailableSubcategories(selectedCat) {
      if (!selectedCat || !selectedCat.value) {
        setPrefix('');
        setArticleNumber('');
        setAvailableSubcategories([]);
        setSelectedSubcategory('');
        if (editingProduct.subcategoryId) {
          let editProduct = editingProduct;
          editProduct.subcategoryId = undefined;
          setEditingProduct(editProduct);
        }
        return;
      }

      const category = categories.filter((cat) => {
        return cat.id === selectedCat.value;
      })[0];
      if (category) {
        const filteredSubcats = subcategories.filter((subcat) => {
          return category.subcategories.includes(subcat.id);
        });
        if (filteredSubcats.length) {
          const selectSubcategories = filteredSubcats.map((subcategory) => {
            return {
              value: subcategory.id,
              label: subcategory.nameNl,
            };
          });
          setAvailableSubcategories(selectSubcategories);
          if (editingProduct.subcategoryId) {
            const subcategory = subcategories.filter(
              (subcat) => subcat.id === editingProduct.subcategoryId
            )[0];
            if (subcategory) {
              setSelectedSubcategory({
                value: subcategory.id,
                label: subcategory.nameNl,
              });
            }
          }
        } else {
          // no subcategories found for selected category. Compose articlenumber with category prefix.
          setAvailableSubcategories([]);
          setSelectedSubcategory('');
          if (editingProduct.subcategoryId) {
            let editProduct = editingProduct;
            editProduct.subcategoryId = undefined;
            setEditingProduct(editProduct);
          }
          composeArticleNumber(category.prefix, true);
        }
        setPrefix(category.prefix);
      } else {
        setPrefix('');
        setArticleNumber('');
        setAvailableSubcategories([]);
        setSelectedSubcategory('');
        if (editingProduct.subcategoryId) {
          let editProduct = editingProduct;
          editProduct.subcategoryId = undefined;
          setEditingProduct(editProduct);
        }
      }
    }
    setSelectedSubcategory('');
    updateAvailableSubcategories(selectedCategory);
  }, [selectedCategory]);

  function handleSubcategoryChange(value) {
    setShouldUpdateArticleNumber(true);
    setSelectedSubcategory(value);
  }

  useEffect(() => {
    if (!selectedSubcategory || !selectedSubcategory.value) {
      return;
    }
    const subcategory = subcategories.filter((subcat) => {
      return subcat.id === selectedSubcategory.value;
    })[0];
    if (!subcategory || !subcategory.id) {
      return;
    }
    let composedPrefix = prefix.split('-')[0];
    composedPrefix = `${composedPrefix}-${subcategory.prefix}`;
    setPrefix(composedPrefix);
    composeArticleNumber(composedPrefix);
  }, [selectedSubcategory]);

  function composeArticleNumber(prefix, updateBasedOnCategory = false) {
    if (updateBasedOnCategory) {
      // no subcategory selected
      let countCategories =
        fetchedProducts.filter((product) => {
          return product.categoryId === selectedCategory.value;
        }).length + 1; // +1 as we need to have the next index value

      let composedCountCat = countCategories.toString();
      for (let i = 0; i < 6 - countCategories.toString().length; i++) {
        composedCountCat = `0${composedCountCat}`; // 6 for the amount of chars needed
      }

      let hasProductWithArticleNumber = fetchedProducts.filter((product) => {
        return product.articleNumber === `${prefix}${composedCountCat}`;
      })[0];

      while (hasProductWithArticleNumber) {
        countCategories += 1;
        composedCountCat = countCategories.toString();
        for (let i = 0; i < 6 - countCategories.toString().length; i++) {
          composedCountCat = `0${composedCountCat}`; // 6 for the amount of chars needed
        }
        hasProductWithArticleNumber = fetchedProducts.filter((product) => {
          return product.articleNumber === `${prefix}${composedCountCat}`;
        })[0];
      }
      setArticleNumber(composedCountCat);
    } else if (shouldUpdateArticleNumber) {
      if (selectedSubcategory && selectedSubcategory.value) {
        let countSubcategories =
          fetchedProducts.filter((product) => {
            return product.subcategoryId === selectedSubcategory.value;
          }).length + 1; // +1 as we need to have the next index value

        let composedCountSubcat = countSubcategories.toString();
        for (let i = 0; i < 6 - countSubcategories.toString().length; i++) {
          composedCountSubcat = `0${composedCountSubcat}`; // 6 for the amount of chars needed
        }

        let hasProductWithArticleNumber = fetchedProducts.filter((product) => {
          return product.articleNumber === `${prefix}${composedCountSubcat}`;
        })[0];

        while (hasProductWithArticleNumber) {
          countSubcategories += 1;
          composedCountSubcat = countSubcategories.toString();
          for (let i = 0; i < 6 - countSubcategories.toString().length; i++) {
            composedCountSubcat = `0${composedCountSubcat}`; // 6 for the amount of chars needed
          }
          hasProductWithArticleNumber = fetchedProducts.filter((product) => {
            return product.articleNumber === `${prefix}${composedCountSubcat}`;
          })[0];
        }
        setArticleNumber(composedCountSubcat);
      } else {
        setArticleNumber('');
      }
    } else {
      const artNumber = editingProduct.articleNumber;
      setArticleNumber(artNumber.substr(artNumber.length - 6)); // taking the last 6 characters as that is the article number
    }
  }

  function handlePositionChange(index, name) {
    let pos = { x: 0, y: 0 };
    switch (index) {
      case 1:
        pos = { x: 1, y: 0 };
        break;
      case 2:
        pos = { x: 2, y: 0 };
        break;
      case 3:
        pos = { x: 0, y: 1 };
        break;
      case 4:
        pos = { x: 1, y: 1 };
        break;
      case 5:
        pos = { x: 2, y: 1 };
        break;
      case 6:
        pos = { x: 0, y: 2 };
        break;
      case 7:
        pos = { x: 1, y: 2 };
        break;
      case 8:
        pos = { x: 2, y: 2 };
        break;
      default:
        break;
    }
    switch (name) {
      case 'fileMain':
        setFileMain({ ...fileMain, watermarkPosition: pos });
        break;
      case 'file1':
        setFile1({ ...file1, watermarkPosition: pos });
        break;
      case 'file2':
        setFile2({ ...file2, watermarkPosition: pos });
        break;
      case 'file3':
        setFile3({ ...file3, watermarkPosition: pos });
        break;
      case 'file4':
        setFile4({ ...file4, watermarkPosition: pos });
        break;
      default:
        console.error('unhandled target name after setting image');
        break;
    }
  }

  function handleFileChange(e) {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    switch (e.target.name) {
      case 'fileMain':
        setFileMain({
          ...fileMain,
          file: e.target.files[0],
          blob: objectUrl,
          changed: true,
          watermarkPosition: { x: 0, y: 0 },
        });
        break;
      case 'file1':
        setFile1({
          ...file1,
          file: e.target.files[0],
          blob: objectUrl,
          changed: true,
          watermarkPosition: { x: 0, y: 0 },
        });
        break;
      case 'file2':
        setFile2({
          ...file2,
          file: e.target.files[0],
          blob: objectUrl,
          changed: true,
          watermarkPosition: { x: 0, y: 0 },
        });
        break;
      case 'file3':
        setFile3({
          ...file3,
          file: e.target.files[0],
          blob: objectUrl,
          changed: true,
          watermarkPosition: { x: 0, y: 0 },
        });
        break;
      case 'file4':
        setFile4({
          ...file4,
          file: e.target.files[0],
          blob: objectUrl,
          changed: true,
          watermarkPosition: { x: 0, y: 0 },
        });
        break;
      default:
        console.error('unhandled target name after setting image');
        break;
    }
  }

  function combineArraysRecursively(array_of_arrays) {
    if (
      !array_of_arrays ||
      !Array.isArray(array_of_arrays) ||
      array_of_arrays.length === 0
    ) {
      return [];
    }

    let outputs = [];
    function permute(arrayOfArrays, whichArray = 0, output = []) {
      // arrayOfArrays contains `header` and `items`
      const variant = arrayOfArrays[whichArray];
      variant.items.forEach((item, index) => {
        if (whichArray === array_of_arrays.length - 1) {
          // last case...
          outputs.push({
            output: output.concat({
              header: variant.header,
              item: item,
              articleNumber: `${index + 1}`,
              variantId: variant.value,
            }),
            buyPrice: variant.buyPrice,
            resellerPrice: variant.resellerPrice,
            stock: variant.stock,
          });
        } else {
          // Recursive case...
          permute(
            arrayOfArrays,
            whichArray + 1,
            output.concat({
              header: variant.header,
              item: item,
              articleNumber: `${index + 1}`,
              variantId: variant.value,
            })
          );
        }
      });
    }
    permute(array_of_arrays);
    let finalOutput = [];
    outputs.forEach((variants) => {
      // could use `reduce`, but meh..
      let finalItemsPrice = parseFloat(0);
      let fullName = '';
      let headers = [];
      let items = [];
      let articleNumber = '';
      let variantIds = [];
      variants.output.forEach((variant) => {
        const item = variant.item;
        finalItemsPrice += parseFloat(item.price);
        fullName = `${fullName} - ${item.nameNl}`;
        headers.push(variant.header);
        items.push(item);
        articleNumber = articleNumber.concat(variant.articleNumber);
        variantIds.push(variant.variantId);
      });
      fullName = fullName.substring(3); // removing the first 3 characters (" - ")
      const fullProductPrice = parseFloat(price) + parseFloat(finalItemsPrice);
      const output = {
        fullName,
        headers,
        items,
        price: finalItemsPrice,
        fullProductPrice,
        resellerPrice: 0,
        buyPrice: 0,
        stock: 0,
        articleNumber,
        variantIds: [...new Set(variantIds)], // getting unique values
      };
      if (
        priceDiscount !== undefined &&
        !isNaN(priceDiscount) &&
        priceDiscount.length > 0
      ) {
        const fullProductPriceDiscounted =
          parseFloat(priceDiscount) + parseFloat(finalItemsPrice);
        output.fullProductPriceDiscounted = fullProductPriceDiscounted;
      }
      finalOutput.push(output);
    });
    return finalOutput;
  }

  useEffect(() => {
    if (editingProduct.id === undefined || shouldUpdateDisplayingVariants) {
      let variantList = [];
      if (addedVariants) {
        addedVariants.forEach((variant) => {
          let composedVariant = {};
          composedVariant.value = variant.value;
          composedVariant.header = {};
          // adding values of the variant itself
          composedVariant.header.nameNl = variant.label;
          composedVariant.header.nameDe = variant.labelDe;
          composedVariant.header.nameEn = variant.labelEn;
          composedVariant.header.nameFr = variant.labelFr;
          composedVariant.header.id = variant.id;
          // adding items
          composedVariant.items = [];
          variant.items.forEach((item) => {
            let composedItem = {};
            composedItem.nameNl = item.nameNl;
            composedItem.nameDe = item.nameDe;
            composedItem.nameEn = item.nameEn;
            composedItem.nameFr = item.nameFr;
            composedItem.price = item.price;
            composedVariant.items.push(composedItem);
          });
          // adding the composed variant to the dictionary
          variantList.push(composedVariant);
        });
      }
      if (variantList.length === 0) {
        setDisplayingVariants([]);
        return;
      }
      const output = combineArraysRecursively(variantList);
      setDisplayingVariants(output);
    }
  }, [addedVariants]);

  useEffect(() => {
    if (products.length > 0) {
      const names = parsedProductNames();
      setProductNames(names);
    }
  }, [products]);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const priceD =
      priceDiscount === undefined ||
      priceDiscount === '' ||
      (priceDiscount && priceDiscount.length === 0)
        ? null
        : priceDiscount;
    const productCardIds = selectedCars.map((item) => item.value);
    const youMightAlsoLike = selectedYouMightAlsoLike.map((item) => item.value);
    const youMightAlsoNeed = selectedYouMightAlsoNeed.map((item) => item.value);
    const extraInfos = selectedExtraInfos.map((item) => item.value);
    const highlightCars = selectedHighlightCars.map((item) => item.value);
    const selectedCar = productCars.filter(
      (car) => car.value === selectedCars[0].value
    )[0];

    let newProduct = {
      price,
      priceDiscount: priceD,
      weight,
      highlighted,
      descriptionEn,
      descriptionNl,
      descriptionDe,
      descriptionFr,
      titleEn,
      titleNl,
      titleDe,
      titleFr,
      almostOutOfStock,
      alsoAtBimmer,
      articleNumber: `${prefix}${articleNumber}`,
      productCarId: productCardIds,
      categoryId: selectedCategory.value,
      subcategoryId: selectedSubcategory.value,
      variants: JSON.stringify(displayingVariants),
      carName: selectedCar.label,
      youMightAlsoLike,
      youMightAlsoNeed,
      extraInfos,
      highlightCars
    };

    if (editingProduct.id !== undefined) {
      newProduct.id = editingProduct.id;
      await updateProduct(newProduct);
    } else {
      await submitProduct(newProduct);
    }
  }

  async function updateProduct(newProduct) {
    let deletedImages = [];
    if (fileMain && (fileMain.deleted || fileMain.changed)) {
      // .changed because you can change the main image, but not delete (yet)
      deletedImages.push('photoMain');
    }
    if (file1 && file1.deleted) {
      deletedImages.push('photo1');
    }
    if (file2 && file2.deleted) {
      deletedImages.push('photo2');
    }
    if (file3 && file3.deleted) {
      deletedImages.push('photo3');
    }
    if (file4 && file4.deleted) {
      deletedImages.push('photo4');
    }

    newProduct.deletedImageKeys = JSON.stringify(deletedImages);
    const putJson = await putProduct(newProduct.id, JSON.stringify(newProduct));
    if (putJson.success === true) {
      const productId = putJson.data.id;
      const formData = new FormData();

      if (fileMain.file && fileMain.changed) {
        formData.append('file0', fileMain.file);
        formData.append(
          'file0_watermark',
          JSON.stringify(fileMain.watermarkPosition)
        );
      }
      if (file1.file && file1.changed) {
        formData.append('file1', file1.file);
        formData.append(
          'file1_watermark',
          JSON.stringify(file1.watermarkPosition)
        );
      }
      if (file2.file && file2.changed) {
        formData.append('file2', file2.file);
        formData.append(
          'file2_watermark',
          JSON.stringify(file2.watermarkPosition)
        );
      }
      if (file3.file && file3.changed) {
        formData.append('file3', file3.file);
        formData.append(
          'file3_watermark',
          JSON.stringify(file3.watermarkPosition)
        );
      }
      if (file4.file && file4.changed) {
        formData.append('file4', file4.file);
        formData.append(
          'file4_watermark',
          JSON.stringify(file4.watermarkPosition)
        );
      }
      let formDataCount = 0;
      for (var value of formData.values()) {
        formDataCount += 1;
      }

      let updatedProduct = putJson.data;
      if (formDataCount > 0) {
        const putJsonPhoto = await putProductPhoto(productId, formData);
        if (putJsonPhoto.success === true) {
          updatedProduct = putJsonPhoto.data;
        }
      }
      const filteredProduct = fetchedProducts.filter(
        (product) => product.id === productId
      )[0];
      const index = fetchedProducts.indexOf(filteredProduct);
      let editingProducts = fetchedProducts;
      editingProducts[index] = updatedProduct;
      setProducts(editingProducts);
      setFetchedProducts(editingProducts);
      await fetchProductVariantData();
      onFilterPressed(); // making sure `products` has the correct values again after updating the list
      toastSuccess(translate('toast_adminProductUpdateSuccess'));
      closeModal();
    }
  }

  async function submitProduct(newProduct) {
    const postProductJson = await postProduct(JSON.stringify(newProduct));
    if (postProductJson.success === true) {
      const productId = postProductJson.data.id;
      const formData = new FormData();
      // checking on .changed is optional here.. .file is undefined if no image is added
      if (fileMain.file && fileMain.changed) {
        formData.append('file0', fileMain.file);
        formData.append(
          'file0_watermark',
          JSON.stringify(fileMain.watermarkPosition)
        );
      }
      if (file1.file && file1.changed) {
        formData.append('file1', file1.file);
        formData.append(
          'file1_watermark',
          JSON.stringify(file1.watermarkPosition)
        );
      }
      if (file2.file && file2.changed) {
        formData.append('file2', file2.file);
        formData.append(
          'file2_watermark',
          JSON.stringify(file2.watermarkPosition)
        );
      }
      if (file3.file && file3.changed) {
        formData.append('file3', file3.file);
        formData.append(
          'file3_watermark',
          JSON.stringify(file3.watermarkPosition)
        );
      }
      if (file4.file && file4.changed) {
        formData.append('file4', file4.file);
        formData.append(
          'file4_watermark',
          JSON.stringify(file4.watermarkPosition)
        );
      }
      const putPhotoJson = await putProductPhoto(productId, formData);
      if (putPhotoJson.success === true) {
        let productItems = fetchedProducts;
        productItems.push(putPhotoJson.data);
        setProducts(productItems);
        setFetchedProducts(productItems);

        await fetchProductVariantData();
        toastSuccess(translate('toast_adminProductAddSuccess'));
        closeModal();
      } else {
        toastError(putPhotoJson.error);
        console.error(putPhotoJson);
      }
    } else {
      toastError(postProductJson.error);
      console.error(postProductJson);
    }
  }

  function onSelectedCarChange(selectedOptions) {
    setSelectedCars(selectedOptions);
  }

  function onSelectedYouMightAlsoLikeChange(selectedOptions) {
    setSelectedYouMightAlsoLike(selectedOptions);
  }

  function onSelectedYouMightAlsoNeedChange(selectedOptions) {
    setSelectedYouMightAlsoNeed(selectedOptions);
  }

  function onSelectedExtraInfoChange(selectedOptions) {
    setSelectedExtraInfos(selectedOptions);
  }

  function onSelectedHighlightCarsChange(selectedOptions) {
    setSelectedHighlightCars(selectedOptions);
  }

  function handleVariantItemClick(item) {
    let editingItems = availableVariantItems;
    const index = editingItems.indexOf(item);
    item.selected = !item.selected;
    editingItems[index] = item;
    setAvailableVariantItems(editingItems);
    forceUpdate();
  }

  function handleVariantSelectionChange(options, meta, openModal = true) {
    if (meta.action === 'remove-value') {
      setSelectedVariants(options);
      mapVariantsToSelect();
      return;
    }

    // adding an item
    options.items = [];
    setCurrentOpenVariantId(meta.option.value);
    options.sort((a, b) => (a.label > b.label ? 1 : -1));
    setSelectedVariants(options);

    let variantItem = variants.filter(
      (variant) => variant.id === meta.option.value
    )[0];
    const items = variantItem.data.map((item) => {
      return {
        nameNl: item.dutch,
        nameEn: item.english,
        nameFr: item.french,
        nameDe: item.german,
        price: item.price,
        selected: false,
      };
    });
    setAvailableVariantItems(items);
    setModalVariantItemsIsOpen(openModal);
  }

  function handleAddVariantItems() {
    let variant = selectedVariants.filter((variant) => {
      return variant.value === currentOpenVariantId;
    })[0];
    const currentIndex = selectedVariants.indexOf(variant);
    variant.items = availableVariantItems.filter(
      (item) => item.selected === true
    );
    let updatingVariants = selectedVariants;
    updatingVariants[currentIndex] = variant;
    setSelectedVariants(updatingVariants);
    setAvailableVariantItems([]);
    setCurrentOpenVariantId('');
    closeVariantItemsModal();
  }

  function handleAddVariants() {
    setShouldUpdateDisplayingVariants(true);
    setAddedVariants(selectedVariants);
    closeVariantsModal();
  }

  function handleSetPrice(e) {
    setPrice(e.target.value);
    displayingVariants.map(
      (variant) =>
        (variant.fullProductPrice =
          parseFloat(variant.price) + parseFloat(e.target.value))
    );
  }

  function handleSetPriceDiscount(e) {
    setPriceDiscount(e.target.value);
    displayingVariants.map(
      (variant) =>
        (variant.fullProductPriceDiscounted =
          parseFloat(variant.price) + parseFloat(e.target.value))
    );
  }

  function handleSetResellerPrice(index, value) {
    let updatingVariants = displayingVariants;
    const variant = updatingVariants[index];
    if (!variant) {
      console.error(`variant not found on index ${index}`);
    }
    variant.resellerPrice = value;
    updatingVariants[index] = variant;
    setDisplayingVariants(updatingVariants);
    forceUpdate();
  }

  function handleSetBuyPrice(index, value) {
    let updatingVariants = displayingVariants;
    const variant = updatingVariants[index];
    if (!variant) {
      console.error(`variant not found on index ${index}`);
    }
    variant.buyPrice = value;
    updatingVariants[index] = variant;
    setDisplayingVariants(updatingVariants);
    forceUpdate();
  }

  function handleSetStock(index, value) {
    let updatingVariants = displayingVariants;
    const variant = updatingVariants[index];
    if (!variant) {
      console.error(`variant not found on index ${index}`);
    }
    variant.stock = value;
    updatingVariants[index] = variant;
    setDisplayingVariants(updatingVariants);
    forceUpdate();
  }

  function onFilterPressed() {
    let filteringProducts = fetchedProducts;
    if (filterName.trim().length > 0) {
      const filterNameLowercase = filterName.toLowerCase();
      filteringProducts = filteringProducts.filter(
        (product) =>
          product.titleNl.toLowerCase().includes(filterNameLowercase) ||
          product.titleEn.toLowerCase().includes(filterNameLowercase) ||
          product.titleFr.toLowerCase().includes(filterNameLowercase) ||
          product.titleDe.toLowerCase().includes(filterNameLowercase)
      );
    }
    let carName = '';
    carName = carName.concat(`${filterBrand.value} `);
    carName = carName.concat(`${filterModel.value} `);
    carName = carName.concat(`${filterType.value}`);
    carName = carName.trim(); // trimming to get rid of starting and/or ending whitespace
    const carNameList = carName.split(' ');
    const filteredCars = productCars.filter((car) => {
      const carNames = car.label.split(' ');
      let hasBrand = true; // default true because if no var is provides, return all possible products
      let hasModel = true;
      let hasType = true;
      if (carNameList[0] !== 'placeholder') {
        hasBrand = carNameList[0].includes(carNames[0]);
      }
      if (carNameList[1] !== 'placeholder') {
        hasModel = carNameList[1].includes(carNames[1]);
      }
      if (carNameList[2] !== 'placeholder') {
        hasType = carNameList[2].includes(carNames[2]);
      }
      return hasBrand && hasModel && hasType;
    });

    if (filteredCars.length > 0) {
      const carIds = filteredCars.map((car) => car.value);
      filteringProducts = filteringProducts.filter((product) => {
        return product.productCarId.some((id) =>
          carIds.some((carId) => carId === id)
        );
      });
    } else {
      filteringProducts = []; // no cars found, so no products found
    }
    setProducts(filteringProducts);
  }

  function onResetFilter() {
    setFilterName('');
    setFilterBrand({ value: 'placeholder', label: '- select brand -' });
    setFilterModel({ value: 'placeholder', label: '- select model -' });
    setFilterType({ value: 'placeholder', label: '- select type -' });
    setProducts(fetchedProducts);
  }

  function handleExistingImageDelete(name) {
    switch (name) {
      case 'fileMain':
        // should not be triggered as the first image (main image) cannot be deleted
        setEditingProduct({ ...editingProduct, photo: undefined });
        setFileMain({ deleted: true });
        break;
      case 'file1':
        setEditingProduct({ ...editingProduct, photo1: undefined });
        setFile1({ deleted: true });
        break;
      case 'file2':
        setEditingProduct({ ...editingProduct, photo2: undefined });
        setFile2({ deleted: true });
        break;
      case 'file3':
        setEditingProduct({ ...editingProduct, photo3: undefined });
        setFile3({ deleted: true });
        break;
      case 'file4':
        setEditingProduct({ ...editingProduct, photo4: undefined });
        setFile4({ deleted: true });
        break;
      default:
        console.error('unhandled target name after setting image');
        break;
    }
  }

  async function handleGeneralImagePicker(fileSelectorName) {
    setModalGeneralImagePickerFileSelectorName(fileSelectorName);
    setModalGeneralImagePickerIsOpen(true);
  }

  function handleGeneralImageClick(file, blob, nameFileSelector) {
    setModalGeneralImagePickerIsOpen(false);
    switch (nameFileSelector) {
      case 'fileMain':
        setFileMain({ ...fileMain, file: file, blob: blob, changed: true });
        break;
      case 'file1':
        setFile1({ ...file1, file: file, blob: blob, changed: true });
        break;
      case 'file2':
        setFile2({ ...file2, file: file, blob: blob, changed: true });
        break;
      case 'file3':
        setFile3({ ...file3, file: file, blob: blob, changed: true });
        break;
      case 'file4':
        setFile4({ ...file4, file: file, blob: blob, changed: true });
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Product</h2>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-car"
                >
                  Wagen
                </label>
                <Select
                  value={selectedCars}
                  onChange={(selectedOptions) =>
                    onSelectedCarChange(selectedOptions)
                  }
                  options={productCars}
                  isSearchable
                  isMulti
                  closeMenuOnSelect={false}
                />
              </div>

              <div className="w-1/2 px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-car"
                >
                  Category
                </label>
                <Select
                  value={selectedCategory}
                  onChange={(selectedOption) =>
                    setSelectedCategory(selectedOption)
                  }
                  options={availableCategories}
                  isClearable
                  isSearchable
                />
              </div>

              <div className="w-1/2 px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-car"
                >
                  Subcategory
                </label>
                <Select
                  value={selectedSubcategory}
                  onChange={(selectedOption) =>
                    handleSubcategoryChange(selectedOption)
                  }
                  options={availableSubcategories}
                  isClearable
                  isSearchable
                />
              </div>

              <div className="w-2/4 px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Basis artikelnmr.
                </label>
                <div>
                  <span className="w-2/6">{prefix}</span>
                  <input
                    className="appearance-none w-4/6 bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 ml-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="name"
                    id="name"
                    type="text"
                    value={articleNumber}
                    onChange={(e) => setArticleNumber(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="w-1/4 px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="price"
                >
                  Generische klantprijs (€)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="price"
                  id="price"
                  type="number"
                  min="0"
                  value={price}
                  onChange={handleSetPrice}
                  placeholder="24,95"
                  required
                />
              </div>

              <div className="w-1/4 px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="priceDiscount"
                >
                  Actieprijs (€)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="priceDiscount"
                  id="priceDiscount"
                  type="number"
                  value={priceDiscount}
                  placeholder="20,95"
                  onChange={handleSetPriceDiscount}
                />
              </div>

              <div className="w-full px-3 py-1 my-3 flex flex-col space-y-1">
                <ButtonActionOutlinePrimary
                  text="Variants"
                  handleClick={openVariantsModal}
                />
                <div className="flex flex-col space-y-4">
                  {modalVariantsIsOpen === false &&
                    displayingVariants !== null &&
                    displayingVariants.map((variant, index) => {
                      return (
                        <div
                          className="w-full mx-2 flex flex-row items-center space-x-1"
                          key={variant.fullName}
                        >
                          <div className="w-3/6 flex flex-col">
                            <span>{variant.fullName}</span>
                            <div className="flex flex-row space-x-2">
                              <span className="text-sm text-gray-700">
                                € {parseFloat(variant.price).toFixed(2)} =
                                totaal: €{' '}
                                {parseFloat(variant.fullProductPrice).toFixed(
                                  2
                                )}
                              </span>
                              {variant.fullProductPriceDiscounted !==
                                undefined &&
                                !isNaN(priceDiscount) &&
                                priceDiscount.length > 0 && (
                                  <span className="text-xs text-gray-700">
                                    (discounted: €{' '}
                                    {parseFloat(
                                      variant.fullProductPriceDiscounted
                                    ).toFixed(2)}
                                    )
                                  </span>
                                )}
                            </div>
                            <span className="text-xs text-gray-700">
                              Artikelnmr: {prefix}
                              {articleNumber}-{variant.articleNumber}
                            </span>
                          </div>
                          <div className="w-1/6 px-3 py-1">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="reseller-price"
                            >
                              reseller prijs (€)
                            </label>
                            <input
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              name="reseller-price"
                              id="reseller-price"
                              type="number"
                              min="0"
                              value={variant.resellerPrice}
                              onChange={(e) =>
                                handleSetResellerPrice(index, e.target.value)
                              }
                            />
                          </div>
                          <div className="w-1/6 px-3 py-1">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="buy-price"
                            >
                              aankoopprijs (€)
                            </label>
                            <input
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              name="buy-price"
                              id="buy-price"
                              type="number"
                              min="0"
                              value={variant.buyPrice}
                              onChange={(e) =>
                                handleSetBuyPrice(index, e.target.value)
                              }
                            />
                          </div>
                          <div className="w-1/6 px-3 py-1">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="stock"
                            >
                              Voorraad
                            </label>
                            <input
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              name="stock"
                              id="stock"
                              type="number"
                              min="0"
                              placeholder="10"
                              value={variant.stock}
                              onChange={(e) =>
                                handleSetStock(index, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Productnaam NL
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name"
                  id="name"
                  type="text"
                  value={titleNl}
                  onChange={(e) => setTitleNl(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Productnaam EN
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name"
                  id="name"
                  type="text"
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Productnaam Fr
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name"
                  id="name"
                  type="text"
                  value={titleFr}
                  onChange={(e) => setTitleFr(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Productnaam DE
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name"
                  id="name"
                  type="text"
                  value={titleDe}
                  onChange={(e) => setTitleDe(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="description"
                >
                  Beschrijving NL
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="description"
                  id="description"
                  value={descriptionNl}
                  onChange={(e) => setDescriptionNl(e.target.value)}
                ></textarea>
              </div>
              <div className="w-full px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="description"
                >
                  Beschrijving EN
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="description"
                  id="description"
                  value={descriptionEn}
                  onChange={(e) => setDescriptionEn(e.target.value)}
                ></textarea>
              </div>

              <div className="w-full px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="description"
                >
                  Beschrijving FR
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="description"
                  id="description"
                  value={descriptionFr}
                  onChange={(e) => setDescriptionFr(e.target.value)}
                ></textarea>
              </div>
              <div className="w-full px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="description"
                >
                  Beschrijving DE
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="description"
                  id="description"
                  value={descriptionDe}
                  onChange={(e) => setDescriptionDe(e.target.value)}
                ></textarea>
              </div>
              <div className="w-full px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-youmightneed"
                >
                  Extra Info
                </label>
                <Select
                  value={selectedExtraInfos}
                  onChange={(selectedOptions) =>
                    onSelectedExtraInfoChange(selectedOptions)
                  }
                  options={availableExtraInfos}
                  isSearchable
                  isMulti
                  closeMenuOnSelect={false}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 py-1">
              <div className="w-1/3 px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="weight"
                >
                  Gewicht (kg)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="weight"
                  id="weight"
                  type="number"
                  min="0"
                  placeholder="1234"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>
              <div className="w-1/3 px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="almostOutOfStock"
                >
                  Bijna uitverkocht
                </label>
                <input
                  name="almostOutOfStock"
                  id="almostOutOfStock"
                  type="checkbox"
                  checked={almostOutOfStock}
                  onChange={(e) => setAlmostOutOfStock(e.target.checked)}
                />
              </div>
              <div className="w-1/3 px-3 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="alsoAtBimmer"
                >
                  Ook bij Bimmerproduct?
                </label>
                <input
                  name="alsoAtBimmer"
                  id="alsoAtBimmer"
                  type="checkbox"
                  checked={alsoAtBimmer}
                  onChange={(e) => setAlsoAtBimmer(e.target.checked)}
                />
              </div>
            </div>
            <div className="flex flex-wrap ml-1 mr-5">
              <div className="w-1/6 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="highlighted"
                >
                  Uitgelicht
                </label>
                <input
                  name="highlighted"
                  id="highlighted"
                  type="checkbox"
                  checked={highlighted}
                  onChange={(e) => setHighlighted(e.target.checked)}
                />
              </div>
              <div className="w-5/6 py-1">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-youmightneed"
                >
                  Uitgelicht voor wagen(s)
                </label>
                <Select
                  value={selectedHighlightCars}
                  onChange={(selectedOptions) =>
                    onSelectedHighlightCarsChange(selectedOptions)
                  }
                  options={selectedCars}
                  isSearchable
                  isMulti
                  closeMenuOnSelect={false}
                />
              </div>
            </div>

            <div className="flex flex-col space-y-8">
              <div className="flex flex-col space-y-1">
                <ImageSelectorWithWatermark
                  title="Hoofdafbeelding"
                  name="fileMain"
                  file={fileMain.file}
                  blob={fileMain.blob}
                  imageName={editingProduct.photo ?? undefined}
                  handleGeneralImagePicker={fileMain.changed || file4.deleted}
                  deletable={false}
                  handleFileChange={handleFileChange}
                  handlePositionChange={handlePositionChange}
                />
              </div>
              <ImageSelectorWithWatermark
                title="Afbeelding 1"
                name="file1"
                file={file1.file}
                blob={file1.blob}
                imageName={editingProduct.photo1 ?? undefined}
                changed={file1.changed || file4.deleted}
                deletable={true}
                handleFileChange={handleFileChange}
                handlePositionChange={handlePositionChange}
                handleExistingImageDelete={handleExistingImageDelete}
              />
              <ImageSelectorWithWatermark
                title="Afbeelding 2"
                name="file2"
                file={file2.file}
                blob={file2.blob}
                imageName={editingProduct.photo2 ?? undefined}
                changed={file2.changed || file4.deleted}
                deletable={true}
                handleFileChange={handleFileChange}
                handlePositionChange={handlePositionChange}
                handleExistingImageDelete={handleExistingImageDelete}
              />
              <ImageSelectorWithWatermark
                title="Afbeelding 3"
                name="file3"
                file={file3.file}
                blob={file3.blob}
                imageName={editingProduct.photo3 ?? undefined}
                changed={file3.changed || file4.deleted}
                deletable={true}
                handleFileChange={handleFileChange}
                handlePositionChange={handlePositionChange}
                handleExistingImageDelete={handleExistingImageDelete}
              />
              <ImageSelectorWithWatermark
                title="Afbeelding 4"
                name="file4"
                file={file4.file}
                blob={file4.blob}
                imageName={editingProduct.photo4 ?? undefined}
                changed={file4.changed || file4.deleted}
                deletable={true}
                handleFileChange={handleFileChange}
                handlePositionChange={handlePositionChange}
                handleExistingImageDelete={handleExistingImageDelete}
              />
              {file4.changed === true && (
                <span className="italic text-teal-500 text-xs">gewijzigd</span>
              )}
            </div>

            <div className="w-full pt-8 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-youmightneed"
              >
                You might also need
              </label>
              <Select
                value={selectedYouMightAlsoNeed}
                onChange={(selectedOptions) =>
                  onSelectedYouMightAlsoNeedChange(selectedOptions)
                }
                options={productNames}
                isSearchable
                isMulti
                closeMenuOnSelect={false}
              />
            </div>

            <div className="w-full py-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-youmightlike"
              >
                You might also like
              </label>
              <Select
                value={selectedYouMightAlsoLike}
                onChange={(selectedOptions) =>
                  onSelectedYouMightAlsoLikeChange(selectedOptions)
                }
                options={productNames}
                isSearchable
                isMulti
                closeMenuOnSelect={false}
              />
            </div>

            <div className="w-full mt-6 mb-4 flex flex-col space-y-1 justify-end">
              <span className="text-teal-500 text-xs bold text-right">
                If an error regarding slug duplicated field occurs,
                <br />
                change the title of the product. This field is unique in the
                database.
              </span>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
                >
                  {translate('cancel')}
                </button>

                <button
                  type="button"
                  onClick={(e) => onSubmitPressed(e)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
                >
                  {translate('save')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalVariantsIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeVariantsModal}
        style={customStylesVariants}
        contentLabel="modal popup"
      >
        <div className="w-full my-3">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Variants toevoegen</h2>
          </div>
          <Select
            placeholder="select variant"
            isMulti
            options={availableVariants}
            value={selectedVariants}
            isSearchable
            isClearable={false}
            onChange={(selectedOptions, actionMeta) =>
              handleVariantSelectionChange(selectedOptions, actionMeta)
            }
          />

          <div className="my-4">
            <hr />
            {selectedVariants !== null &&
              selectedVariants.map((variant) => {
                return (
                  <div
                    className="mt-4 mb-2 w-full flex flex-col space-y-2"
                    key={variant.label}
                  >
                    <div className="p-2 border-2 border-solid border-gray-300 rounded">
                      <span className="font-bold">{variant.label}</span>
                      <ul className="mt-1 w-full">
                        {variant.items !== undefined &&
                          variant.items.map((item) => {
                            return (
                              <li
                                className="text-sm flex flex-row justify-between my-1"
                                key={item.nameNl}
                              >
                                <span className="justify-start">
                                  {item.nameNl}
                                </span>
                                <span className="justify-end">
                                  € {parseFloat(item.price).toFixed(2)}
                                </span>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
          <ButtonActionOutlinePrimary
            text="Add variants"
            type="button"
            handleClick={handleAddVariants}
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalVariantItemsIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeVariantItemsModal}
        style={customStylesVariantItems}
        contentLabel="modal popup"
      >
        <div className="w-full mb-3">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Select variant items</h2>
          </div>
          <ul className="my-4">
            {availableVariantItems.length > 0 &&
              availableVariantItems.map((item) => {
                return (
                  item !== undefined && (
                    <li
                      key={item.nameNl}
                      className={
                        'my-1 py-1 rounded border-2 bg-gray-200 cursor-pointer ' +
                        (item.selected === true
                          ? 'border-green-500'
                          : 'border-gray-200')
                      }
                      onClick={() => handleVariantItemClick(item)}
                    >
                      <div className="p-2 flex justify-between items-center">
                        <span className="justify-start">{item.nameNl}</span>
                        <span className="justify-end">
                          € {parseFloat(item.price).toFixed(2)}
                        </span>
                      </div>
                    </li>
                  )
                );
              })}
          </ul>
          <ButtonActionOutlinePrimary
            text="Add item(s)"
            type="button"
            handleClick={handleAddVariantItems}
          />
        </div>
      </Modal>

      <GeneralImagePickerModal
        modalIsOpen={modalGeneralImagePickerIsOpen}
        onRequestClose={() => setModalGeneralImagePickerIsOpen(false)}
        fileSelectorName={modalGeneralImagePickerFileSelectorName}
        onImageClick={(file, blob, fileSelectorName) =>
          handleGeneralImageClick(file, blob, fileSelectorName)
        }
      />

      {/* filter */}

      <h2 className="mt-6 text-lg font-semibold leading-tight">Filter</h2>
      <div className="h-10 mt-4 mb-8 flex flex-row space-x-2 items-center">
        <Select
          value={filterBrand}
          onChange={(selectedOption) => setFilterBrand(selectedOption)}
          options={filterAllBrands}
          className="w-1/5"
          placeholder="- select brand -"
          isSearchable
        />
        <Select
          value={filterModel}
          onChange={(selectedOption) => setFilterModel(selectedOption)}
          options={filterAllModels}
          className="w-1/5"
          placeholder="- select model -"
          isSearchable
        />
        <Select
          value={filterType}
          onChange={(selectedOption) => setFilterType(selectedOption)}
          options={filterAllTypes}
          className="w-1/5"
          placeholder="- select type -"
          isSearchable
        />
        <input
          className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          name="filter-name-dutch"
          id="filter-name-dutch"
          type="text"
          placeholder="Name (multilanguage)"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
        <button
          type="button"
          onClick={() => onFilterPressed()}
          className="border border-blue-500 bg-blue-500 hover:bg-blue-700 hover:border-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {translate('filter')}
        </button>
        <button
          type="button"
          onClick={() => onResetFilter()}
          className="border border-blue-500 text-blue-500 py-2 px-4 rounded"
        >
          Reset
        </button>
      </div>

      {/* content */}
      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_products')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('admin_car')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('admin_productname')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('admin_price')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('admin_discount')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {products.length > 0 &&
                products.map((product) => {
                  return (
                    <tr key={product.id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap flex flex-col">
                          {carNames(product).map((car) => {
                            return (
                              <span key={`${product.id}-${car}`}>{car}</span>
                            );
                          })}
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Link
                          to={'/shop/' + product.slug}
                          className="text-gray-900 whitespace-no-wrap hover:underline"
                          key={product.id}
                        >
                          {product.titleNl}
                        </Link>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          € {product.price}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          €{' '}
                          {product.priceDiscount ? product.priceDiscount : '-'}
                        </p>
                      </td>
                      <td className="py-5 px-5 border-b border-gray-200 bg-white text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleCopyProduct(product)}
                          className="text-sm text-blue-500 border border-blue-500 mx-2 h-8 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('copy')}
                        </button>
                        <button
                          type="button"
                          onClick={() => handleUpdateProduct(product)}
                          className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('edit')}
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteProduct(product.id)}
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
