import { endpoints, getRequest, putRequest } from '../helpers';

// !==========!
//   !USERS!
// !==========!

/**
 * Fetch all users
 */
export async function fetchUsers() {
  return await getRequest(endpoints.USERS);
}

/**
 * Fetch all users
 * @param {json} body - Stringified JSON data containing user key-values
 * @param {string} id - Id of a user. This is a UUID value.
 */
export async function putUser(id, body) {
  return await putRequest(`${endpoints.USERS}/${id}`, body);
}

/**
 * Put default address of current logged in user
 * @param {string} id - Id of a user. This is a UUID value.
 * @param {json} body - Stringified JSON data containing user default address id key-value
 */
export async function putDefaultAddress(id, body) {
  return await putRequest(`${endpoints.USERS}/defaultaddress/${id}`, body);
}
