import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import {
  fetchSuppliers,
  fetchProducts,
  fetchSupplierProducts,
  postSupplierProduct,
  deleteSupplierProduct,
} from '../../../api';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import LabeledInputFieldText from '../../UIKit/Inputs/LabeledInputFieldText';
import TextLabel from '../../UIKit/Labels/TextLabel';
import Select from 'react-select';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '80%',
    height: '635px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminSupplierProducts() {
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [productNameSupplier, setProductNameSupplier] = useState('');
  const [productCodeSupplier, setProductCodeSupplier] = useState('');
  const [inputLink, setInputLink] = useState('');
  const [product, setProduct] = useState({});
  const [supplier, setSupplier] = useState({});
  const [defaultProduct, setDefaultProduct] = useState(false);

  const [availableProducts, setAvailableProducts] = useState([]);
  const [availableSuppliers, setAvailableSuppliers] = useState([]);

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  async function handleDeleteSupplier(id) {
    const deleteJson = await deleteSupplierProduct(id);
    if (deleteJson.success === true) {
      const filteredSuppliers = supplierProducts.filter((supplier) => {
        return supplier.id !== id;
      });
      setSupplierProducts(filteredSuppliers);
      toastSuccess(translate('Supplier product deleted'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  useEffect(() => {
    async function fetchSupplierProductData() {
      const getJson = await fetchSupplierProducts();
      setSupplierProducts(getJson);
    }
    async function fetchSupplierData() {
      const getJson = await fetchSuppliers();
      const selectSupplierProducts = getJson.map((supplier) => {
        return { value: supplier.id, label: supplier.name };
      });
      setAvailableSuppliers(selectSupplierProducts);
    }
    async function fetchProductData() {
      const getJson = await fetchProducts();
      const selectProducts = getJson.map((product) => {
        return {
          value: product.id,
          label: `${product.articleNumber} - ${product.titleEn}`,
        };
      });
      setAvailableProducts(selectProducts);
    }
    fetchSupplierProductData();
    fetchSupplierData();
    fetchProductData();
  }, []);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const postJson = await postSupplierProduct(
      JSON.stringify({
        productNameSupplier: productNameSupplier.trim(),
        productCodeSupplier: productCodeSupplier.trim(),
        link: inputLink,
        productId: product.value,
        supplierId: supplier.value,
        defaultProduct,
      })
    );
    if (postJson.success === true) {
      setSupplierProducts((previous) => [...previous, postJson.data]);
      toastSuccess(translate('Supplier product created'));
      closeModal();
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  function resetInputFields() {
    setProductNameSupplier('');
    setProductNameSupplier('');
    setProductCodeSupplier('');
    setInputLink('');
    setProduct({});
    setDefaultProduct(false);
    setSupplier({});
  }

  return (
    <div>
      {/* http://reactcommunity.org/react-modal/ */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">
              Leverancier product toevoegen
            </h2>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-col space-y-3 -mx-2">
              <LabeledInputFieldText
                type="text"
                label="Product name supplier"
                value={productNameSupplier}
                required={true}
                grouped={true}
                onChange={(e) => setProductNameSupplier(e.target.value)}
              />
              <LabeledInputFieldText
                type="text"
                label="Product code supplier"
                value={productCodeSupplier}
                required={true}
                grouped={true}
                onChange={(e) => setProductCodeSupplier(e.target.value)}
              />
              <LabeledInputFieldText
                label="Link"
                type="text"
                value={inputLink}
                required={false}
                grouped={true}
                onChange={(e) => setInputLink(e.target.value)}
              />
              <div className="flex flex-col space-y-2 mt-2">
                <div>
                  <TextLabel name="Product" label="Product" />
                  <Select
                    value={product}
                    onChange={(selectedOption) => setProduct(selectedOption)}
                    options={availableProducts}
                    isClearable
                    isSearchable
                  />
                </div>

                <div>
                  <TextLabel name="Supplier" label="Supplier" />
                  <Select
                    value={supplier}
                    onChange={(selectedOption) => setSupplier(selectedOption)}
                    options={availableSuppliers}
                    isClearable
                    isSearchable
                  />
                </div>
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold"
                  htmlFor="default"
                >
                  Default
                </label>
                <input
                  name="default"
                  id="default"
                  type="checkbox"
                  checked={defaultProduct}
                  onChange={(e) => setDefaultProduct(e.target.checked)}
                />
              </div>
            </div>

            <div className="w-full mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                Annuleren
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                Opslaan
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_supplier')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Code
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Naam
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {supplierProducts.length > 0 &&
                supplierProducts.map((product) => {
                  return (
                    <tr key={product.id}>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {product.productCodeSupplier}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {product.productNameSupplier}
                        </p>
                      </td>
                      <td className="py-3 px-5 bg-white border-b border-gray-200 text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleDeleteSupplier(product.id)}
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
