import { toast, Slide } from 'react-toastify';

const defaultSettings = {
  position: 'top-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  transition: Slide,
};

export function toastSuccess(message) {
  toast.success(message, defaultSettings);
}

export function toastError(message) {
  toast.error(message, defaultSettings);
}
