import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import CreatableSelect from 'react-select/creatable';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import { FaCheckSquare } from 'react-icons/fa';
import {
  fetchProductCars,
  deleteProductCar,
  postProductCar,
  putProductCar,
} from '../../../api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '525px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminCars() {
  const [productCars, setProductCars] = useState([]);
  const [carBrand, setCarBrand] = useState('');
  const [carModel, setCarModel] = useState('');
  const [carType, setCarType] = useState('');
  const [newUniveral, setNewUniveral] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalUpdatingIsOpen, setModalUpdatingIsOpen] = useState(false);

  const [editingCarId, setEditingCarId] = useState('');
  const [editingCarBrand, setEditingCarBrand] = useState('');
  const [editingCarModel, setEditingCarModel] = useState('');
  const [editingCarType, setEditingCarType] = useState('');

  const [availableBrands, setAvailableBrands] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);
  const [availableTypes, setAvailableTypes] = useState([]);

  function openModal(update) {
    if (update) {
      setModalUpdatingIsOpen(true);
    } else {
      setModalIsOpen(true);
    }
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
    setModalUpdatingIsOpen(false);
  }

  function handleUpdateCar(car) {
    setEditingCarId(car.id);
    setEditingCarBrand(car.brand);
    setEditingCarModel(car.model);
    setEditingCarType(car.type);
    setNewUniveral(car.universal);
    openModal(true);
  }

  async function handleDeleteProductCar(id) {
    const deleteJson = await deleteProductCar(id);
    if (deleteJson.success === true) {
      const filteredProductCars = productCars.filter((productCar) => {
        return productCar.id !== id;
      });
      setProductCars(filteredProductCars);
      toastSuccess(translate('toast_adminCarDeleteSuccess'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchProductCars();
      setProductCars(getJson);
    }
    fetchData();
  }, []);

  function updateAvailableBrands() {
    const brands = productCars.map((car) => car.brand);
    const uniqueBrands = [...new Set(brands)];
    const selectBrands = uniqueBrands.map((brand) => {
      return { value: brand, label: brand };
    });
    setAvailableBrands(selectBrands);
  }

  useEffect(updateAvailableBrands, [productCars]);

  useEffect(() => {
    if (!carBrand) {
      setCarModel('');
      setAvailableModels([]);
      return;
    }
    const models = productCars.map((car) => car.model);
    const uniqueModels = [...new Set(models)];
    const selectModels = uniqueModels.map((type) => {
      return { value: type, label: type };
    });
    setAvailableModels(selectModels);
  }, [carBrand]);

  useEffect(() => {
    if (!carModel) {
      setCarType('');
      setAvailableTypes([]);
      return;
    }
    const types = productCars.map((car) => car.type);
    const uniqueTypes = [...new Set(types)];
    const selectTypes = uniqueTypes.map((model) => {
      return { value: model, label: model };
    });
    setAvailableTypes(selectTypes);
  }, [carModel]);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const object = {
      brand: carBrand.label.trim(),
      universal: newUniveral,
    };
    if (carModel.label) {
      object.model = carModel.label.trim();
    }
    if (carType.label) {
      object.type = carType.label.trim();
    }
    const postJson = await postProductCar(JSON.stringify(object));
    if (postJson.success === true) {
      setProductCars((previousProductCars) => [
        ...previousProductCars,
        postJson.data,
      ]);
      toastSuccess(translate('toast_adminCarAddSuccess'));
      closeModal();
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  async function onSubmitUpdatePressed(e) {
    e.preventDefault();
    const putJson = await putProductCar(
      editingCarId,
      JSON.stringify({
        brand: editingCarBrand.trim(),
        model: editingCarModel.trim(),
        type: editingCarType.trim(),
        universal: newUniveral,
      })
    );
    if (putJson.success === true) {
      let editingCars = productCars;
      const filteredEditingCars = editingCars.filter((car) => {
        return car.id === editingCarId;
      });
      if (!filteredEditingCars || !filteredEditingCars.length) {
        return;
      }
      const index = editingCars.indexOf(filteredEditingCars[0]);
      editingCars[index] = putJson.data;
      setProductCars(editingCars);
      toastSuccess(translate('toast_adminCarUpdateSuccess'));
      closeModal();
      updateAvailableBrands();
    } else {
      toastError(putJson.error);
      console.error(putJson);
    }
  }

  function handleBrandChange(newValue, actionMeta) {
    if (!newValue) {
      setCarBrand('');
      return;
    }
    setCarBrand(newValue);
  }

  function handleModelChange(newValue, actionMeta) {
    if (!newValue) {
      setCarModel('');
      return;
    }
    setCarModel(newValue);
  }

  function handleTypeChange(newValue, actionMeta) {
    if (!newValue) {
      setCarType('');
      return;
    }
    setCarType(newValue);
  }

  function resetInputFields() {
    setCarBrand('');
    setCarModel('');
    setCarType('');
    setEditingCarId('');
    setEditingCarBrand('');
    setEditingCarModel('');
    setEditingCarType('');
    setNewUniveral(false);
  }

  return (
    <div>
      {/* http://reactcommunity.org/react-modal/ */}

      <Modal
        isOpen={modalUpdatingIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Wagenproduct aanpassen</h2>
            <p className="mt-1 text-center w-full text-sm text-gray-700">
              Changing one of the values will not update all other saved cars.
            </p>
          </div>

          <form className="w-full" onSubmit={onSubmitUpdatePressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="brand"
                >
                  Brand
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="brand"
                  id="brand"
                  type="text"
                  value={editingCarBrand}
                  onChange={(e) => setEditingCarBrand(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="model"
                >
                  Model
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="model"
                  id="model"
                  type="text"
                  value={editingCarModel}
                  onChange={(e) => setEditingCarModel(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="type"
                >
                  Type
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="type"
                  id="type"
                  type="text"
                  value={editingCarType}
                  onChange={(e) => setEditingCarType(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold"
                  htmlFor="highlighted"
                >
                  Universal
                </label>
                <input
                  name="universal"
                  id="universal"
                  type="checkbox"
                  checked={newUniveral}
                  onChange={(e) => setNewUniveral(e.target.checked)}
                />
              </div>
            </div>

            <div className="w-full mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                {translate('cancel')}
              </button>
              <button
                type="button"
                onClick={(e) => onSubmitUpdatePressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                {translate('save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Wagenproduct toevoegen</h2>
            <p className="mt-1 text-center w-full text-sm text-gray-700">
              Type something to search a value. If the value does not exist, you
              have the option to create that item.
            </p>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-nl"
                >
                  Brand
                </label>
                <CreatableSelect
                  isClearable
                  options={availableBrands}
                  value={carBrand}
                  onChange={(newValue, actionMeta) =>
                    handleBrandChange(newValue, actionMeta)
                  }
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-fr"
                >
                  Model
                </label>
                <CreatableSelect
                  isClearable
                  options={availableModels}
                  value={carModel}
                  onChange={(newValue, actionMeta) =>
                    handleModelChange(newValue, actionMeta)
                  }
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-de"
                >
                  Type
                </label>
                <CreatableSelect
                  isClearable
                  options={availableTypes}
                  value={carType}
                  onChange={(newValue, actionMeta) =>
                    handleTypeChange(newValue, actionMeta)
                  }
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold"
                  htmlFor="highlighted"
                >
                  Universal
                </label>
                <input
                  name="universal"
                  id="universal"
                  type="checkbox"
                  checked={newUniveral}
                  onChange={(e) => setNewUniveral(e.target.checked)}
                />
              </div>
            </div>

            <div className="w-full mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                {translate('cancel')}
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                {translate('save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_cars')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal(false)}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('brand')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('model')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('type')}
                </th>
                <th className="py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Universeel
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {productCars.length > 0 &&
                productCars.map((car) => {
                  return (
                    <tr key={car.id}>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {car.brand}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {car.model}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {car.type}
                        </p>
                      </td>
                      <td className="border-b border-gray-200">
                        {car.universal === true && (
                          <span className="text-2xl text-green-500">
                            <FaCheckSquare />
                          </span>
                        )}
                      </td>
                      <td className="p-3 px-5 border-b border-gray-200 bg-white text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleUpdateCar(car)}
                          className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('edit')}
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteProductCar(car.id)}
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
