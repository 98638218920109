import React from 'react';
import { Link } from 'react-router-dom';

/**
 * A routable button component with no background color that navigates to another page
 * @param {string} to - Route to navigate to
 * @param {string} text - Text displayed in the button
 * @param {boolean} right - Boolean whether the text should be right aligned or not. If false, text will be left aligned.
 */
export default function ButtonRoutableClear({ to, text, right }) {
  return (
    <Link to={to} className={right ? 'text-right' : 'text-left'}>
      <span className={'text-gray-700 hover:text-gray-900 font-bold'}>
        {text}
      </span>
    </Link>
  );
}
