import React from 'react';

/**
 * A Button component that also returns an onClick action
 * @param {string} text - Text displayed in the button
 * @param {string} type - Type of button. Default: `button`
 * @param {any} handleClick - Action when clicked on the button
 * @param {any} disabled - State is button is disabled or enabled
 */
export default function ButtonActionSecondary({
  text,
  type = 'button',
  handleClick,
  disabled = false,
}) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={
        // reason for border: when having the button next an outlined button, this button otherwise
        // looks smaller than the outlined one
        'bg-blue-600 border-2 border-blue-600 text-white font-bold py-2 px-4 ' +
        (disabled
          ? 'cursor-not-allowed opacity-50'
          : 'hover:bg-blue-800 hover:border-blue-800')
      }
      onClick={handleClick}
    >
      {text}
    </button>
  );
}
