import React from 'react';
import { Link } from 'react-router-dom';
import translate from '../../../i18n/translate';
import LabeledInputFieldNumber from '../../UIKit/Inputs/LabeledInputFieldNumber';
import ButtonActionOutlineDanger from '../../UIKit/Buttons/ButtonActionOutlineDanger';

export default function BasketSmallScreen({
  basketItems,
  handleDeleteProduct,
  handleProductAmountChange,
  selectedLanguage,
}) {
  function totalPrice(basketItem) {
    const price = productPrice(basketItem);
    const totalPrice = parseFloat(price * basketItem.count);
    return totalPrice.toFixed(2);
  }

  function translatedString(object, language) {
    if (language) {
      switch (language) {
        case 'en-US':
          return object.nameEn;
        case 'de-DE':
          return object.nameDe;
        case 'fr-BE':
          return object.nameFr;
        default:
          return object.nameNl;
      }
    }
  }

  function productPrice(basketItem) {
    const product = basketItem.product;
    const variant = basketItem.variant;
    let price = parseFloat(
      product.priceDiscount ? product.priceDiscount : product.price
    );
    if (variant) {
      price += parseFloat(variant.price);
    }
    return price.toFixed(2);
  }

  return (
    <div className="w-full">
      {basketItems && basketItems.length > 0 ? (
        basketItems.map((item) => (
          <div
            className="my-4 flex flex-col border-b border-solid border-gray-300"
            key={item.basketId}
          >
            <img
              loading="lazy"
              src={`/uploads/products/thumbnails/${item.product.photo}`}
              alt="product"
              className="w-full my-2 object-contain"
            />
            <div className="flex flex-col space-y-1">
              <Link
                to={'/shop/' + item.product.slug}
                className="mt-2 text-lg font-bold w-full hover:underline"
              >
                {selectedLanguage === 'en-US' && (
                  <span>{item.product.titleEn}</span>
                )}
                {selectedLanguage === 'de-DE' && (
                  <span>{item.product.titleDe}</span>
                )}
                {selectedLanguage === 'fr-BE' && (
                  <span>{item.product.titleFr}</span>
                )}
                {selectedLanguage === 'nl-BE' && (
                  <span>{item.product.titleNl}</span>
                )}
              </Link>
              {item.car !== undefined && (
                <span className="text-sm text-gray-800 w-full mb-1">
                  {item.car.brand} {item.car.model} {item.car.type}
                </span>
              )}
              {item.variant !== undefined && (
                <div className="flex flex-col">
                  {item.variant.headers.map((header, index) => {
                    return (
                      <span
                        className="text-gray-600 text-sm"
                        key={header.nameNl}
                      >
                        {translatedString(header, selectedLanguage) +
                          ': ' +
                          translatedString(
                            item.variant.items[index],
                            selectedLanguage
                          )}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="my-2 w-full flex flex-col justify-between">
              <div className="flex items-center w-full">
                <div className="w-40 flex justify-center items-center">
                  <span className="mr-2">{translate('amount')}</span>
                  <LabeledInputFieldNumber
                    name="product-count"
                    value={item.count}
                    onChange={(e) =>
                      handleProductAmountChange(e.target.value, item.basketId)
                    }
                    required={true}
                    min={1}
                    max={1000}
                  />
                </div>
                <span className="mx-2">x</span>€ {productPrice(item)}
              </div>
              <span className="font-bold text-lg">€ {totalPrice(item)}</span>
            </div>
            <ButtonActionOutlineDanger
              text={translate('delete')}
              handleClick={() => handleDeleteProduct(item)}
            />
          </div>
        ))
      ) : (
        <span className="my-4 text-center">{translate('basket_noItems')}</span>
      )}
    </div>
  );
}
