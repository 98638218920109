import { endpoints, getRequest, postRequest, putRequest } from '../helpers';

// !===========!
//   !ABOUTUS!
// !===========!

/**
 * Fetch about us
 */
export async function fetchAboutUs() {
  return await getRequest(endpoints.ABOUTUS);
}

/**
 * Post a new about us
 * @param {json} body - Stringified JSON data containing aboutUs key-values
 */
export async function postAboutUs(body) {
  return await postRequest(endpoints.ABOUTUS, body);
}

/**
 * Put the about us
 * @param {string} id - Id of the about us. This is a UUID value.
 * @param {json} body - Stringified JSON data containing aboutUs key-values
 */
export async function putAboutUs(id, body) {
  return await putRequest(`${endpoints.ABOUTUS}/${id}`, body);
}
