import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import { putUser } from '../../../api';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import LabeledInputFieldText from '../../UIKit/Inputs/LabeledInputFieldText';
import TextHead from '../../UIKit/Labels/TextHead';
import TextLabel from '../../UIKit/Labels/TextLabel';
import ButtonActionPrimary from '../../UIKit/Buttons/ButtonActionPrimary';
import ButtonActionOutlinePrimary from '../../UIKit/Buttons/ButtonActionOutlinePrimary';
import ButtonActionOutlineSecondary from '../../UIKit/Buttons/ButtonActionOutlineSecondary';
import Select from 'react-select';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '70%',
    height: '775px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function UserContactInformation() {
  const [user, setUser] = useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [vat, setVat] = useState('');
  const [phone, setPhone] = useState('');
  const [defaultLanguage, setDefaultLanguage] = useState({
    value: 'nl-BE',
    label: 'Nederlands',
  });
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const languageNL = { value: 'nl-BE', label: 'Nederlands' };
  const languageEN = { value: 'en-US', label: 'English' };
  const languageFR = { value: 'fr-BE', label: 'Français' };
  const languageDE = { value: 'de-DE', label: 'Deutsch' };

  useEffect(() => {
    setAvailableLanguages([languageNL, languageEN, languageFR, languageDE]);
  }, []);

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setPhone(user.phone);
    setCompany(user.company);
    setVat(user.vat);
    updateLanguage(user.language);
  }, [user]);

  function updateLanguage(language) {
    switch (language) {
      case 'en-US':
        setDefaultLanguage(languageEN);
        break;
      case 'fr-BE':
        setDefaultLanguage(languageFR);
        break;
      case 'de-DE':
        setDefaultLanguage(languageDE);
        break;
      default:
        setDefaultLanguage(languageNL);
        break;
    }
  }

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setModalIsOpen(false);
  }

  async function onSubmitPressed(e) {
    e.preventDefault();
    const putJson = await putUser(
      user.id,
      JSON.stringify({
        firstName,
        lastName,
        phone,
        company,
        vat,
        language: defaultLanguage.value,
      })
    );
    if (putJson.success === true) {
      let updatedUser = user;
      updatedUser.firstName = firstName;
      updatedUser.lastName = lastName;
      updatedUser.phone = phone;
      updatedUser.company = company;
      updatedUser.vat = vat;
      updatedUser.language = defaultLanguage.value;
      setUser(updatedUser);
      toastSuccess(translate('toast_generalInformationSuccess'));
      closeModal();
    } else {
      toastError(translate('toast_generalInformationError'));
      console.error(putJson);
    }
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <TextHead text={translate('profile_general')} center={true} />

          <form className="w-full" onSubmit={onSubmitPressed}>
            <LabeledInputFieldText
              name="company"
              type="text"
              label={translate('company')}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required={true}
            />
            <LabeledInputFieldText
              name="vat"
              type="text"
              label={translate('vat')}
              value={vat}
              onChange={(e) => setVat(e.target.value)}
              placeholder="BE123456"
              required={false}
            />

            <div className="flex sm:flex-row flex-col w-full">
              <div className="sm:w-1/2 sm:pr-1">
                <LabeledInputFieldText
                  name="firstname"
                  type="text"
                  label={translate('firstName')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Jane"
                  required={true}
                />
              </div>
              <div className="sm:w-1/2 sm:pl-1">
                <LabeledInputFieldText
                  name="lastname"
                  type="text"
                  label={translate('lastName')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Doe"
                  required={true}
                />
              </div>
            </div>

            <LabeledInputFieldText
              name="phone"
              type="tel"
              label={translate('phone')}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="+12 34 56 78"
              required={true}
            />

            <LabeledInputFieldText
              name="email"
              type="email"
              label={translate('email')}
              value={user.email}
              disabled={true}
            />

            <div className="w-40 py-3">
              <TextLabel name="language" label={translate('language')} />
              <Select
                className="w-full"
                value={defaultLanguage}
                onChange={(selectedOption) =>
                  setDefaultLanguage(selectedOption)
                }
                options={availableLanguages}
              />
            </div>

            <div className="w-full flex my-4">
              <ButtonActionOutlineSecondary
                text={translate('cancel')}
                handleClick={() => closeModal(false)}
              />
              <div className="mx-2">
                <ButtonActionPrimary text={translate('save')} type="submit" />
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <div className="w-full sm:px-6 py-4">
        <TextHead text={translate('profile_general')} />
        <ul className="my-3">
          {user.company !== undefined && (
            <li>
              {user.company} - {user.vat}
            </li>
          )}
          <li className="font-bold">
            {user.firstName} {user.lastName}
          </li>
          {user.phone !== undefined && <li>{user.phone}</li>}
          <li>
            {translate('defaultLanguage')}: {defaultLanguage.label} <br />
            <span className="text-xs text-gray-600 italic">
              {translate('profile_defaultLanguageDescription')}
            </span>
          </li>
        </ul>
        <ButtonActionOutlinePrimary
          text={translate('edit')}
          handleClick={openModal}
        />
      </div>
    </>
  );
}
