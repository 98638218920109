import { endpoints, getRequest, postRequest, deleteRequest } from '../helpers';

// !============!
//   !SUPPLIER!
// !============!

/**
 * Fetch all suppliers
 */
export async function fetchSuppliers() {
  return await getRequest(endpoints.SUPPLIERS);
}

/**
 * Fetch a single supplier
 * @param {string} id - Id of a supplier. This is a UUID value.
 */
export async function fetchSupplier(id) {
  return await getRequest(`${endpoints.SUPPLIERS}/${id}`);
}

/**
 * Post a new supplier
 * @param {json} body - Stringified JSON data containing supplier key-values
 */
export async function postSupplier(body) {
  return await postRequest(endpoints.SUPPLIERS, body);
}

/**
 * Put a new supplier
 * @param {json} body - Stringified JSON data containing supplier key-values
 */
export async function putSupplier(id, body) {
  return await postRequest(`${endpoints.SUPPLIERS}/${id}`, body);
}

/**
 * Delete a single supplier based on the given id value
 * @param {string} id - Id of a supplier item. This is a UUID value.
 */
export async function deleteSupplier(id) {
  return await deleteRequest(`${endpoints.SUPPLIERS}/${id}`);
}
