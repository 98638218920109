import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { BasketContext } from '../../context/BasketContext';
import translate from '../../i18n/translate';
import AdminOrders from './components/AdminOrders';
import AdminUsers from './components/AdminUsers';
import AdminCars from './components/AdminCars';
import AdminCategories from './components/AdminCategories';
import AdminSubCategories from './components/AdminSubCategories';
import AdminProducts from './components/AdminProducts';
import AdminSocials from './components/AdminSocials';
import AdminGeneralImages from './components/AdminGeneralImages';
import AdminAboutUs from './components/AdminAboutUs';
import AdminVariants from './components/AdminVariants';
import AdminExtraInfo from './components/AdminExtraInfo';
import AdminSuppliers from './components/AdminSuppliers';
import AdminSupplierProducts from './components/AdminSupplierProducts';
import AdminTnC from './components/AdminTnC';
import Profile from '../user/components/Profile';
import TextHead from '../UIKit/Labels/TextHead';
import { postLogout } from '../../api';
import {
  FaUserFriends,
  FaShoppingCart,
  FaCarSide,
  FaGripVertical,
  FaTag,
  FaWallet,
  FaShareAlt,
  FaInfo,
  FaUser,
  FaStream,
  FaInfoCircle,
  FaBalanceScale,
  FaBox,
  FaShoppingBasket,
  FaCoins,
} from 'react-icons/fa';
import ButtonActionSignout from '../UIKit/Buttons/ButtonActionSignout';
import { toastError } from '../UIKit/Toastify';
import AdminPurchases from './components/AdminPurchases';
import AdminSales from './components/AdminSales';
import OrderHistory from '../user/components/OrderHistory';

export default function Admin(props) {
  const [, setUser] = useContext(UserContext);
  const [, setBasket] = useContext(BasketContext);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    setSelectedLanguage(props.selectedLocale);
  }, [props]);

  const handleSideTap = (id) => {
    setSelectedItem(id);
  };

  async function signOut() {
    const postJson = await postLogout();
    if (postJson.success === true) {
      setUser({});
      setBasket([]);
      props.history.push('/shop');
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  const panelItems = [
    { icon: <FaWallet />, title: 'admin_orders' },
    { icon: <FaUserFriends />, title: 'admin_users' },
    { icon: <FaShoppingCart />, title: 'admin_products' },
    { icon: <FaCarSide />, title: 'admin_cars' },
    { icon: <FaGripVertical />, title: 'categories' },
    { icon: <FaTag />, title: 'subcategories' },
    { icon: <FaShareAlt />, title: 'admin_social' },
    { icon: <FaInfo />, title: 'admin_aboutus' },
    { icon: <FaStream />, title: 'admin_variants' },
    { icon: <FaInfoCircle />, title: 'admin_extraInfo' },
    { icon: <FaBalanceScale />, title: 'admin_termsAndConditions' },
    { icon: <FaBox />, title: 'admin_supplier' },
    { icon: <FaBox />, title: 'admin_supplierProducts' },
    { icon: <FaShoppingBasket />, title: 'admin_purchases' },
    { icon: <FaCoins />, title: 'admin_sales' }
  ];

  return (
    <div className="flex flex-col md:flex-row w-full mt-5">
      <div className="md:w-64">
        <section className="ml-4 mr-2">
          <nav className="flex-grow md:block pb-4 md:pb-0 md:overflow-y-auto">
            <TextHead text={translate('sidebar_admin')} />
            {panelItems.map((item, index) => {
              return (
                <button
                  className="inline-flex focus:outline-none items-center w-full text-left px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg hover:bg-gray-400"
                  onClick={() => handleSideTap(index)}
                  key={index}
                >
                  <span className="mr-2">{item.icon}</span>
                  <span className="pr-2">{translate(item.title)}</span>
                </button>
              );
            })}
          </nav>
        </section>

        <section className="ml-4 mr-2 mt-5">
          <nav className="flex-grow md:block pb-4 md:pb-0 md:overflow-y-auto">
            <TextHead text={translate('sidebar_profile')} />
            <button
              className="inline-flex focus:outline-none items-center w-full text-left px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg hover:bg-gray-400"
              onClick={() => handleSideTap(500)}
            >
              <span className="mr-2">
                <FaUser />
              </span>
              <span className="pr-2"> {translate('sidebar_profile')}</span>
            </button>
            <button
              className="inline-flex focus:outline-none items-center w-full text-left px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg hover:bg-gray-400"
              onClick={() => handleSideTap(501)}
            >
              <span className="mr-2">
                <FaWallet />
              </span>
              <span className="pr-2"> {translate('sidebar_orderHistory')}</span>
            </button>
            <div className="mt-2">
              <ButtonActionSignout
                text={translate('signOut')}
                handleClick={signOut}
              />
            </div>
          </nav>
        </section>
      </div>

      <div className="w-full sm:ml-3 px-3">
        {selectedItem === 0 && <AdminOrders />}
        {selectedItem === 1 && <AdminUsers />}
        {selectedItem === 2 && <AdminProducts />}
        {selectedItem === 3 && <AdminCars />}
        {selectedItem === 4 && <AdminCategories />}
        {selectedItem === 5 && <AdminSubCategories />}
        {selectedItem === 6 && <AdminSocials />}
        {selectedItem === 7 && <AdminAboutUs />}
        {selectedItem === 8 && <AdminVariants />}
        {selectedItem === 9 && <AdminExtraInfo />}
        {selectedItem === 10 && <AdminTnC />}
        {selectedItem === 11 && <AdminSuppliers />}
        {selectedItem === 12 && <AdminSupplierProducts />}
        {selectedItem === 13 && <AdminPurchases />}
        {selectedItem === 14 && <AdminSales />}
        {selectedItem === 15 && <AdminGeneralImages />}
        {selectedItem === 500 && <Profile />}
        {selectedItem === 501 && (
          <OrderHistory selectedLanguage={selectedLanguage} />
        )}
      </div>
    </div>
  );
}
