import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

export default function ShareButtons() {
  const shareUrl = window.location.href;
  return (
    <div className="flex flex-row">
      <FacebookShareButton className="mr-1" url={shareUrl}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <FacebookMessengerShareButton
        className="mr-1"
        url={shareUrl}
        appId="521270401588372" // @todo: change...
      >
        <FacebookMessengerIcon size={32} round />
      </FacebookMessengerShareButton>

      <TwitterShareButton
        className="mr-1"
        url={shareUrl}
        title="Hey! Check dit artikel!" // @todo: change...
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <WhatsappShareButton
        className="mr-1"
        url={shareUrl}
        title="Hey! Check dit artikel!"
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
    </div>
  );
}
