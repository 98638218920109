import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import {
  fetchOrders,
  fetchOrderDetails,
  putOrderHandleState,
  deleteOrder,
} from '../../../api';
import { toastSuccess, toastError } from '../../UIKit/Toastify';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '90%',
    height: '700px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminOrders() {
  const [orders, setOrders] = useState([]);
  const [managingOrderId, setManagingOrderId] = useState('');
  const [managingOrder, setManagingOrder] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  useEffect(() => {
    async function fetchData() {
      const allOrders = await fetchOrders();
      setOrders(allOrders);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchDataDetails() {
      const orderData = await fetchOrderDetails(managingOrderId);
      setManagingOrder(orderData);
    }
    if (managingOrderId && managingOrderId.length > 0) {
      fetchDataDetails();
    }
  }, [managingOrderId]);

  function handleEditClick(order) {
    setManagingOrderId(order.id);
    openModal();
  }

  function resetInputFields() {
    setManagingOrderId('');
    setManagingOrder({});
  }

  async function onSubmitPressed() {
    const putJson = await putOrderHandleState(managingOrder.id);
    if (putJson.success === true) {
      const previousStateOrderId = orders.indexOf(managingOrder);
      orders[previousStateOrderId] = putJson.data;
      toastSuccess(translate('toast_adminOrderUpdatedSuccess'));
      closeModal();
    } else {
      toastError(putJson.error);
      console.error(putJson);
    }
  }

  async function onDeletePressed() {
    const id = managingOrder.id;
    const deleteJson = await deleteOrder(id);
    if (deleteJson.success === true) {
      const filteredOrders = orders.filter((order) => {
        return order.id !== id;
      });
      setOrders(filteredOrders);
      toastSuccess(translate('orders_orderDeletedSuccess'));
      closeModal();
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Bestelling Beheren</h2>
          </div>
          {(managingOrder && managingOrder.id) !== undefined ? (
            <form
              className="w-full h-full flex flex-col justify-between"
              onSubmit={onSubmitPressed}
            >
              <section className="w-full flex sm:flex-row flex-col sm:mx-2 sm:space-x-32">
                <div className="sm:w-1/2 w-full">
                  <h2 className="my-2">Algemene info</h2>
                  <ul>
                    <li>Order: #{managingOrder.orderNumber}</li>
                    <li>Updated: {managingOrder.updatedAt}</li>
                    <li>
                      Status:{' '}
                      <b className="font-bold">{managingOrder.status}</b>
                    </li>
                    <li>Levering: {managingOrder.delivery ? 'YES' : 'NO'}</li>
                  </ul>
                </div>
                <div className="sm:w-1/2 w-full mt-12 sm:mt-0">
                  <h2 className="my-2">Adres koper</h2>
                  <ul>
                    <li>
                      {managingOrder.firstName} {managingOrder.lastName}
                    </li>
                    <li>{managingOrder.street}</li>
                    <li>
                      {managingOrder.postal} {managingOrder.city}
                    </li>
                    <li>{managingOrder.country}</li>
                  </ul>
                </div>
              </section>

              <section className="mt-8 sm:mx-2 w-full">
                <h2>Products</h2>
                <table className="mt-2 w-full">
                  <thead>
                    <tr>
                      <th className="w-16 text-sm text-gray-800">Thumb</th>
                      <th className="text-left text-sm text-gray-800">
                        <span className="ml-4 mr-2">Product</span>
                      </th>
                      <th className="text-left text-sm text-gray-800">
                        Varianten
                      </th>
                      <th className="text-right text-sm text-gray-800">
                        Aantal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {managingOrder.products &&
                      managingOrder.products.length > 0 &&
                      managingOrder.products.map((product, productIndex) => {
                        return (
                          <tr
                            key={product.id}
                            className="border-b border-gray-200 w-full"
                          >
                            <td className="w-16">
                              <img
                                src={
                                  '/uploads/products/thumbnails/' +
                                  product.photo
                                }
                                alt="product img"
                                className="w-16 h-16 object-contain py-2"
                              />
                            </td>
                            <td>
                              <div className="flex flex-col ml-4 mr-2 py-2">
                                <Link
                                  to={'/shop/' + product.slug}
                                  className="hover:underline"
                                  key={product.id}
                                >
                                  {product.titleNl}
                                </Link>
                                <span className="text-sm text-gray-600">
                                  {product.articleNumber}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="flex flex-col py-2">
                                {managingOrder.productVariants[productIndex] &&
                                  managingOrder.productVariants[productIndex]
                                    .id !== undefined &&
                                  managingOrder.productVariants[
                                    productIndex
                                  ].headers.map((header, headerIndex) => {
                                    return (
                                      <span key={header.nameNl}>
                                        {header.nameNl +
                                          ': ' +
                                          managingOrder.productVariants[
                                            productIndex
                                          ].items[headerIndex].nameNl}
                                      </span>
                                    );
                                  })}
                              </div>
                            </td>
                            <td className="text-right">x {product.amount}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="w-full text-right font-semibold my-2">
                  Totaal: {managingOrder.value} {managingOrder.currency}
                </div>
              </section>

              <section className="justify-end w-full mt-8 mb-4">
                <div className="flex justify-between items-center w-full">
                  <div className="flex justify-start">
                    <button
                      type="button"
                      onClick={() => onDeletePressed()}
                      className="border border-red-500 text-red-500 font-bold py-2 px-4 rounded"
                    >
                      Verwijderen
                    </button>
                  </div>

                  <div className="flex space-x-4 justify-end">
                    <button
                      type="button"
                      onClick={() => closeModal(false)}
                      className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
                    >
                      Annuleren
                    </button>

                    <button
                      type="button"
                      onClick={(e) => onSubmitPressed(e)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Behandeld
                    </button>
                  </div>
                </div>
              </section>
            </form>
          ) : (
            <span>Loading...</span>
          )}
        </div>
      </Modal>

      <div>
        <div>
          <h2 className="text-2xl font-semibold leading-tight">
            {translate('admin_orders')}
          </h2>
        </div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Order Nr.
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Datum
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Betaalmethode
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Totaal
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Afgewerkt
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody>
                {orders.length > 0 &&
                  orders.map((order) => {
                    let statusColor;
                    switch (order.status) {
                      case 'FAILED':
                        statusColor = 'bg-red-200 text-red-900';
                        break;
                      case 'PAID':
                        statusColor = 'bg-green-200 text-green-900';
                        break;
                      default:
                        statusColor = 'bg-blue-200 text-blue-900';
                        break;
                    }
                    return (
                      <tr key={order.id}>
                        <td className="p-5 border-b border-gray-200 bg-white text-sm">
                          <span
                            className={
                              'px-4 py-2 rounded-full font-bold text-xs ' +
                              statusColor
                            }
                          >
                            {order.status}
                          </span>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            #{order.orderNumber}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {order.updatedAt}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {order.method}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {order.value} {order.currency}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-center">
                            {order.handled ? '✅' : '❌'}
                          </p>
                        </td>
                        <td className="py-5 px-5 border-b border-gray-200 bg-white text-sm flex justify-end">
                          <button
                            type="button"
                            onClick={() => handleEditClick(order)}
                            className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                          >
                            {translate('edit')}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
