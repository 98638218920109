import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import { fetchUsers } from '../../../api';
import TextHead from '../../UIKit/Labels/TextHead';

export default function BasketUserManagement({
  addresses,
  onUserSelectChange,
}) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let getJson = await fetchUsers();
      getJson.sort((user1, user2) =>
        user1.firstName > user2.firstName ? 1 : user2.firstName > user1.firstName ? -1 : 0
      );
      if (addresses) {
        getJson.forEach((user) => {
          // setting address data to the users (for default selected address per user)
          const userAddressId = user.defaultAddressId;
          const address = addresses.filter(
            (address) => address.id === userAddressId
          )[0];
          user.address = address;
        });
      }
      setUsers(getJson);
    }
    fetchData();
  }, [addresses]);

  return (
    <div className="w-full">
      <TextHead text={translate('basket_selectUser')} />
      {users.map((user) => {
        return (
          <div
            key={user.id}
            className="p-2 border-b border-gray-300 border-solid flex flex-row hover:bg-gray-200 cursor-pointer"
            onClick={() => onUserSelectChange(user)}
          >
            <div className="flex-grow">
              <span className="text-black group-hover:text-white">
                {user.firstName} {user.lastName}
              </span>
              {user.company !== null && user.company.length > 0 && (
                <span className="pl-3 text-xs text-gray-800">
                  ({user.company} - {user.vat})
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
