import React, { useState, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import translate from '../../../i18n/translate';
import { putChangePassword } from '../../../api';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import TextHead from '../../UIKit/Labels/TextHead';
import TextError from '../../UIKit/Labels/TextError';
import ButtonActionOutlinePrimary from '../../UIKit/Buttons/ButtonActionOutlinePrimary';
import LabeledInputFieldText from '../../UIKit/Inputs/LabeledInputFieldText';

export default function UserChangePassword() {
  const [user, setUser] = useContext(UserContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [
    displayErrorPasswordNotMatching,
    setDisplayErrorPasswordNotMatching,
  ] = useState(false);
  const [displayErrorPasswordEmpty, setDisplayErrorPasswordEmpty] = useState(
    false
  );

  async function handleChangePassword() {
    if (password.trim().length === 0 || confirmPassword.trim().length === 0) {
      setDisplayErrorPasswordEmpty(true);
      return;
    }
    if (password !== confirmPassword) {
      setDisplayErrorPasswordNotMatching(true);
      return;
    }
    const putJson = await putChangePassword(JSON.stringify({ password }));
    if (putJson.success === true) {
      let updatedUser = user;
      updatedUser.password = password;
      setUser(updatedUser);
      toastSuccess(translate('toast_passwordChangeSuccess'));
      resetInputFields();
    } else {
      toastError(translate('toast_passwordChangeFailed'));
      console.error(putJson);
    }
  }

  function resetInputFields() {
    setPassword('');
    setConfirmPassword('');
    setDisplayErrorPasswordNotMatching(false);
    setDisplayErrorPasswordEmpty(false);
  }

  function handlePasswordChange(value) {
    setDisplayErrorPasswordNotMatching(false);
    setDisplayErrorPasswordEmpty(false);
    setPassword(value);
  }
  function handleConfirmPasswordChange(value) {
    setDisplayErrorPasswordNotMatching(false);
    setDisplayErrorPasswordEmpty(false);
    setConfirmPassword(value);
  }

  return (
    <section className="w-full sm:px-6 py-4">
      <TextHead text={translate('profile_changePassword')} />
      <LabeledInputFieldText
        name="password"
        type="password"
        label={translate('password')}
        value={password}
        onChange={(e) => handlePasswordChange(e.target.value)}
        placeholder="******************"
        required={true}
      />
      <LabeledInputFieldText
        name="confirm-password"
        type="password"
        label={translate('signup_confirmPassword')}
        value={confirmPassword}
        onChange={(e) => handleConfirmPasswordChange(e.target.value)}
        placeholder="******************"
        required={true}
      />
      <div className="mb-4 mt-1">
        {displayErrorPasswordNotMatching === true && (
          <TextError text={translate('error_passwordsNotMatching')} />
        )}
        {displayErrorPasswordEmpty === true && (
          <TextError text={translate('error_passwordsEmpty')} />
        )}
      </div>
      <ButtonActionOutlinePrimary
        text={translate('save')}
        handleClick={() => handleChangePassword()}
      />
    </section>
  );
}
