import {
  endpoints,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../helpers';

// !================!
//   !PRODUCTCARS!
// !================!

/**
 * Fetch all product cars
 */
export async function fetchProductCars() {
  return await getRequest(endpoints.PRODUCTCARS);
}

export async function fetchProductCarsLinkedToProducts() {
  return await getRequest(`${endpoints.PRODUCTCARS}/linked`);
}

/**
 * Fetch all product cars that match the filter query
 * @param {any} filter - Object containing `brand`, `model` and `type`
 */
export async function fetchFilteredProductCars(filter) {
  let queryString = '';
  if (filter.brand.length) {
    queryString = `${queryString}?brand=${filter.brand}`;
  }
  if (filter.model.length) {
    queryString = `${queryString}&model=${filter.model}`;
  }
  if (filter.type.length) {
    queryString = `${queryString}&type=${filter.type}`;
  }
  return await getRequest(`${endpoints.PRODUCTCARS}${queryString}`);
}

/**
 * Post a new product car
 * @param {json} body - Stringified JSON data containing product car key-values
 */
export async function postProductCar(body) {
  return await postRequest(endpoints.PRODUCTCARS, body);
}

/**
 * Put a new product car
 * @param {string} id - Id of a product car. This is a UUID value.
 * @param {json} body - Stringified JSON data containing product car key-values
 */
export async function putProductCar(id, body) {
  return await putRequest(`${endpoints.PRODUCTCARS}/${id}`, body);
}

/**
 * Delete a single product car based on the given id value
 * @param {string} id - Id of a product car. This is a UUID value.
 */
export async function deleteProductCar(id) {
  return await deleteRequest(`${endpoints.PRODUCTCARS}/${id}`);
}
