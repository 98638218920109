import { endpoints, getRequest, postRequest, deleteRequest } from '../helpers';

// !====================!
//   !SUPPLIER PRODUCT!
// !====================!

/**
 * Fetch all supplier products
 */
export async function fetchSupplierProducts() {
  return await getRequest(endpoints.SUPPLIERPRODUCTS);
}

/**
 * Fetch a single supplier product
 * @param {string} id - Id of a supplier. This is a UUID value.
 */
export async function fetchSupplierProduct(id) {
  return await getRequest(`${endpoints.SUPPLIERPRODUCTS}/${id}`);
}

/**
 * Post a new supplier product
 * @param {json} body - Stringified JSON data containing supplier product key-values
 */
export async function postSupplierProduct(body) {
  return await postRequest(endpoints.SUPPLIERPRODUCTS, body);
}

/**
 * Put a new supplier product
 * @param {json} body - Stringified JSON data containing supplier product key-values
 */
export async function putSupplierProduct(id, body) {
  return await postRequest(`${endpoints.SUPPLIERPRODUCTS}/${id}`, body);
}

/**
 * Delete a single supplier product based on the given id value
 * @param {string} id - Id of a supplier product item. This is a UUID value.
 */
export async function deleteSupplierProduct(id) {
  return await deleteRequest(`${endpoints.SUPPLIERPRODUCTS}/${id}`);
}
