import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, roles, selectedLocale, ...rest }) => {
  const [user] = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.id !== undefined && roles.includes(user.role)) {
          return <Component {...props} selectedLocale={selectedLocale} />;
        } else {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};
