import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import { fetchAboutUs, postAboutUs, putAboutUs } from '../../../api';
import { textToHTML } from '../../../utils';
import { toastSuccess, toastError } from '../../UIKit/Toastify';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '850px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminAboutUs() {
  const [aboutUs, setAboutUs] = useState({});
  const [newAboutUsNl, setNewAboutUsNl] = useState('');
  const [newAboutUsEn, setNewAboutUsEn] = useState('');
  const [newAboutUsDe, setNewAboutUsDe] = useState('');
  const [newAboutUsFr, setNewAboutUsFr] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchAboutUs();
      setAboutUs(getJson);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setNewAboutUsNl(textToHTML(aboutUs.aboutNl));
    setNewAboutUsEn(textToHTML(aboutUs.aboutEn));
    setNewAboutUsFr(textToHTML(aboutUs.aboutFr));
    setNewAboutUsDe(textToHTML(aboutUs.aboutDe));
  }, [aboutUs]);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const data = JSON.stringify({
      aboutNl: newAboutUsNl.trim(),
      aboutEn: newAboutUsEn.trim(),
      aboutDe: newAboutUsDe.trim(),
      aboutFr: newAboutUsFr.trim(),
    });

    let resJson;
    if (aboutUs.id !== undefined) {
      // about us existed. Therefor put is needed (update)
      resJson = await putAboutUs(aboutUs.id, data);
    } else {
      resJson = await postAboutUs(data);
    }
    if (resJson.success === true) {
      setAboutUs(resJson.data);
      toastSuccess(translate('toast_adminAboutUsUpdatedSuccess'));
      closeModal();
    } else {
      toastError(resJson.error);
      console.error(resJson);
    }
  }

  function resetInputFields() {
    if (aboutUs.id === undefined) {
      setNewAboutUsNl('');
      setNewAboutUsDe('');
      setNewAboutUsFr('');
      setNewAboutUsEn('');
    }
  }

  return (
    <div>
      {/* http://reactcommunity.org/react-modal/ */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Over Ons</h2>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-nl"
                >
                  Nederlands
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-nl"
                  id="name-nl"
                  value={newAboutUsNl}
                  onChange={(e) => setNewAboutUsNl(e.target.value)}
                  required
                  autoFocus
                ></textarea>
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-fr"
                >
                  Frans
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-fr"
                  id="name-fr"
                  value={newAboutUsFr}
                  onChange={(e) => setNewAboutUsFr(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-en"
                >
                  Engels
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-en"
                  id="name-en"
                  value={newAboutUsEn}
                  onChange={(e) => setNewAboutUsEn(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-de"
                >
                  Duits
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-de"
                  id="name-de"
                  value={newAboutUsDe}
                  onChange={(e) => setNewAboutUsDe(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>

            <div className="w-full mt-6 mb-4 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                {translate('cancel')}
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                {translate('save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_aboutus')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {aboutUs.id === undefined
            ? '+ ' + translate('new')
            : translate('edit')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full rounded-lg overflow-hidden">
          {aboutUs.id !== undefined && (
            <>
              <div className="mt-4">
                <h2 className="text-2xl font-semibold leading-tight">
                  Nederlands
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textToHTML(aboutUs.aboutNl),
                  }}
                />
                <hr className="my-4" />
              </div>
              <div className="mt-4">
                <h2 className="text-2xl font-semibold leading-tight">Engels</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textToHTML(aboutUs.aboutEn),
                  }}
                />
                <hr className="my-4" />
              </div>
              <div className="mt-4">
                <h2 className="text-2xl font-semibold leading-tight">Frans</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textToHTML(aboutUs.aboutFr),
                  }}
                />
                <hr className="my-4" />
              </div>
              <div className="mt-4">
                <h2 className="text-2xl font-semibold leading-tight">Duits</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textToHTML(aboutUs.aboutDe),
                  }}
                />
                <hr className="my-4" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
