import { endpoints, getRequest, postRequest, deleteRequest } from '../helpers';

// !=============!
//   !ADDRESSES!
// !=============!

/**
 * Fetch all addresses
 */
export async function fetchAddresses() {
  return await getRequest(endpoints.ADDRESSES);
}

/**
 * Fetch a single address item
 * @param {string} id - Id of an address. This is a UUID value.
 */
export async function fetchAddress(id) {
  return await getRequest(`${endpoints.ADDRESSES}/${id}`);
}

/**
 * Fetch address item of current logged in user
 */
export async function fetchMyAddress() {
  return await getRequest(`${endpoints.ADDRESSES}/me`);
}

/**
 * Post a new address item
 * @param {json} body - Stringified JSON data containing address key-values
 */
export async function postAddress(body) {
  return await postRequest(endpoints.ADDRESSES, body);
}

/**
 * Delete a single address item based on the given id value
 * @param {string} id - Id of an address item. This is a UUID value.
 */
export async function deleteAddress(id) {
  return await deleteRequest(`${endpoints.ADDRESSES}/${id}`);
}
