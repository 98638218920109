import React from 'react';
import { Link } from 'react-router-dom';
import translate from '../../../i18n/translate';
import LabeledInputFieldNumber from '../../UIKit/Inputs/LabeledInputFieldNumber';
import ButtonActionOutlineDanger from '../../UIKit/Buttons/ButtonActionOutlineDanger';

export default function BasketLargeScreen({
  basketItems,
  handleDeleteProduct,
  handleProductAmountChange,
  selectedLanguage,
}) {
  
  function totalPrice(basketItem) {
    const price = productPrice(basketItem);
    const totalPrice = parseFloat(price * basketItem.count);
    return totalPrice.toFixed(2);
  }

  function translatedString(object, language) {
    if (language) {
      switch (language) {
        case 'en-US':
          return object.nameEn;
        case 'de-DE':
          return object.nameDe;
        case 'fr-BE':
          return object.nameFr;
        default:
          return object.nameNl;
      }
    }
  }

  function productPrice(basketItem) {
    const product = basketItem.product;
    const variant = basketItem.variant;
    let price = parseFloat(
      product.priceDiscount ? product.priceDiscount : product.price
    );
    if (variant) {
      price += parseFloat(variant.price);
    }
    return price.toFixed(2);
  }

  return (
    <div className="w-full leading-normal">
      <table className="w-full">
        <tbody>
          {basketItems && basketItems.length > 0 ? (
            basketItems.map((item) => (
              <tr
                className="border-b border-solid border-gray-700"
                key={item.basketId}
              >
                <td>
                  <img
                    loading="lazy"
                    src={`/uploads/products/thumbnails/${item.product.photo}`}
                    alt="product"
                    className="w-32 h-32 my-2 object-contain pl-3"
                  />
                </td>
                <td>
                  <Link
                    to={'/shop/' + item.product.slug}
                    className="text-lg font-bold overflow-hidden hover:underline block"
                  >
                    {selectedLanguage === 'en-US' && (
                      <span>{item.product.titleEn}</span>
                    )}
                    {selectedLanguage === 'de-DE' && (
                      <span>{item.product.titleDe}</span>
                    )}
                    {selectedLanguage === 'fr-BE' && (
                      <span>{item.product.titleFr}</span>
                    )}
                    {selectedLanguage === 'nl-BE' && (
                      <span>{item.product.titleNl}</span>
                    )}
                  </Link>
                  {item.variant !== undefined && (
                    <div className="flex flex-col">
                      {item.variant.headers.map((header, index) => {
                        return (
                          <span
                            className="text-gray-600 text-sm"
                            key={header.nameNl}
                          >
                            {translatedString(header, selectedLanguage) +
                              ': ' +
                              translatedString(
                                item.variant.items[index],
                                selectedLanguage
                              )}
                          </span>
                        );
                      })}
                    </div>
                  )}
                </td>
                <td className="text-sm">
                  {item.car !== undefined &&
                    `${item.car.brand} ${item.car.model} ${item.car.type}`}
                </td>
                <td>
                  <ButtonActionOutlineDanger
                    text={translate('delete')}
                    handleClick={() => handleDeleteProduct(item)}
                  />
                </td>
                <td>
                  <div className="flex items-center">
                    <div className="w-40 flex justify-center items-center">
                      <span className="mr-2">{translate('amount')}</span>
                      <LabeledInputFieldNumber
                        name="product-count"
                        value={item.count}
                        onChange={(e) =>
                          handleProductAmountChange(
                            e.target.value,
                            item.basketId
                          )
                        }
                        required={true}
                        min={1}
                        max={1000}
                      />
                    </div>
                    <span className="mx-2">x</span>€ {productPrice(item)}
                  </div>
                </td>
                <td className="text-right pr-3 font-bold">
                  € {totalPrice(item)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>
                <span className="my-2 text-center">
                  {translate('basket_noItems')}
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
