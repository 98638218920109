import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import LabeledInputFieldText from '../../UIKit/Inputs/LabeledInputFieldText';
import LabeledInputFieldNumber from '../../UIKit/Inputs/LabeledInputFieldNumber';
import ButtonActionPrimary from '../../UIKit/Buttons/ButtonActionPrimary';
import ButtonActionOutlinePrimary from '../../UIKit/Buttons/ButtonActionOutlinePrimary';
import TextHead from '../../UIKit/Labels/TextHead';
import {
  fetchAllVariants,
  postVariant,
  putVariant,
  deleteVariant,
} from '../../../api';
import { FaTrashAlt, FaCopy } from 'react-icons/fa';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '650px',
    height: '560px',
    transform: 'translate(-50%, -50%)',
  },
};
const customStylesAddItem = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '450px',
    height: '535px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
}

export default function AdminVariants() {
  const forceUpdate = useForceUpdate();
  const [variants, setVariants] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalAddItemIsOpen, setModalAddItemIsOpen] = useState(false);

  const [editingVariant, setEditingVariant] = useState({});
  const [inputHeaderDutch, setInputHeaderDutch] = useState('');
  const [inputHeaderEnglish, setInputHeaderEnglish] = useState('');
  const [inputHeaderFrench, setInputHeaderFrench] = useState('');
  const [inputHeaderGerman, setInputHeaderGerman] = useState('');
  const [inputPrefix, setInputPrefix] = useState('');
  const [newVariantItems, setNewVariantItems] = useState([]);
  const [inputItemDutch, setInputItemDutch] = useState('');
  const [inputItemEnglish, setInputItemEnglish] = useState('');
  const [inputItemFrench, setInputItemFrench] = useState('');
  const [inputItemGerman, setInputItemGerman] = useState('');
  const [inputItemPrice, setInputItemPrice] = useState();

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    resetInputFieldsAddItem();
    setModalIsOpen(false);
  }

  function closeAddItemModal() {
    resetInputFieldsAddItem();
    setModalAddItemIsOpen(false);
  }

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchAllVariants();
      let newVariants = getJson.map((variant) => {
        return {
          id: variant.id,
          nameNl: variant.nameNl,
          nameEn: variant.nameEn,
          nameFr: variant.nameFr,
          nameDe: variant.nameDe,
          prefix: variant.prefix,
          data: JSON.parse(variant.data),
        };
      });
      setVariants(newVariants);
    }
    fetchData();
  }, []);

  function handleAddItem() {
    const newItem = {
      dutch: inputItemDutch,
      english: inputItemEnglish,
      french: inputItemFrench,
      german: inputItemGerman,
      price: inputItemPrice,
    };
    let items = newVariantItems;
    items.push(newItem);
    setNewVariantItems(items);
    closeAddItemModal();
  }

  async function handleDeleteVariant(id) {
    const deleteJson = await deleteVariant(id);
    if (deleteJson.success === true) {
      const filteredVariants = variants.filter((variant) => {
        return variant.id !== id;
      });
      setVariants(filteredVariants);
      toastSuccess(translate('Variant deleted successfully'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  async function handleUpdateVariant(variant) {
    setEditingVariant(variant);
    setInputHeaderDutch(variant.nameNl);
    setInputHeaderEnglish(variant.nameEn);
    setInputHeaderFrench(variant.nameFr);
    setInputHeaderGerman(variant.nameDe);
    setInputPrefix(variant.prefix);
    let items = [];
    variant.data.forEach((item) => {
      const newItem = {
        dutch: item.dutch,
        english: item.english,
        french: item.french,
        german: item.german,
        price: item.price,
      };
      items.push(newItem);
    });
    setNewVariantItems(items);
    setModalIsOpen(true);
  }

  async function onSubmitPressed(e) {
    e.preventDefault();

    if (editingVariant.id && editingVariant.id.length > 0) {
      // ? existing variant. Submit as update.
      submitExistingVariant();
    } else {
      // ? new variant. Submit as new.
      submitNewVariant();
    }
  }

  async function submitNewVariant() {
    const postJson = await postVariant(
      JSON.stringify({
        nameNl: inputHeaderDutch,
        nameEn: inputHeaderEnglish,
        nameDe: inputHeaderGerman,
        nameFr: inputHeaderFrench,
        prefix: inputPrefix,
        data: JSON.stringify(newVariantItems),
      })
    );
    if (postJson.success === true) {
      const postData = postJson.data;
      const newVariant = {
        ...postData,
        data: JSON.parse(postData.data),
      };
      setVariants((previousVariants) => [...previousVariants, newVariant]);
      toastSuccess(translate('Variant added successfully'));
      closeModal();
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }
  async function submitExistingVariant() {
    const variantJson = JSON.stringify({
      nameNl: inputHeaderDutch,
      nameEn: inputHeaderEnglish,
      nameDe: inputHeaderGerman,
      nameFr: inputHeaderFrench,
      prefix: inputPrefix,
      data: JSON.stringify(newVariantItems),
    });
    const putJson = await putVariant(editingVariant.id, variantJson);
    if (putJson.success === true) {
      const responseData = putJson.data;
      const variantId = responseData.id;
      let updatingVariants = variants;
      const updatedVariant = updatingVariants.filter(
        (variant) => variant.id === variantId
      )[0];
      const index = variants.indexOf(updatedVariant);
      updatingVariants[index] = {
        id: responseData.id,
        nameNl: responseData.nameNl,
        nameEn: responseData.nameEn,
        nameFr: responseData.nameFr,
        nameDe: responseData.nameDe,
        prefix: responseData.prefix,
        data: JSON.parse(responseData.data),
      };
      setVariants(updatingVariants);
      toastSuccess(translate('Variant updated successfully'));
      closeModal();
    } else {
      toastError(putJson.error);
      console.error(putJson);
    }
  }

  function resetInputFields() {
    setEditingVariant({});
    setInputHeaderDutch('');
    setInputHeaderEnglish('');
    setInputHeaderFrench('');
    setInputHeaderGerman('');
    setInputPrefix('');
    setNewVariantItems([]);
  }

  function resetInputFieldsAddItem() {
    setInputItemDutch('');
    setInputItemEnglish('');
    setInputItemFrench('');
    setInputItemGerman('');
    setInputItemPrice();
  }

  function handleDeleteItem(index) {
    if (index >= newVariantItems.length) {
      console.error('error removing variant item: index is out of bounds');
    }
    let items = newVariantItems;
    items.splice(index, 1);
    setNewVariantItems(items);
    forceUpdate();
  }

  function handleCopyItem(item) {
    setInputItemDutch(item.dutch);
    setInputItemEnglish(item.english);
    setInputItemFrench(item.french);
    setInputItemGerman(item.german);
    setModalAddItemIsOpen(true);
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Variant toevoegen</h2>
          </div>

          <form className="w-full mb-4" onSubmit={onSubmitPressed}>
            <div className="flex flex-row w-full space-x-4 mb-5">
              <div className="flex-1 flex flex-col">
                <TextHead text="Header text" center={false} />
                <LabeledInputFieldText
                  type="text"
                  placeholder="Dutch"
                  value={inputHeaderDutch}
                  required={true}
                  grouped={true}
                  onChange={(e) => setInputHeaderDutch(e.target.value)}
                />
                <LabeledInputFieldText
                  type="text"
                  placeholder="English"
                  value={inputHeaderEnglish}
                  required={true}
                  grouped={true}
                  onChange={(e) => setInputHeaderEnglish(e.target.value)}
                />
                <LabeledInputFieldText
                  type="text"
                  placeholder="French"
                  value={inputHeaderFrench}
                  required={true}
                  grouped={true}
                  onChange={(e) => setInputHeaderFrench(e.target.value)}
                />
                <LabeledInputFieldText
                  type="text"
                  placeholder="German"
                  value={inputHeaderGerman}
                  required={true}
                  grouped={true}
                  onChange={(e) => setInputHeaderGerman(e.target.value)}
                />
                <LabeledInputFieldText
                  type="text"
                  label="prefix"
                  value={inputPrefix}
                  required={true}
                  onChange={(e) => setInputPrefix(e.target.value)}
                />
              </div>
              <div className="flex-1 flex flex-col space-y-1">
                <TextHead text="Items" center={false} />
                {newVariantItems && newVariantItems.length > 0 && (
                  <div className="pb-4">
                    {newVariantItems.map((item, index) => {
                      return (
                        <div
                          key={item.dutch}
                          className="py-2 flex flex-row justify-between items-center bg-gray-200 my-1 rounded"
                        >
                          <span className="mx-2 text-sm flex flex-col justify-start">
                            <span>{item.dutch}</span>
                            <span className="text-xs text-gray-600">
                              € {parseFloat(item.price).toFixed(2)}
                            </span>
                          </span>
                          <div className="flex flex-row space-x-4 justify-end items-center mr-2">
                            <FaCopy
                              className="cursor-pointer hover:text-green-700"
                              onClick={() => handleCopyItem(item)}
                            />
                            <FaTrashAlt
                              className="cursor-pointer hover:text-green-700"
                              onClick={() => handleDeleteItem(index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <ButtonActionOutlinePrimary
                  text="+ Add"
                  type="button"
                  handleClick={() => setModalAddItemIsOpen(true)}
                />
              </div>
            </div>
            <ButtonActionPrimary text="Save" type="submit" />
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalAddItemIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeAddItemModal}
        style={customStylesAddItem}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Item toevoegen</h2>
          </div>

          <div className="flex flex-col w-full">
            <TextHead text="Item text" center={false} />
            <LabeledInputFieldText
              type="text"
              placeholder="Dutch"
              value={inputItemDutch}
              onChange={(e) => setInputItemDutch(e.target.value)}
              grouped={true}
              required={true}
            />
            <LabeledInputFieldText
              type="text"
              placeholder="English"
              value={inputItemEnglish}
              onChange={(e) => setInputItemEnglish(e.target.value)}
              grouped={true}
              required={true}
            />
            <LabeledInputFieldText
              type="text"
              placeholder="French"
              value={inputItemFrench}
              onChange={(e) => setInputItemFrench(e.target.value)}
              grouped={true}
              required={true}
            />
            <LabeledInputFieldText
              type="text"
              placeholder="German"
              value={inputItemGerman}
              onChange={(e) => setInputItemGerman(e.target.value)}
              grouped={true}
              required={true}
            />

            <div className="w-1/2 my-2 flex flex-row space-x-3 items-center">
              <span>€</span>
              <LabeledInputFieldNumber
                placeholder="Price"
                value={inputItemPrice}
                onChange={(e) => setInputItemPrice(e.target.value)}
                required={true}
              />
            </div>
            <ButtonActionOutlinePrimary
              text="Add item"
              type="button"
              handleClick={handleAddItem}
            />
          </div>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_variants')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal(false)}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('Name')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('Prefix')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('Count')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {variants &&
                variants.length > 0 &&
                variants.map((variant) => {
                  return (
                    <tr key={variant.id}>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {variant.nameNl}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {variant.prefix}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {variant.data && variant.data.length}
                        </p>
                      </td>
                      <td className="p-3 px-5 border-b border-gray-200 bg-white text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleUpdateVariant(variant)}
                          className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('edit')}
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteVariant(variant.id)}
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
