import React from 'react';
import TextLabel from '../Labels/TextLabel';

export default function LabeledInputAreaText({
  name = 'input-area-text',
  label,
  value,
  onChange,
  required,
  disabled = false,
}) {
  return (
    <div className="w-full py-3">
      <TextLabel name={name} label={label} />
      <textarea
        className={
          'no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none ' +
          (disabled ? 'cursor-not-allowed text-gray-600 bg-gray-100' : 'border')
        }
        name={name}
        id={name}
        value={value}
        required={required}
        onChange={onChange}
        disabled={disabled}
      ></textarea>
    </div>
  );
}
