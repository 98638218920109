import { Locales } from '../locales';

export default {
  [Locales.English]: {
    // navbar
    shop: 'Shop',
    aboutUs: 'About us',
    gallery: 'Gallery',
    contact: 'Contact',
    approvedByCMA: 'Approved by CMA',
    login: 'Login',

    // footer
    footer_termsAndConditions: 'Terms & Conditions',
    footer_followUs: 'Follow us',
    footer_poweredBy: 'With the support of',

    // contact
    contact_name: 'Name',
    contact_message: 'Message',
    contact_whichCarQuestion: 'Which car is your question about?',
    contact_howDoYouKnowCMA: 'How do you know CMA?',
    contact_sendMeACopy: 'Send me a copy',
    contact_previousPurchase: 'A previous purchase',
    contact_aFriend: 'A friend',
    contact_aCarMeet: 'A car meet',

    // login
    login_title: 'Sign In',
    login_dontHaveAnAccount: "Don't have an account?",
    login_forgotPassword: 'Forgot password?',

    // sign up
    signup_title: 'Create An Account',
    signup_confirmPassword: 'Confirm password',
    signup_birthday: 'Date of birth',

    // forgot password
    forgotpassword_title: 'Forgot Password',

    // shop
    shop_comeBackLater:
      'No products found. We are updating the store every day. Please come back soon!',

    // product details
    details_alwaysInStock: 'Always in stock',
    details_outOfStock: 'Sold out!',
    details_xInStock: '{amount} in stock',
    details_articleNr: 'Article number:',
    details_details: 'details',
    details_shop: 'shop',
    details_askQuestion: 'Question about this product',
    details_availableForCars: 'Fits the following cars:',
    details_totalPrice: 'Total price',
    details_notAllVariantsSelected: 'Please select from all fields',
    details_selectAnOption: '-- select an option --',
    details_customerPriceWithVAT: 'Customer price with VAT',
    details_resellerPriceWithoutVAT: 'Reseller price without VAT',
    details_resellerPriceWithVAT: 'Reseller price with VAT',
    details_margeWithoutVAT: 'Marge without VAT',
    details_freeShipment: 'Free shipment',
    details_youMightAlsoLike: 'You might also like',
    details_youMightAlsoNeed: 'You might also need',

    // admin
    admin_orders: 'Orders',
    admin_users: 'Users',
    admin_products: 'Products',
    admin_settings: 'Settings',
    admin_cars: 'Cars',
    admin_social: 'Social',
    admin_aboutus: 'About us',
    admin_approvedBy: 'Approved by CMA',
    admin_gallery: 'Gallery',
    admin_articleNumber: 'Article Number',
    admin_productName: 'Name',
    admin_price: 'Price',
    admin_discount: 'Discount Price',
    admin_variants: 'Variants',
    admin_extraInfo: 'Exta info',
    admin_termsAndConditions: 'Terms and conditions',
    admin_supplier: 'Supplier',
    admin_supplierProducts: 'Supplier products',
    admin_customer: 'Customer',
    admin_generalImages: 'General images',
    admin_purchases: 'Purchases',
    admin_sales: 'Sales',
    admin_car: 'Car',
    admin_productName: 'Product name',

    // profile
    profile_general: 'General',
    profile_addresses: 'Addresses',
    profile_changePassword: 'Change password',
    profile_createAddress: 'Create address',
    profile_noAddressAvailable:
      'No addresses available yet! Please create one.',
    profile_defaultLanguageDescription:
      'Attention: when changing the language in the navigation section, this will not update the default language on your profile.',

    // basket
    basket_totalArticles: 'Total articles',
    basket_deliveryCosts: 'Delivery Costs',
    basket_total: 'Total',
    basket_paymentMethod: 'Payment method',
    basket_delivery: 'Delivery',
    basket_pickUp: 'Pick up',
    basket_addressOutOfReach:
      'Shipping address may be outside the possible delivery zone. Please contact to discuss delivery costs.',
    basket_basket: 'Basket',
    basket_noItems:
      'There are no items in your basket, yet! If there should be items in your shopping cart, but they are not visible, chance is that these items may have been removed from the store.',
    basket_confirmation: 'Confirmation',
    basket_orderProcessingTitle: '-|--> Payment processing',
    basket_orderSuccessTitle: '✅ Payment successful',
    basket_orderFailedTitle: '❌ Payment failed',
    basket_orderProcessingDescription:
      'We are processing your order. you can view your order status in your profile.',
    basket_orderSuccessDescription:
      'We received your order and payment! We will get started with your order as soon as possible. You can view your order statuses in your profile.',
    basket_orderFailedDescription:
      'Something went wrong with your payment. Please check your order. You can do this in your profile.',
    basket_loginToProceed:
      'You must be logged in in order to continue this order.',
    basket_outsideDeliveryZone: 'Outside delivery zone',
    basket_calculatedAtNextStep: 'Calculated at next step',
    basket_customer: 'Customer',
    basket_proceedForCustomer: 'Proceed for customer',

    // sidebar
    sidebar_profile: 'Profile',
    sidebar_admin: 'Admin',
    sidebar_orderHistory: 'Orders',

    // toast
    toast_mailSuccess: 'Mail sent successfully',
    toast_mailFailed: 'Mail sent failed',
    toast_passwordChangeSuccess: 'Password updated successfully',
    toast_passwordChangeFailed: 'Password update failed',
    toast_addressAddSuccess: 'Address added successfully',
    toast_addressDeleteSuccess: 'Address deleted successfully',
    toast_addressAddFailed: 'Address add failed',
    toast_basketUpdatedSuccess: 'Basket updated successfully',
    toast_basketUpdatedFailed: 'Basket update failed',
    toast_generalInformationSuccess: 'General information updated successfully',
    toast_generalInformationError: 'General information update failed',
    toast_adminOrderUpdatedSuccess: 'Order updated successfully',
    toast_adminAboutUsUpdatedSuccess: 'About us updated successfully',
    toast_adminSocialDeleteSuccess: 'Social deleted successfully',
    toast_adminSocialAddSuccess: 'Social added successfully',
    toast_adminCarDeleteSuccess: 'Car deleted successfully',
    toast_adminCarAddSuccess: 'Car added successfully',
    toast_adminCarUpdateSuccess: 'Car updated successfully',
    toast_adminCategoryDeleteSuccess: 'Category deleted successfully',
    toast_adminCategoryAddSuccess: 'Category added successfully',
    toast_adminCategoryUpdateSuccess: 'Category updated successfully',
    toast_adminSubcategoryDeleteSuccess: 'Subcategory deleted successfully',
    toast_adminSubcategoryAddSuccess: 'Subcategory added successfully',
    toast_adminSubcategoryEditSuccess: 'Subcategory updated successfully',
    toast_adminProductDeleteSuccess: 'Product deleted successfully',
    toast_adminProductAddSuccess: 'Product added successfully',
    toast_adminProductUpdateSuccess: 'Product updated successfully',
    toast_adminGalleryDeleteSuccess: 'Gallery deleted successfully',
    toast_adminGalleryAddSuccess: 'Gallery added successfully',
    toast_adminApprovedByUpdateSuccess: 'Approved by updated successfully',
    toast_adminApprovedByDeleteSuccess: 'Approved by deleted successfully',
    toast_adminApprovedByAddSuccess: 'Approved by added successfully',
    toast_registerSuccess: 'Registration successful',
    toast_basketUpdateFailed:
      'Logged in successful, but something went wrong updating the basket.',
    toast_forgotPasswordSuccessful:
      'Password reset successfully sent to your email address',
    toast_defaultAddressSuccess: 'Default address updated successfully',
    toast_logoutFailed: 'Logout failed. Try again later...',
    toast_adminUserUpdateSuccess: 'User updated successfully',

    // orders
    orders_manageOrder: 'Manage order',
    orders_generalInfo: 'General info',
    orders_updated: 'Last updated',
    orders_status: 'Status',
    orders_order: 'Order',
    orders_delivery: 'Delivery',
    orders_addressBuyer: 'Address buyer',
    orders_products: 'Products',
    orders_product: 'Product',
    orders_variants: 'Variants',
    orders_amount: 'Amount',
    orders_total: 'Total',
    orders_paymentMethod: 'Payment method',
    orders_date: 'Date',
    orders_orderNumber: 'Order number',
    orders_completed: 'Completed',
    orders_orderDeletedSuccess: 'Order deleted successfully',

    // generics
    email: 'Email',
    password: 'Password',
    description: 'Description',
    loading: 'Loading...',
    brand: 'Brand',
    model: 'Model',
    type: 'Type',
    firstName: 'First name',
    lastName: 'Last name',
    company: 'Company',
    vat: 'VAT number',
    phone: 'Phone',
    street: 'Street',
    streetNumber: 'Number',
    streetBox: 'Box',
    postal: 'Postal',
    city: 'City',
    country: 'Country',
    highlighted: 'Highlighted products',
    language: 'Language',
    defaultLanguage: 'Default language',
    categories: 'Categories',
    subcategories: 'Subcategories',
    category: 'Category',
    subcategory: 'Subcategory',
    amount: 'Amount',
    yes: 'Yes',
    no: 'No',

    // buttons
    send: 'Send',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    signOut: 'Sign Out',
    resetPassword: 'Reset Password',
    back: 'Back',
    inBasket: 'In Basket',
    admin: 'Admin',
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    new: '+ New',
    makeDefault: 'Make Default',
    questionAboutProduct: 'Question about this product?',
    delete: 'Delete',
    proceed: 'Proceed',
    pay: 'Pay',
    copy: 'Copy',
    filter: 'Filter',
    details: 'Details',
    close: 'Close',

    // errors
    error_fieldRequired: 'This field is required',
    error_passwordsNotMatching: 'Passwords are not matching',
    error_passwordsEmpty: 'One of the passwords is empty',
    error_birthdayNotCorrect: 'Invalid birthday',
  },
};
