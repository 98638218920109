import React from 'react';
import TextLabel from '../Labels/TextLabel';

export default function LabeledInputFieldText({
  type,
  name = 'input-field-text',
  label,
  value,
  placeholder,
  onChange,
  required,
  grouped = false,
  disabled = false,
}) {
  return (
    <div className={'w-full ' + (!grouped && 'py-3')}>
      <TextLabel name={name} label={label} />
      <input
        className={
          'w-full p-3 rounded ' +
          (disabled ? 'cursor-not-allowed text-gray-600 bg-gray-100' : 'border')
        }
        name={name}
        id={name}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
      />
    </div>
  );
}
