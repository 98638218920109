import React from 'react';
import PositionSelector from './PositionSelector';

export default function ImageSelectorWithWatermark({
  title,
  name,
  file,
  blob,
  imageName = undefined,
  changed,
  deletable,
  handleFileChange,
  handlePositionChange,
  handleExistingImageDelete,
}) {
  return (
    <div className="flex flex-col">
      <div>
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor={name}
        >
          {title}
          {deletable === false && (
            <span className="text-red-500 text-xs mx-2">(mandatory)</span>
          )}
        </label>
        {changed === true && (
          <div>
            <span className="italic text-teal-500 text-xs">(gewijzigd)</span>
            <br />
          </div>
        )}
        {(deletable === false || !imageName) && (
          <input
            type="file"
            accept="image/*"
            name={name}
            onChange={(e) => handleFileChange(e)}
            draggable
          />
        )}
        {deletable === false &&
          imageName !== undefined &&
          imageName.length > 0 && (
            <span className="text-sm italic text-teal-600 px-2">
              (voeg afbeelding toe om aan te passen)
            </span>
          )}
      </div>

      {file === undefined && imageName !== undefined && imageName.length > 0 ? (
        <div className="flex flex-row space-x-2 items-center">
          <img
            loading="lazy"
            src={'/uploads/products/photos/' + imageName}
            className="h-48 w-48 mr-4 object-contain"
          />
          {deletable === true && (
            <button
              type="button"
              onClick={() => handleExistingImageDelete(name)}
              className="text-sm bg-red-500 hover:bg-red-700 text-white h-8 px-2 rounded focus:outline-none focus:shadow-outline"
            >
              Verwijderen
            </button>
          )}
        </div>
      ) : (
        <div className="flex flex-col">
          {file && (
            <div className="flex flex-row space-x-4">
              <div>
                <p className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2 mb-1">
                  Preview
                </p>
                <img
                  loading="lazy"
                  src={blob}
                  className="h-48 w-48 mr-4 object-contain"
                />
              </div>
              <div>
                <p className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2 mb-1">
                  Watermerk
                </p>
                <PositionSelector
                  positionChange={(index) => handlePositionChange(index, name)}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
