import React from 'react';

export default function TextLabel({ name, label }) {
  return (
    <label
      className="block uppercase text-gray-700 text-xs font-bold mb-2"
      htmlFor={name}
    >
      {label}
    </label>
  );
}
