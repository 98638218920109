import React from 'react';
import UserContactInformation from './UserContactInformation';
import UserContactAddress from './UserContactAddress';
import UserChangePassword from './UserChangePassword';

export default function Profile() {
  return (
    <div className="w-full container">
      <UserContactInformation />
      <UserContactAddress />
      <UserChangePassword />
    </div>
  );
}
