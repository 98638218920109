import { endpoints, getRequest, postRequest, deleteRequest } from '../helpers';

// !===========!
//   !SOCIAL!
// !===========!

/**
 * Fetch all social items
 */
export async function fetchSocials() {
  return await getRequest(endpoints.SOCIAL);
}

/**
 * Fetch a single social item
 * @param {string} id - Id of a subcategory. This is a UUID value.
 */
export async function fetchSocial(id) {
  return await getRequest(`${endpoints.SOCIAL}/${id}`);
}

/**
 * Post a new social item
 * @param {json} body - Stringified JSON data containing subcategory key-values
 */
export async function postSocial(body) {
  return await postRequest(endpoints.SOCIAL, body);
}

/**
 * Delete a single social item based on the given id value
 * @param {string} id - Id of a social item. This is a UUID value.
 */
export async function deleteSocial(id) {
  return await deleteRequest(`${endpoints.SOCIAL}/${id}`);
}
