import React, { useState, useEffect, useContext } from 'react';
import translate from '../i18n/translate';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { BasketContext } from '../context/BasketContext';
import { fetchMe, fetchMyBasket } from '../api';
import { IconContext } from 'react-icons';
import { FaShoppingCart } from 'react-icons/fa';
import ButtonRoutableOutlineWhite from './UIKit/Buttons/ButtonRoutableOutlineWhite';

export default function Header({ handleLanguageChange }) {
  const [user, setUser] = useContext(UserContext);
  const [basket, setBasket] = useContext(BasketContext);
  const [basketCount, setBasketCount] = useState(0);
  const [basketCountViewMarginRight, setBasketCountViewMarginRight] = useState(
    'mr-3'
  );

  useEffect(() => {
    async function fetchData() {
      const getUserJson = await fetchMe();
      if (getUserJson.success === true) {
        const getBasketJson = await fetchMyBasket();
        setUser(getUserJson.data);
        setBasket(getBasketJson);
      } else {
        setUser({});
        // check if local basket exists
        const localBasket = JSON.parse(localStorage.getItem('basket'));
        if (localBasket) {
          setBasket(localBasket);
        } else {
          setBasket([]);
        }
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (basket.length > 0) {
      const totalCount = basket.reduce((currentCount, basketItem) => {
        return currentCount + parseInt(basketItem.count);
      }, 0);

      let marginRight = 'mr-3';
      if (totalCount >= 10000) {
        marginRight = 'mr-12';
      } else if (totalCount >= 1000) {
        marginRight = 'mr-8';
      } else if (totalCount >= 100) {
        marginRight = 'mr-6';
      } else if (totalCount >= 10) {
        marginRight = 'mr-5';
      }
      setBasketCountViewMarginRight(marginRight);
      setBasketCount(totalCount);
    } else {
      setBasketCountViewMarginRight('mr-3');
      setBasketCount(0);
    }
  }, [basket]);

  return (
    <header>
      <nav className="flex items-center justify-between flex-wrap bg-cmabrown px-6 py-3">
        <div className="w-full sm:w-auto mb-3 sm:mb-0 flex justify-center items-center sm:mr-6">
          <Link to="/shop">
            <span className="font-semibold text-2xl tracking-tight text-white text-center sm:text-left">
              Central Modification Agency
            </span>
          </Link>
        </div>

        <div className="w-full sm:w-auto flex sm:flex-row flex-col justify-center items-center">
          <div className="flex justify-center items-center mx-2 my-4">
            <span
              className="pr-4 text-2xl cursor-pointer"
              onClick={() => handleLanguageChange('nl-BE')}
            >
              <img
                src="/images/lang/language_dutch.png"
                alt="NL"
                className="w-8 h-8"
              />
            </span>
            <span
              className="pr-4 text-2xl cursor-pointer"
              onClick={() => handleLanguageChange('fr-BE')}
            >
              <img
                src="/images/lang/language_french.png"
                alt="FR"
                className="w-8 h-8"
              />
            </span>
            <span
              className="pr-4 text-2xl cursor-pointer"
              onClick={() => handleLanguageChange('en-US')}
            >
              <img
                src="/images/lang/language_english.png"
                alt="EN"
                className="w-8 h-8"
              />
            </span>
            <span
              className="pr-4 text-2xl cursor-pointer"
              onClick={() => handleLanguageChange('de-DE')}
            >
              <img
                src="/images/lang/language_german.png"
                alt="DE"
                className="w-8 h-8"
              />
            </span>
          </div>
          <div className="w-full sm:w-auto flex justify-center items-center">
            <div className="mx-3">
              {!user || user.id === undefined ? (
                <ButtonRoutableOutlineWhite
                  to="/login"
                  text={translate('login')}
                />
              ) : user.role === 'admin' ? (
                <ButtonRoutableOutlineWhite
                  to="/admin"
                  text={translate('admin')}
                />
              ) : user.role === 'user' || user.role === 'reseller' ? (
                <ButtonRoutableOutlineWhite
                  to="/user"
                  text={translate('sidebar_profile')}
                />
              ) : null}
            </div>

            <Link to="/checkout">
              <div className="relative">
                <IconContext.Provider value={{ color: 'white' }}>
                  <FaShoppingCart
                    className={
                      'static cursor-pointer h-8 w-8 ' +
                      basketCountViewMarginRight
                    }
                  />
                  {basketCount > 0 && (
                    <div className="absolute h-6 px-2 top-0 right-0 bg-cmagreen border-cmabrown border-2 rounded-full flex justify-center items-center">
                      <span className="text-xs text-center text-white font-bold">
                        {basketCount}
                      </span>
                    </div>
                  )}
                </IconContext.Provider>
              </div>
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
}
