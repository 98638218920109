import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import {
  fetchTermsAndConditions,
  postTermsAndConditions,
  putTermsAndConditions,
} from '../../../api';
import { textToHTML } from '../../../utils';
import { toastSuccess, toastError } from '../../UIKit/Toastify';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '650px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminTnC() {
  const [termsAndConditions, setTermsAndConditions] = useState({});
  const [newTnCNl, setNewTnCNl] = useState('');
  const [newTnCEn, setNewTnCEn] = useState('');
  const [newTnCDe, setNewTnCDe] = useState('');
  const [newTnCFr, setNewTnCFr] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchTermsAndConditions();
      setTermsAndConditions(getJson);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setNewTnCNl(textToHTML(termsAndConditions.nameNl));
    setNewTnCEn(textToHTML(termsAndConditions.nameEn));
    setNewTnCFr(textToHTML(termsAndConditions.nameFr));
    setNewTnCDe(textToHTML(termsAndConditions.nameDe));
  }, [termsAndConditions]);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const data = JSON.stringify({
      nameNl: newTnCNl.trim(),
      nameEn: newTnCEn.trim(),
      nameDe: newTnCDe.trim(),
      nameFr: newTnCFr.trim(),
    });

    let resJson;
    if (termsAndConditions.id !== undefined) {
      // existed. Therefor put is needed (update)
      resJson = await putTermsAndConditions(termsAndConditions.id, data);
    } else {
      resJson = await postTermsAndConditions(data);
    }
    if (resJson.success === true) {
      setTermsAndConditions(resJson.data);
      toastSuccess(translate('toast_adminTnCUpdatedSuccess'));
      closeModal();
    } else {
      toastError(resJson.error);
      console.error(resJson);
    }
  }

  function resetInputFields() {
    if (termsAndConditions.id === undefined) {
      setNewTnCNl('');
      setNewTnCDe('');
      setNewTnCFr('');
      setNewTnCEn('');
    }
  }

  return (
    <div>
      {/* http://reactcommunity.org/react-modal/ */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Terms and Conditions</h2>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-nl"
                >
                  Nederlands
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-nl"
                  id="name-nl"
                  value={newTnCNl}
                  onChange={(e) => setNewTnCNl(e.target.value)}
                  required
                  autoFocus
                ></textarea>
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-fr"
                >
                  Frans
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-fr"
                  id="name-fr"
                  value={newTnCFr}
                  onChange={(e) => setNewTnCFr(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-en"
                >
                  Engels
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-en"
                  id="name-en"
                  value={newTnCEn}
                  onChange={(e) => setNewTnCEn(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-de"
                >
                  Duits
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-de"
                  id="name-de"
                  value={newTnCDe}
                  onChange={(e) => setNewTnCDe(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>

            <div className="w-full mt-6 mb-4 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                {translate('cancel')}
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                {translate('save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_termsAndConditions')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {termsAndConditions.id === undefined
            ? '+ ' + translate('new')
            : translate('edit')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full rounded-lg overflow-hidden">
          {termsAndConditions.id !== undefined && (
            <>
              <div className="mt-4">
                <h2 className="text-2xl font-semibold leading-tight">
                  Nederlands
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textToHTML(termsAndConditions.nameNl),
                  }}
                />
                <hr className="my-4" />
              </div>
              <div className="mt-4">
                <h2 className="text-2xl font-semibold leading-tight">Engels</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textToHTML(termsAndConditions.nameEn),
                  }}
                />
                <hr className="my-4" />
              </div>
              <div className="mt-4">
                <h2 className="text-2xl font-semibold leading-tight">Frans</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textToHTML(termsAndConditions.nameFr),
                  }}
                />
                <hr className="my-4" />
              </div>
              <div className="mt-4">
                <h2 className="text-2xl font-semibold leading-tight">Duits</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textToHTML(termsAndConditions.nameDe),
                  }}
                />
                <hr className="my-4" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
