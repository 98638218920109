import { endpoints, getRequest } from '../helpers';

// !=====================!
//   !PRODUCT VARIANTS!
// !=====================!

/**
 * Fetch all product variants
 */
export async function fetchAllProductVariants() {
  return await getRequest(endpoints.PRODUCTVARIANTS);
}

/**
 * Fetch product variants for a product
 * @param {string} id - Id of a variant. This is a UUID value.
 */
export async function fetchProductVariantForProduct(id) {
  return await getRequest(`${endpoints.PRODUCTVARIANTS}/${id}`);
}
