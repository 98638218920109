import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import Select from 'react-select';
import {
  fetchAllProductVariants,
  fetchSupplierProducts,
  fetchPurchases,
  postPurchase,
  putPurchase,
  deletePurchase,
  fetchProducts,
} from '../../../api';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import LabeledInputFieldText from '../../UIKit/Inputs/LabeledInputFieldText';
import TextLabel from '../../UIKit/Labels/TextLabel';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '80%',
    height: '450',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminPurchases() {
  const [purchases, setPurchases] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [inputProduct, setInputProduct] = useState({});
  const [inputSupplierProduct, setInputSupplierProduct] = useState({});
  const [inputDate, setInputDate] = useState('');
  const [inputPrice, setInputPrice] = useState('');
  const [inputPriceTransport, setInputPriceTransport] = useState('');
  const [inputSupplierInvoiceNumber, setInputSupplierInvoiceNumber] =
    useState('');

  const [availableProducts, setAvailableProducts] = useState([]);
  const [availableSupplierProducts, setAvailableSupplierProducts] = useState(
    []
  );

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  async function handleDeletePurchase(id) {
    const deleteJson = await deletePurchase(id);
    if (deleteJson.success === true) {
      const filteredPurchase = purchases.filter((purchase) => {
        return purchase.id !== id;
      });
      setPurchases(filteredPurchase);
      toastSuccess(translate('Purchase deleted'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  useEffect(() => {
    async function fetchPurchaseData() {
      const getJson = await fetchPurchases();
      setPurchases(getJson);
    }
    async function fetchSupplierProductData() {
      const getJson = await fetchSupplierProducts();
      const selectSupplierProducts = getJson.map((product) => {
        const label = `${product.productCodeSupplier} - ${product.productNameSupplier}`;
        return { value: product.id, label };
      });
      setAvailableSupplierProducts(selectSupplierProducts);
    }
    async function fetchProductVariantData() {
      const getJsonVariants = await fetchAllProductVariants();
      const getJsonProducts = await fetchProducts();
      const selectProducts = getJsonVariants.map((variant) => {
        const product = getJsonProducts.filter(
          (product) => product.id === variant.productId
        )[0];
        let label = '';
        if (product) {
          const articleNumber = product.articleNumber + variant.articleNumber;
          label = `${product.titleEn} - ${variant.fullName} (€ ${variant.price}) (${articleNumber})`;
        }
        return { value: variant.id, label };
      });
      setAvailableProducts(selectProducts);
    }
    fetchPurchaseData();
    fetchProductVariantData();
    fetchSupplierProductData();
  }, []);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const postJson = await postPurchase(
      JSON.stringify({
        productId: inputProduct.value,
        supplierProductId: inputSupplierProduct.value,
        date: inputDate,
        price: inputPrice,
        priceTransport: inputPriceTransport,
        supplierInvoiceNumber: inputSupplierInvoiceNumber,
      })
    );
    if (postJson.success === true) {
      setPurchases((previous) => [...previous, postJson.data]);
      toastSuccess(translate('Purchase created'));
      closeModal();
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  function resetInputFields() {
    setInputProduct({});
    setInputSupplierProduct({});
    setInputDate('');
    setInputPrice('');
    setInputPriceTransport('');
    setInputSupplierInvoiceNumber('');
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Aankoop toevoegen</h2>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-col space-y-2 -mx-2">
              <div>
                <TextLabel name="Product" label="Product" />
                <Select
                  value={inputProduct}
                  onChange={(selectedOption) => setInputProduct(selectedOption)}
                  options={availableProducts}
                  isClearable
                  isSearchable
                />
              </div>
              <div>
                <TextLabel name="SupplierProduct" label="Supplier product" />
                <Select
                  value={inputSupplierProduct}
                  onChange={(selectedOption) =>
                    setInputSupplierProduct(selectedOption)
                  }
                  options={availableSupplierProducts}
                  isClearable
                  isSearchable
                />
              </div>
              <LabeledInputFieldText
                type="date"
                label="Purchase date"
                value={inputDate}
                grouped={true}
                onChange={(e) => setInputDate(e.target.value)}
              />
              <div className="flex flex-row space-x-2">
                <LabeledInputFieldText
                  type="number"
                  label="Price"
                  value={inputPrice}
                  grouped={true}
                  onChange={(e) => setInputPrice(e.target.value)}
                />
                <LabeledInputFieldText
                  type="number"
                  label="Price transport"
                  value={inputPriceTransport}
                  grouped={true}
                  onChange={(e) => setInputPriceTransport(e.target.value)}
                />
              </div>
              <LabeledInputFieldText
                type="text"
                label="Supplier invoice number"
                value={inputSupplierInvoiceNumber}
                grouped={true}
                onChange={(e) => setInputSupplierInvoiceNumber(e.target.value)}
              />
            </div>

            <div className="w-full mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                Annuleren
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                Opslaan
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_purchases')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Factuurnummer
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Aankoopprijs
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {purchases.length > 0 &&
                purchases.map((purchase) => {
                  return (
                    <tr key={purchase.id}>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {purchase.supplierInvoiceNumber}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap hover:underline">
                          € {purchase.price}
                        </p>
                      </td>
                      <td className="py-3 px-5 bg-white border-b border-gray-200 text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleDeletePurchase(purchase.id)}
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
