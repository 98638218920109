import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import { fetchPhotos, putGeneralPhoto, deletePhoto } from '../../../api';
import ImageSelectorWithWatermark from '../../UIKit/ImageSelector';

export default function AdminGeneralImages() {
  const [images, setImages] = useState([]);
  const [file, setFile] = useState({ watermarkPosition: { x: 0, y: 0 } });

  useEffect(() => {
    fetchGeneralPhotos();
  }, []);

  async function fetchGeneralPhotos() {
    const getJson = await fetchPhotos();
    setImages(getJson.files);
  }

  async function handleDeleteImage(name) {
    const deleteJson = await deletePhoto(JSON.stringify({ name }));
    if (deleteJson.success === true) {
      await fetchGeneralPhotos();
      toastSuccess(translate('General image deleted'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  async function handleUploadImage() {
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('file_watermark', JSON.stringify(file.watermarkPosition));
    const putPhotoJson = await putGeneralPhoto(formData);
    if (putPhotoJson.success === true) {
      setFile({});
      await fetchGeneralPhotos();
      toastSuccess(translate('General image uploaded'));
    } else {
      toastError(putPhotoJson.error);
      console.error(putPhotoJson);
    }
  }

  function handleFileChange(e) {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setFile({
      ...file,
      file: e.target.files[0],
      blob: objectUrl,
      watermarkPosition: { x: 0, y: 0 },
    });
  }

  function handlePositionChange(index) {
    let pos = { x: 0, y: 0 };
    switch (index) {
      case 1:
        pos = { x: 1, y: 0 };
        break;
      case 2:
        pos = { x: 2, y: 0 };
        break;
      case 3:
        pos = { x: 0, y: 1 };
        break;
      case 4:
        pos = { x: 1, y: 1 };
        break;
      case 5:
        pos = { x: 2, y: 1 };
        break;
      case 6:
        pos = { x: 0, y: 2 };
        break;
      case 7:
        pos = { x: 1, y: 2 };
        break;
      case 8:
        pos = { x: 2, y: 2 };
        break;
      default:
        break;
    }
    setFile({ ...file, watermarkPosition: pos });
  }

  return (
    <div>
      <h2 className="text-2xl font-semibold leading-tight">
        General Product Images
      </h2>

      <section className="mt-5">
        <ImageSelectorWithWatermark
          title="New image"
          name="file"
          file={file.file}
          blob={file.blob}
          changed={false}
          deletable={true}
          handleFileChange={handleFileChange}
          handlePositionChange={handlePositionChange}
        />
        {file.file !== undefined && (
          <div className="flex flex-col my-2">
            <span className="text-xs text-gray-700">
              <b>note:</b> the name of the file will also be the image name
            </span>
            <button
              className="bg-green-500 text-white px-2 py-1 rounded w-24"
              onClick={() => handleUploadImage()}
            >
              Upload
            </button>
          </div>
        )}
      </section>

      <section className="mt-12">
        <h3 className="font-bold text-xl">Available Images</h3>
        <div className="flex flex-wrap justify-start items-start">
          {images.map((image) => {
            return (
              <div className="flex justify-center items-center relative m-2">
                <img
                  className="w-48 h-48 object-cover overflow-hidden"
                  loading="lazy"
                  src={'/uploads/products/general/photos/' + image}
                  alt={image}
                  key={image}
                />
                <button
                  className="w-8 h-8 rounded-full bg-white border-red-700 border-2 absolute bottom-0 right-0 text-sm text-red-700"
                  onClick={() => handleDeleteImage(image)}
                >
                  x
                </button>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}
