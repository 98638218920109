import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import {
  fetchSuppliers,
  postSupplier,
  deleteSupplier,
} from '../../../api';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import LabeledInputFieldText from '../../UIKit/Inputs/LabeledInputFieldText';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '450px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminSuppliers() {
  const [suppliers, setSuppliers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [inputName, setInputName] = useState('');
  const [inputLink, setInputLink] = useState('');
  const [inputContactName, setInputContactName] = useState('');
  const [inputEmail, setInputEmail] = useState('');

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  async function handleDeleteSupplier(id) {
    const deleteJson = await deleteSupplier(id);
    if (deleteJson.success === true) {
      const filteredSuppliers = suppliers.filter((supplier) => {
        return supplier.id !== id;
      });
      setSuppliers(filteredSuppliers);
      toastSuccess(translate('Supplier deleted'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchSuppliers();
      setSuppliers(getJson);
    }
    fetchData();
  }, []);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const postJson = await postSupplier(
      JSON.stringify({
        name: inputName.trim(),
        contactName: inputContactName.trim(),
        link: inputLink.trim(),
        email: inputEmail.trim(),
      })
    );
    if (postJson.success === true) {
      setSuppliers((previous) => [...previous, postJson.data]);
      toastSuccess(translate('Supplier created'));
      closeModal();
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  function resetInputFields() {
    setInputName('');
    setInputContactName('');
    setInputLink('');
  }

  return (
    <div>
      {/* http://reactcommunity.org/react-modal/ */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Leverancier toevoegen</h2>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <LabeledInputFieldText
                type="text"
                placeholder="Name"
                value={inputName}
                required={true}
                grouped={true}
                onChange={(e) => setInputName(e.target.value)}
              />
              <LabeledInputFieldText
                type="text"
                placeholder="Contact Name"
                value={inputContactName}
                required={true}
                grouped={true}
                onChange={(e) => setInputContactName(e.target.value)}
              />
              <LabeledInputFieldText
                type="url"
                placeholder="Link"
                value={inputLink}
                required={false}
                grouped={true}
                onChange={(e) => setInputLink(e.target.value)}
              />
              <LabeledInputFieldText
                type="email"
                placeholder="Email"
                value={inputEmail}
                required={false}
                grouped={true}
                onChange={(e) => setInputEmail(e.target.value)}
              />
            </div>

            <div className="w-full mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                Annuleren
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                Opslaan
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_supplier')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Naam
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Link
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {suppliers.length > 0 &&
                suppliers.map((supplier) => {
                  return (
                    <tr key={supplier.id}>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {supplier.name}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap hover:underline">
                          <a
                            href={'https://' + supplier.link}
                            rel="noopener noreferrer"
                          >
                            {supplier.link}
                          </a>
                        </p>
                      </td>
                      <td className="py-3 px-5 bg-white border-b border-gray-200 text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleDeleteSupplier(supplier.id)}
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
