import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { fetchPhotos } from '../../../../api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '70%',
    height: '70%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function GeneralImagePickerModal({
  modalIsOpen,
  onRequestClose,
  fileSelectorName,
  onImageClick,
}) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchPhotos();
      setImages(getJson.files);
    }
    fetchData();
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <h2 className="text-2xl font-semibold leading-tight">
        General Product Images
      </h2>

      <div className="mt-5 flex flex-wrap justify-start items-start">
        {images.map((image) => {
          return (
            <img
              className="w-48 h-48 m-2 object-cover overflow-hidden cursor-pointer"
              loading="lazy"
              src={'/uploads/products/general/photos/' + image}
              alt={image}
              key={image}
              onClick={() => onImageClick(image, fileSelectorName)}
            />
          );
        })}
      </div>
    </Modal>
  );
}
