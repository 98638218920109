import { endpoints, getRequest, putRequest } from '../helpers';

// !===================!
//   !GENERAL IMAGES!
// !===================!

/**
 * Fetch all general image items as a list of image names
 */
export async function fetchPhotos() {
  return await getRequest(endpoints.GENERALIMAGES);
}

/**
 * Puts a new general photo
 * @param {FormData} formData - FormData containing an image
 */
export async function putGeneralPhoto(formData) {
  return await putRequest(endpoints.GENERALIMAGES, formData, false);
}

/**
 * Deletes a general photo based on its name. Reason for a PUT: you cannot provide
 * a body to a DELETE request. Image names can get too long for the request url to handle.
 * @param {json} body - Stringified JSON data containing name of the image
 */
export async function deletePhoto(body) {
  return await putRequest(`${endpoints.GENERALIMAGES}/delete`, body);
}
