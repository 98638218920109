import React from 'react';

/**
 * A Button component that also returns an onClick action
 * @param {string} text - Text displayed in the button
 * @param {string} type - Type of button. Default: `button`
 * @param {any} handleClick - Action when clicked on the button
 */
export default function ButtonActionSignout({
  text,
  type = 'button',
  handleClick,
}) {
  return (
    <button
      type={type}
      className={
        // reason for border: when having the button next an outlined button, this button otherwise
        // looks smaller than the outlined one
        'w-full text-left bg-red-600 border-2 border-red-600 hover:bg-red-800 hover:border-red-800 text-white font-bold py-2 px-4 rounded-lg'
      }
      onClick={handleClick}
    >
      {text}
    </button>
  );
}
