import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import { FilterContext } from '../../../context/FilterContext';
import { BasketContext } from '../../../context/BasketContext';
import ShareButtons from './ProductShareButtons';
import translate from '../../../i18n/translate';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import { discountAmount, textToHTML } from '../../../utils';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import TextLoading from '../../UIKit/Labels/TextLoading';
import TextHead from '../../UIKit/Labels/TextHead';
import TextHeadProduct from '../../UIKit/Labels/TextHeadProduct';
import LabeledInputFieldText from '../../UIKit/Inputs/LabeledInputFieldText';
import LabeledInputAreaText from '../../UIKit/Inputs/LabeledInputAreaText';
import ButtonActionPrimary from '../../UIKit/Buttons/ButtonActionPrimary';
import ButtonActionSecondary from '../../UIKit/Buttons/ButtonActionSecondary';
import Product from './Product';
import uuid from 'uuid'; // used for random basketId generator
import {
  fetchProductDetails,
  postContact,
  postMyBasketItem,
} from '../../../api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '70%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function ProductDetails(props) {
  const [user] = useContext(UserContext);
  const [filter] = useContext(FilterContext);
  const [basket, setBasket] = useContext(BasketContext);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [slug, setSlug] = useState('');
  const [product, setProduct] = useState({});
  const [variants, setVariants] = useState([]);
  const [extraInfos, setExtraInfos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [listImages, setListImages] = useState([]);
  const [youMightAlsoLike, setYouMightAlsoLike] = useState([]);
  const [youMightAlsoNeed, setYouMightAlsoNeed] = useState([]);

  const intl = useIntl();

  const [translatedDescription, setTranslatedDescription] = useState('');
  const [translatedTitle, setTranslatedTitle] = useState('');
  const [translatedCategory, setTranslatedCategory] = useState('');
  const [translatedSubcategory, setTranslatedSubcategory] = useState('');
  const [translatedExtraInfo, setTranslatedExtraInfo] = useState([]);

  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');

  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [carNames, setCarNames] = useState([]);

  const [combinedVariants, setCombinedVariants] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [customerPriceInclVAT, setCustomerPriceInclVAT] = useState(
    parseFloat(0).toFixed(2)
  );
  const [resellerPriceInclVAT, setResellerPriceInclVAT] = useState(
    parseFloat(0).toFixed(2)
  );
  const [resellerPriceExclVAT, setResellerPriceExclVAT] = useState(
    parseFloat(0).toFixed(2)
  );
  const [margeExclVAT, setMargeExclVAT] = useState(parseFloat(0).toFixed(2));

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setModalIsOpen(false);
  }

  useEffect(() => {
    setSlug(props.match.params.slug);
    setSelectedLanguage(props.selectedLocale);
  }, [props]);

  useEffect(() => {
    async function fetchData() {
      if (product.id !== undefined) {
        // product already fetched. Skip fetching...
        return;
      }
      const getJson = await fetchProductDetails(slug);
      if (getJson.success === false) {
        console.error(
          'could not find product. Either the url is wrong, or the product has been removed. Redirecting to the shop page...'
        );
        props.history.push('/shop');
      } else {
        setCategory(getJson.category);
        setSubcategory(getJson.subcategory);
        setCarNames(getJson.carNames);
        setProduct(getJson.data);
        setVariants(getJson.variants);
        setYouMightAlsoLike(getJson.youMightAlsoLike);
        setYouMightAlsoNeed(getJson.youMightAlsoNeed);
        setExtraInfos(getJson.extraInfos);
      }
    }
    if (slug.length > 0) {
      fetchData();
    }
  }, [slug]);

  useEffect(() => {
    if (selectedLanguage.length === 0) {
      // language not read yet
      return;
    }
    if (product.id) {
      let extraInf = [];
      switch (selectedLanguage) {
        case 'en-US':
          setTranslatedDescription(product.descriptionEn);
          setTranslatedTitle(product.titleEn);
          setTranslatedCategory(category.nameEn);
          if (subcategory) {
            setTranslatedSubcategory(subcategory.nameEn);
          }
          extraInf = extraInfos.map((info) => info.nameEn);
          break;
        case 'de-DE':
          setTranslatedDescription(product.descriptionDe);
          setTranslatedTitle(product.titleDe);
          setTranslatedCategory(category.nameDe);
          if (subcategory) {
            setTranslatedSubcategory(subcategory.nameDe);
          }
          extraInf = extraInfos.map((info) => info.nameDe);
          break;
        case 'fr-BE':
          setTranslatedDescription(product.descriptionFr);
          setTranslatedTitle(product.titleFr);
          setTranslatedCategory(category.nameFr);
          if (subcategory) {
            setTranslatedSubcategory(subcategory.nameFr);
          }
          extraInf = extraInfos.map((info) => info.nameFr);
          break;
        default:
          setTranslatedDescription(product.descriptionNl);
          setTranslatedTitle(product.titleNl);
          setTranslatedCategory(category.nameNl);
          if (subcategory) {
            setTranslatedSubcategory(subcategory.nameNl);
          }
          extraInf = extraInfos.map((info) => info.nameNl);
          break;
      }
      setTranslatedExtraInfo(extraInf);
    }
  }, [product, category, subcategory, selectedLanguage, extraInfos]);

  useEffect(() => {
    setSelectedVariants([]);
    setTotalPrice(0.0); // reset total price as all previous selected variants are gone
  }, [selectedLanguage]);

  useEffect(() => {
    if (product.id !== undefined) {
      if (product.photo) {
        setImgSrc(product.photo);
      }
      setTotalPrice(
        product.priceDiscount ? product.priceDiscount : product.price
      );
    }
  }, [product]);

  useEffect(() => {
    let images = [];
    if (product.id !== undefined) {
      if (product.photo) {
        images.push(product.photo);
      }
      if (product.photo1) {
        images.push(product.photo1);
      }
      if (product.photo2) {
        images.push(product.photo2);
      }
      if (product.photo3) {
        images.push(product.photo3);
      }
      if (product.photo4) {
        images.push(product.photo4);
      }
    }
    setListImages(images);
  }, [product]);

  function translatedString(object, language) {
    if (language) {
      switch (language) {
        case 'en-US':
          return object.nameEn;
        case 'de-DE':
          return object.nameDe;
        case 'fr-BE':
          return object.nameFr;
        default:
          return object.nameNl;
      }
    }
  }

  useEffect(() => {
    if (variants.length > 0) {
      const mappedVariants = variants.map((variant) => {
        const items = [];
        for (let i = 0; i < variant.headers.length; i++) {
          const header = variant.headers[i];
          const item = variant.items[i];
          const customerPrice = variant.buyPrice;
          const resellerPrice = variant.resellerPrice;
          items.push({
            header: translatedString(header, selectedLanguage),
            item: translatedString(item, selectedLanguage),
            defaultPrice: item.price,
            customerPrice,
            resellerPrice,
          });
        }
        return items;
      });
      let combinedItems = [];
      mappedVariants.forEach((variant) => {
        variant.forEach((variantItem, index) => {
          if (combinedItems[index]) {
            const foundItem = combinedItems[index].filter(
              (item) => item.name === variantItem.item
            )[0];
            if (foundItem === undefined) {
              combinedItems[index].push({
                name: variantItem.item,
                resellerPrice: variantItem.resellerPrice,
                customerPrice: variantItem.customerPrice,
                defaultPrice: variantItem.defaultPrice,
              });
            }
          } else {
            combinedItems.push([
              {
                name: variantItem.item,
                resellerPrice: variantItem.resellerPrice,
                customerPrice: variantItem.customerPrice,
                defaultPrice: variantItem.defaultPrice,
              },
            ]);
          }
        });
      });

      const firstVariant = mappedVariants.shift(); // firstVariant contains the needed (already ordered) header values
      combinedItems = combinedItems.map((items, index) => {
        return { header: firstVariant[index].header, items };
      });
      setCombinedVariants(combinedItems);
    }
  }, [variants, selectedLanguage]);

  useEffect(() => {
    setContactName(`${user.firstName} ${user.lastName}`);
    setContactEmail(user.email);
  }, [user]);

  function handlePhotoClick(photo) {
    setImgSrc(photo);
  }

  async function handleBasketClick() {
    if (combinedVariants.length !== selectedVariants.length) {
      toastError(translate('details_notAllVariantsSelected'));
      return;
    }
    const mappedVariants = variants.map((variant) => {
      const objects = [];
      variant.headers.forEach((header, index) => {
        const item = variant.items[index];
        objects.push({ header, item });
      });
      return { id: variant.id, objects };
    });
    let filteredVariants = mappedVariants;
    selectedVariants.forEach((variant, index) => {
      const header = variant.header;
      const item = variant.item.name;
      filteredVariants = filteredVariants.filter((mVariant) => {
        const object = mVariant.objects[index];
        const mHeader = translatedString(object.header, selectedLanguage);
        const mItem = translatedString(object.item, selectedLanguage);
        return header === mHeader && item === mItem;
      });
    });
    const productVariant = filteredVariants[0];
    const productVariantId = productVariant ? productVariant.id : undefined;

    if (user.id !== undefined) {
      // logged in
      let localBasket = [];
      const postJson = await postMyBasketItem(
        JSON.stringify({
          productId: product.id,
          carId: filter.carId,
          productVariantId,
        })
      );
      if (postJson.success === true) {
        if (basket) {
          localBasket = [...basket, postJson.data];
        } else {
          localBasket = [postJson.data];
        }
        setBasket(localBasket);
        toastSuccess(translate('toast_basketUpdatedSuccess'));
      } else {
        toastError(translate('toast_basketUpdatedFailed'));
        console.error(postJson);
      }
    } else {
      // user is not logged in. Store in localStorage
      const localStorageBasket = JSON.parse(localStorage.getItem('basket'));
      let newBasket = [
        {
          basketId: uuid.v4(),
          productId: product.id,
          productVariantId,
          count: 1,
          carId: filter.carId,
        },
      ];
      if (productVariant !== undefined) {
        newBasket.productVariantId = productVariant.id;
      }
      if (localStorageBasket) {
        newBasket = [...newBasket, ...localStorageBasket];
      }
      localStorage.setItem('basket', JSON.stringify(newBasket));
      setBasket(newBasket);
      toastSuccess(translate('toast_basketUpdatedSuccess'));
    }
  }

  async function onSubmitPressed() {
    const message = `
    Dit bericht is verzonden vanuit de CMA webshop via een product detailpagina.
    Vraag over product: ${product.titleNl} (${window.location.href})
    Artikelnummer: ${product.articleNumber}

    ----------------------
    Boodschap gebruiker:
    ----------------------
    ${contactMessage}
    `;

    const postJson = await postContact(
      JSON.stringify({ contactName, contactEmail, message })
    );
    if (postJson.success === true) {
      toastSuccess(translate('toast_mailSuccess'));
    } else {
      toastError(translate('toast_mailFailed'));
      console.error(postJson);
    }
  }

  function handleVariantChange(id, value) {
    const item = JSON.parse(value);
    const updatingSelectedVariants = selectedVariants;
    let variant = updatingSelectedVariants.filter((variant) => {
      return variant.header === id;
    })[0];
    if (variant !== undefined) {
      const index = updatingSelectedVariants.indexOf(variant);
      updatingSelectedVariants[index] = {
        header: variant.header,
        item,
      };
    } else {
      // variant not yet added to `selectedVariants`
      variant = combinedVariants.filter((variant) => variant.header === id)[0];
      updatingSelectedVariants.push({
        header: variant.header,
        item,
      });
    }
    setSelectedVariants(updatingSelectedVariants);

    if (product.id === undefined) {
      return; // no product found in the state
    }
    let price = product.priceDiscount
      ? parseFloat(product.priceDiscount)
      : parseFloat(product.price);
    let resellerPrice = price;
    updatingSelectedVariants.forEach((variant) => {
      price += parseFloat(variant.item.defaultPrice);
      resellerPrice = parseFloat(variant.item.resellerPrice);
    });
    if (user.role === 'reseller') {
      setCustomerPriceInclVAT(parseFloat(price).toFixed(2));
      setResellerPriceInclVAT(parseFloat(resellerPrice).toFixed(2));
      setResellerPriceExclVAT(parseFloat(resellerPrice * 0.79).toFixed(2)); // 0.79 because of 21% VAT
      setMargeExclVAT(parseFloat((price - resellerPrice) * 0.79).toFixed(2)); // 0.79 because of 21% VAT
    }
    setTotalPrice(price.toFixed(2));
  }

  if (product.id === undefined)
    return (
      <div className="m-12">
        <TextLoading />
      </div>
    );

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="p-2 mb-2">
          <TextHead text={translate('details_askQuestion')} center={true} />
        </div>

        <form className="w-full" onSubmit={onSubmitPressed}>
          <LabeledInputFieldText
            name="name"
            type="text"
            label={translate('contact_name')}
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            placeholder="Jane"
            required={true}
          />
          <LabeledInputFieldText
            name="email"
            type="email"
            label={translate('email')}
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            placeholder="jane@doe.com"
            required={true}
          />
          <LabeledInputAreaText
            name="message"
            label={translate('contact_message')}
            value={contactMessage}
            onChange={(e) => setContactMessage(e.target.value)}
            required={true}
          />
          <ButtonActionPrimary text={translate('send')} type="submit" />
        </form>
      </Modal>

      <div className="container lg:mt-12 md:mt-8 px-4 mt-5 mx-auto">
        <div className="text-sm text-gray-500 italic my-4">
          <span>{translatedCategory}</span>
          {translatedSubcategory.length > 0 && (
            <span> / {translatedSubcategory}</span>
          )}
        </div>

        <div className="flex md:flex-row flex-col">
          <div className="md:w-1/2 sm:mr-6 md:mr-12">
            <div className="flex lg:flex-row flex-col">
              {listImages.length > 1 && (
                <div className="flex lg:flex-col flex-row lg:mr-2 md:mt-6 space-x-1 lg:space-x-0 space-y-0 lg:space-y-1">
                  {listImages.map((image) => {
                    return (
                      <img
                        loading="lazy"
                        className="w-1/5 lg:w-20 h-20 cursor-pointer object-cover overflow-hidden"
                        src={'/uploads/products/photos/' + image}
                        alt="Product"
                        onClick={() => handlePhotoClick(image)}
                        key={image}
                      />
                    );
                  })}
                </div>
              )}
              <img
                loading="lazy"
                className="w-full overflow-auto object-contain lg:mt-6 mt-1"
                src={'/uploads/products/photos/' + imgSrc}
                alt="Product main image"
              />
            </div>
          </div>

          <div className="md:w-1/2">
            <TextHead text={translatedTitle} center={false} />
            {combinedVariants.length > 0 && (
              <div className="md:my-12 mt-4">
                {combinedVariants.map((variant) => {
                  return (
                    <div
                      className="flex flex-col space-y-1 md:mt-6 mt-3"
                      key={variant.header}
                    >
                      <div className="flex flex-col">
                        <span className="font-semibold">{variant.header}</span>
                        <div className="relative">
                          <select
                            className="w-full rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-indigo-500 text-base pl-3 pr-10"
                            onChange={(e) =>
                              handleVariantChange(e.target.id, e.target.value)
                            }
                            id={variant.header}
                          >
                            <option disabled selected value hidden>
                              {intl.formatMessage({
                                id: 'details_selectAnOption',
                              })}
                            </option>
                            {variant.items.map((item) => {
                              return (
                                <option
                                  value={JSON.stringify(item)}
                                  key={item.name + item.defaultPrice}
                                >
                                  {item.name} (+€{' '}
                                  {parseFloat(item.defaultPrice).toFixed(2)})
                                </option>
                              );
                            })}
                          </select>
                          <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                            <svg
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              className="w-4 h-4"
                              viewBox="0 0 24 24"
                            >
                              <path d="M6 9l6 6 6-6"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {user.role === 'reseller' && variants.length > 0 ? (
              <div className="mb-5 flex flex-col space-y-1">
                <span>
                  {translate('details_customerPriceWithVAT')}: €{' '}
                  {customerPriceInclVAT}
                </span>
                <span>
                  {translate('details_resellerPriceWithoutVAT')}: €{' '}
                  {resellerPriceExclVAT}
                </span>
                <span className="font-bold text-lg">
                  {translate('details_resellerPriceWithVAT')}: €{' '}
                  {resellerPriceInclVAT}
                </span>
                <span className="italic text-sm">
                  {translate('details_margeWithoutVAT')}: € {margeExclVAT}
                </span>
              </div>
            ) : (
              <div className="my-4 flex flex-row space-x-2">
                <div>
                  <span className="sm:text-xl text-base font-bold">
                    {translate('details_totalPrice')}:
                  </span>
                  <span className="sm:text-xl text-base pl-2">
                    € {parseFloat(totalPrice).toFixed(2)}
                  </span>
                </div>
                {product.priceDiscount && (
                  <span className="bg-cmagreen text-white text-sm font-bold text-center px-2 py-1 ml-2 rounded-full">
                    -{discountAmount(product.priceDiscount, product.price)} EUR
                    incl.
                  </span>
                )}
                {parseInt(product.weight) === 0 && (
                  <span className="bg-cmagreen text-white text-sm font-bold text-center px-2 py-1 ml-2 rounded-full">
                    {intl.formatMessage({ id: 'details_freeShipment' })}
                  </span>
                )}
              </div>
            )}

            <p className="mt-4">
              {translate('details_articleNr')} {product.articleNumber}
            </p>

            <div className="flex flex-wrap my-4">
              <div className="mt-1 mr-2">
                <ButtonActionPrimary
                  text={translate('inBasket')}
                  handleClick={handleBasketClick}
                />
              </div>
              {user.id !== undefined && (
                <div className="mt-1 mr-2">
                  <ButtonActionSecondary
                    text={translate('questionAboutProduct')}
                    handleClick={openModal}
                  />
                </div>
              )}
            </div>
            <ShareButtons />
          </div>
        </div>

        {translatedDescription && translatedDescription.length > 0 && (
          <p
            className="mt-6 mb-3"
            dangerouslySetInnerHTML={{
              __html: textToHTML(translatedDescription),
            }}
          />
        )}

        {extraInfos && extraInfos.length > 0 && (
          <section className="mt-3">
            {translatedExtraInfo.map((info) => {
              return (
                <p
                  className="my-2"
                  key="info"
                  dangerouslySetInnerHTML={{
                    __html: textToHTML(info),
                  }}
                />
              );
            })}
          </section>
        )}

        <hr className="my-6" />

        <div>
          {carNames && carNames.length > 0 && (
            <section className="mt-6">
              <TextHeadProduct text={translate('details_availableForCars')} />
              <ul className="list-disc mx-4">
                {carNames.map((carName) => {
                  return <li key={carName}>{carName}</li>;
                })}
              </ul>
            </section>
          )}
        </div>

        <div>
          {youMightAlsoNeed && youMightAlsoNeed.length > 0 && (
            <section className="w-full my-8">
              <TextHead text={translate('details_youMightAlsoNeed')} />
              <div className="flex flex-wrap">
                {youMightAlsoNeed.map((productNeed, index) => {
                  return (
                    <a
                      href={'/shop/' + productNeed.slug}
                      className="lg:w-1/4 md:w-1/2 w-full px-4 pb-4"
                      key={`${productNeed.id}_${index}`}
                    >
                      <Product
                        product={productNeed}
                        selectedLocale={selectedLanguage}
                      />
                    </a>
                  );
                })}
              </div>
            </section>
          )}

          {youMightAlsoLike && youMightAlsoLike.length > 0 && (
            <section className="w-full my-8">
              <TextHead text={translate('details_youMightAlsoLike')} />
              <div className="flex flex-wrap">
                {youMightAlsoLike.map((productLike, index) => {
                  return (
                    <a
                      href={'/shop/' + productLike.slug}
                      className="lg:w-1/4 md:w-1/2 w-full px-4 pb-4"
                      key={`${productLike.id}_${index}`}
                    >
                      <Product
                        product={productLike}
                        selectedLocale={selectedLanguage}
                      />
                    </a>
                  );
                })}
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
}
