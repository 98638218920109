import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { BasketContext } from '../../context/BasketContext';
import translate from '../../i18n/translate';
import { postLogin, postOverrideMyBasket, fetchMyBasket } from '../../api';
import { toastError } from '../UIKit/Toastify';
import ButtonActionPrimary from '../UIKit/Buttons/ButtonActionPrimary';
import ButtonRoutableClear from '../UIKit/Buttons/ButtonRoutableClear';
import LabeledInputFieldText from '../UIKit/Inputs/LabeledInputFieldText';

export default function Login(props) {
  const [user, setUser] = useContext(UserContext);
  const [, setBasket] = useContext(BasketContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    async function handleBasketData() {
      const localStorageBasket = JSON.parse(localStorage.getItem('basket'));
      if (localStorageBasket && localStorageBasket.length > 0) {
        await overrideBasketData(localStorageBasket);
      } else {
        const userBasket = await fetchMyBasket();
        setBasket(userBasket);
      }
    }

    async function overrideBasketData(localStorageBasket) {
      const putJson = await postOverrideMyBasket(
        JSON.stringify({ basket: localStorageBasket })
      );
      if (putJson.success === true) {
        setBasket(localStorageBasket);
        localStorage.removeItem('basket');
      } else {
        toastError(translate('toast_basketUpdateFailed'));
        console.error(putJson);
      }
    }

    if (user.id === undefined) {
      return;
    }
    handleBasketData();

    if (user.role === 'admin') {
      props.history.push('/admin');
    } else if (user.role === 'user' || user.role === 'reseller') {
      props.history.push('/shop');
    }
  }, [user, props]);

  async function handleSignInClick(e) {
    e.preventDefault();
    let postJson = await postLogin(JSON.stringify({ email, password }));
    if (postJson.success === true) {
      let resUser = postJson.user;
      setUser(resUser);
      props.history.push('/shop');
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  return (
    <div className="container mx-auto max-w-sm py-8">
      <div className="bg-white rounded shadow">
        <div className="border-b py-8 font-bold text-black text-center text-xl tracking-widest uppercase">
          {translate('login_title')}
        </div>

        <form
          className="sm:mx-10 mx-4 my-8"
          onSubmit={(e) => handleSignInClick(e)}
        >
          <LabeledInputFieldText
            name="email"
            type="email"
            label={translate('email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="jane@doe.com"
            required={true}
          />
          <LabeledInputFieldText
            name="password"
            type="password"
            label={translate('password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="******************"
            required={true}
          />

          <div className="flex justify-between items-center my-6">
            <ButtonActionPrimary text={translate('signIn')} type="submit" />
            <ButtonRoutableClear
              to="/sign-up"
              text={translate('login_dontHaveAnAccount')}
              right={true}
            />
          </div>
        </form>

        <div className="border-t py-4">
          <div className="mx-8">
            <ButtonRoutableClear
              to="/forgot-password"
              text={translate('login_forgotPassword')}
              right={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
