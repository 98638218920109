import en from './en-US';
import de from './de-DE';
import fr from './fr-BE';
import nl from './nl-BE';

export default {
  ...en,
  ...de,
  ...fr,
  ...nl,
};
