import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import {
  fetchMyAddress,
  postAddress,
  putDefaultAddress,
  deleteAddress,
} from '../../../api';
import Select from 'react-select';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import LabeledInputFieldText from '../../UIKit/Inputs/LabeledInputFieldText';
import LabeledInputFieldNumber from '../../UIKit/Inputs/LabeledInputFieldNumber';
import TextHead from '../../UIKit/Labels/TextHead';
import TextLabel from '../../UIKit/Labels/TextLabel';
import ButtonActionPrimary from '../../UIKit/Buttons/ButtonActionPrimary';
import ButtonActionOutlinePrimary from '../../UIKit/Buttons/ButtonActionOutlinePrimary';
import ButtonActionOutlineSecondary from '../../UIKit/Buttons/ButtonActionOutlineSecondary';
import ButtonActionOutlineDanger from '../../UIKit/Buttons/ButtonActionOutlineDanger';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '80%',
    height: '575px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function UserContactAddress({ selectedAddressId, onAddressSelectChange, onAddressListChange }) {
  const [user, setUser] = useContext(UserContext);
  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState('');
  const [currentSelectedAddress, setCurrentSelectedAddress] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [box, setBox] = useState('');
  const [postal, setPostal] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [availableCountries, setAvailableCountries] = useState([]);

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  useEffect(() => {
    setCurrentSelectedAddress(selectedAddressId)
  }, [selectedAddressId])

  useEffect(() => {
    setAvailableCountries([
      { value: 'Belgium', label: 'Belgium' },
      { value: 'Netherlands', label: 'Netherlands' },
      { value: 'Germany', label: 'Germany' },
      { value: 'France', label: 'France' },
      { value: 'Other', label: 'Other' },
    ]);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const addresses = await fetchMyAddress();
      setAddresses(addresses);
    }
    if (user.id) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (user.defaultAddressId && addresses.length > 0) {
      const address = addresses.filter(
        (address) => address.id === user.defaultAddressId
      )[0];
      if (address) {
        setDefaultAddress(address.id);
        if (currentSelectedAddress && currentSelectedAddress.length === 0) {
          setCurrentSelectedAddress(address.id);
          return;
        }
      } else {
        setDefaultAddress('');
        setCurrentSelectedAddress('');
      }
    } else if (addresses.length > 0) {
      setCurrentSelectedAddress(addresses[0].id);
    }
  }, [user, addresses, currentSelectedAddress]);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const body = JSON.stringify({
      firstName,
      lastName,
      street,
      number,
      box,
      postal,
      city,
      country: country.value,
    });
    const postJson = await postAddress(body);
    if (postJson.success === true) {
      handleNewAddress(postJson.data);
      toastSuccess(translate('toast_addressAddSuccess'));
      closeModal();
    } else {
      toastError(translate('toast_addressAddFailed'));
      console.error(postJson);
    }
  }

  function handleSelectedAddressChange(id) {
    const address = addresses.filter((address) => address.id === id)[0];
    if (address) {
      setCurrentSelectedAddress(address.id);
    } else {
      setCurrentSelectedAddress('');
    }
    if (onAddressSelectChange && address) {
      onAddressSelectChange(address.id);
    }
  }

  async function handleMakeDefaultAddress(id) {
    const putJson = await putDefaultAddress(
      user.id,
      JSON.stringify({ defaultAddressId: id })
    );
    if (putJson.success === true) {
      user.defaultAddressId = id;
      setUser(user);
      setDefaultAddress(id);
      toastSuccess(translate('toast_defaultAddressSuccess'));
    } else {
      toastError(putJson.error);
      console.error(putJson);
    }
  }

  function handleNewAddress(data) {
    addresses.push(data);
    if (onAddressListChange) {
      onAddressListChange(addresses);
    }
    handleSelectedAddressChange(data.id);
  }

  async function handleDeleteAddress(id) {
    const deleteJson = await deleteAddress(id);
    if (deleteJson.success === true) {
      const filteredAddresses = addresses.filter((address) => {
        return address.id !== id;
      });
      if (currentSelectedAddress === id) {
        // when deleting current active address, move to default address
        handleSelectedAddressChange(defaultAddress);
      }
      setAddresses(filteredAddresses);
      if (onAddressListChange) {
        onAddressListChange(filteredAddresses);
      }
      toastSuccess(translate('toast_addressDeleteSuccess'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  function resetInputFields() {
    setFirstName('');
    setLastName('');
    setStreet('');
    setNumber('');
    setBox('');
    setPostal('');
    setCity('');
    setCountry('');
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <TextHead text={translate('profile_createAddress')} center={true} />

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="flex sm:flex-row flex-col w-full">
              <div className="sm:w-1/2 sm:pr-1">
                <LabeledInputFieldText
                  name="firstname"
                  type="text"
                  label={translate('firstName')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Jane"
                  required={true}
                />
              </div>
              <div className="sm:w-1/2 sm:pl-1">
                <LabeledInputFieldText
                  name="lastname"
                  type="text"
                  label={translate('lastName')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Doe"
                  required={true}
                />
              </div>
            </div>

            <div className="flex sm:flex-row flex-col w-full">
              <div className="sm:w-3/5">
                <LabeledInputFieldText
                  name="street"
                  type="text"
                  label={translate('street')}
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  required={true}
                />
              </div>
              <div className="sm:w-1/5 sm:px-2">
                <LabeledInputFieldNumber
                  name="street-number"
                  label={translate('streetNumber')}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  required={true}
                />
              </div>
              <div className="sm:w-1/5">
                <LabeledInputFieldText
                  name="box"
                  type="text"
                  label={translate('streetBox')}
                  value={box}
                  onChange={(e) => setBox(e.target.value)}
                  required={false}
                />
              </div>
            </div>

            <div className="flex w-full">
              <div className="w-1/3 pr-1">
                <LabeledInputFieldText
                  name="postal"
                  type="text"
                  label={translate('postal')}
                  value={postal}
                  onChange={(e) => setPostal(e.target.value)}
                  required={true}
                />
              </div>
              <div className="w-2/3 pl-1">
                <LabeledInputFieldText
                  name="city"
                  type="text"
                  label={translate('city')}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required={true}
                />
              </div>
            </div>
            <div className="w-full py-3">
              <TextLabel name="country" label={translate('country')} />
              <Select
                className="w-full"
                placeholder={translate('country')}
                value={country}
                onChange={(selectedOption) => setCountry(selectedOption)}
                options={availableCountries}
                isSearchable
              />
            </div>
            <div className="w-full flex my-4">
              <ButtonActionOutlineSecondary
                text={translate('cancel')}
                handleClick={() => closeModal(false)}
              />
              <div className="mx-2">
                <ButtonActionPrimary text={translate('save')} type="submit" />
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <div className="w-full sm:px-6 py-4">
        <TextHead text={translate('profile_addresses')} />
        {addresses.length === 0 && (
          <p className="my-6">{translate('profile_noAddressAvailable')}</p>
        )}
        {addresses.length > 0 && (
          <>
            <table className="w-full">
              <tbody>
                {addresses.map((address) => {
                  return (
                    <tr
                      key={address.id}
                      className="border-b border-gray-300 border-solid"
                    >
                      <td className="flex flex-row">
                        <input
                          type="radio"
                          name="radio-address"
                          id="radio-address"
                          checked={address.id === currentSelectedAddress}
                          className="mr-6 cursor-pointer"
                          value={address.id}
                          onChange={(e) =>
                            handleSelectedAddressChange(e.target.value)
                          }
                        />
                        <ul
                          className="my-2 flex-grow cursor-pointer"
                          onClick={() =>
                            handleSelectedAddressChange(address.id)
                          }
                        >
                          <li>
                            {address.firstName} {address.lastName}
                          </li>
                          <li>
                            {address.street} {address.number} {address.box}
                          </li>
                          <li>
                            {address.postal} {address.city}
                          </li>
                          <li>{address.country}</li>
                        </ul>
                        <div className="flex flex-row justify-end items-center">
                          {address.id === currentSelectedAddress &&
                            address.id !== defaultAddress && (
                              <div className="space-x-2">
                                <ButtonActionOutlineSecondary
                                  text={translate('makeDefault')}
                                  handleClick={() =>
                                    handleMakeDefaultAddress(address.id)
                                  }
                                />
                                <ButtonActionOutlineDanger
                                  text={translate('delete')}
                                  handleClick={() =>
                                    handleDeleteAddress(address.id)
                                  }
                                />
                              </div>
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        <div className="mt-4">
          <ButtonActionOutlinePrimary
            text={translate('new')}
            handleClick={openModal}
          />
        </div>
      </div>
    </>
  );
}
