import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import { fetchSocials, postSocial, deleteSocial } from '../../../api';
import Select from 'react-select';
import { toastSuccess, toastError } from '../../UIKit/Toastify';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '340px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminSocials() {
  const [socials, setSocials] = useState([]);
  const [unassignedSocials, setUnassignedSocials] = useState([]);
  const [selectedSocial, setSelectedSocial] = useState('');
  const [newUrl, setNewUrl] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  async function handleDeleteSocial(id) {
    const deleteJson = await deleteSocial(id);
    if (deleteJson.success === true) {
      const filteredSocials = socials.filter((social) => {
        return social.id !== id;
      });
      setSocials(filteredSocials);
      toastSuccess(translate('toast_adminSocialDeleteSuccess'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchSocials();
      setSocials(getJson);
    }
    fetchData();
  }, []);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const postJson = await postSocial(
      JSON.stringify({
        name: selectedSocial.value,
        url: newUrl.trim(),
      })
    );
    if (postJson.success === true) {
      setSocials((previous) => [...previous, postJson.data]);
      toastSuccess(translate('toast_adminSocialAddSuccess'));
      closeModal();
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  useEffect(() => {
    updateUnassignedSocials();
  }, [socials]);

  function updateUnassignedSocials() {
    let listSocials = ['facebook', 'instagram', 'flickr', 'linkedin'];
    const assignedSocials = socials.map((social) => social.name);
    assignedSocials.forEach((social) => {
      const index = listSocials.indexOf(social);
      if (index !== -1) {
        // element index found
        listSocials.splice(index, 1);
      }
    });
    const selectSocials = listSocials.map((social) => {
      return { value: social, label: social };
    });
    setUnassignedSocials(selectSocials);
  }

  function resetInputFields() {
    setSelectedSocial('');
    setNewUrl('');
  }

  return (
    <div>
      {/* http://reactcommunity.org/react-modal/ */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Social toevoegen</h2>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <Select
                  options={unassignedSocials}
                  value={selectedSocial}
                  onChange={(selectedOption) =>
                    setSelectedSocial(selectedOption)
                  }
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="social-url"
                >
                  Url
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="social-url"
                  id="social-url"
                  type="text"
                  value={newUrl}
                  onChange={(e) => setNewUrl(e.target.value)}
                  required
                />
                <label
                  className="block text-gray-700 text-xs mb-2"
                  htmlFor="social-url"
                >
                  <b>Must</b> start with "http://" or "https://"
                </label>
              </div>
            </div>

            <div className="w-full mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                Annuleren
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                Opslaan
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_social')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Naam
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Url
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {socials.length > 0 &&
                socials.map((social) => {
                  return (
                    <tr key={social.id}>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {social.name}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap hover:underline">
                          <a href={social.url} rel="noopener noreferrer">
                            {social.url}
                          </a>
                        </p>
                      </td>
                      <td className="py-3 px-5 bg-white border-b border-gray-200 text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleDeleteSocial(social.id)}
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
