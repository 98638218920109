import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import Modal from 'react-modal';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import {
  fetchSubcategories,
  postSubcategory,
  putSubcategory,
  deleteSubcategory,
} from '../../../api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '620px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminSubCategories() {
  const [subcategories, setSubcategories] = useState([]);
  const [newSubCategoryNL, setNewSubCategoryNL] = useState('');
  const [newSubCategoryEN, setNewSubCategoryEN] = useState('');
  const [newSubCategoryDE, setNewSubCategoryDE] = useState('');
  const [newSubCategoryFR, setNewSubCategoryFR] = useState('');
  const [newPrefix, setNewPrefix] = useState('');

  const [editingSubcategory, setEditingSubcategory] = useState({});

  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchSubcategories();
      setSubcategories(getJson);
    }
    fetchData();
  }, []);

  async function handleDeleteSubcategory(id) {
    const deleteJson = await deleteSubcategory(id);
    if (deleteJson.success === true) {
      const filteredSubcats = subcategories.filter((subcat) => {
        return subcat.id !== id;
      });
      setSubcategories(filteredSubcats);
      toastSuccess(translate('toast_adminSubcategoryDeleteSuccess'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  async function onSubmitPressed(e) {
    e.preventDefault();
    const data = JSON.stringify({
      nameNl: newSubCategoryNL.trim(),
      nameEn: newSubCategoryEN.trim(),
      nameDe: newSubCategoryDE.trim(),
      nameFr: newSubCategoryFR.trim(),
      prefix: newPrefix.trim(),
    });

    if (editingSubcategory.id !== undefined) {
      const putJson = await putSubcategory(editingSubcategory.id, data);
      if (putJson.success === true) {
        const index = subcategories.indexOf(editingSubcategory);
        let editingSubcategories = subcategories;
        editingSubcategories[index] = putJson.data;
        setSubcategories(editingSubcategories);
        toastSuccess(translate('toast_adminSubcategoryEditSuccess'));
        closeModal();
      } else {
        toastError(putJson.error);
        console.error(putJson);
      }
      return;
    }

    const postJson = await postSubcategory(data);
    if (postJson.success === true) {
      setSubcategories((previousSubcats) => [
        ...previousSubcats,
        postJson.data,
      ]);
      toastSuccess(translate('toast_adminSubcategoryAddSuccess'));
      closeModal();
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  function resetInputFields() {
    setNewSubCategoryNL('');
    setNewSubCategoryDE('');
    setNewSubCategoryFR('');
    setNewSubCategoryEN('');
    setNewPrefix('');
    setEditingSubcategory({});
  }

  function handleUpdateSubcategory(subcategory) {
    setEditingSubcategory(subcategory);
    setCategoryData(subcategory);
    setModalIsOpen(true);
  }

  function setCategoryData(subcategory) {
    setNewSubCategoryNL(subcategory.nameNl);
    setNewSubCategoryEN(subcategory.nameEn);
    setNewSubCategoryFR(subcategory.nameFr);
    setNewSubCategoryDE(subcategory.nameDe);
    setNewPrefix(subcategory.prefix);
  }

  return (
    <div>
      {/* http://reactcommunity.org/react-modal/ */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">Subcategorie</h2>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-nl"
                >
                  Name NL
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name-nl"
                  id="name-nl"
                  type="text"
                  value={newSubCategoryNL}
                  onChange={(e) => setNewSubCategoryNL(e.target.value)}
                  required
                  autoFocus
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-fr"
                >
                  Name FR
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name-fr"
                  id="name-fr"
                  type="text"
                  value={newSubCategoryFR}
                  onChange={(e) => setNewSubCategoryFR(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-en"
                >
                  Name EN
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name-en"
                  id="name-en"
                  type="text"
                  value={newSubCategoryEN}
                  onChange={(e) => setNewSubCategoryEN(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-de"
                >
                  Name DE
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name-de"
                  id="name-de"
                  type="text"
                  value={newSubCategoryDE}
                  onChange={(e) => setNewSubCategoryDE(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="prefix"
                >
                  Prefix
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="prefix"
                  id="prefix"
                  type="text"
                  value={newPrefix}
                  onChange={(e) => setNewPrefix(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="w-full mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                Annuleren
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                Opslaan
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_subcategories')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Prefix
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Naam
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {subcategories.length > 0 &&
                subcategories.map((subcategory) => {
                  return (
                    <tr key={subcategory.id}>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {subcategory.prefix}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {subcategory.nameNl}
                        </p>
                      </td>
                      <td className="py-3 px-5 bg-white border-b border-gray-200 text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleUpdateSubcategory(subcategory)}
                          className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('edit')}
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            handleDeleteSubcategory(subcategory.id)
                          }
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
