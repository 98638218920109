import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import translate from '../../../i18n/translate';
import { FaCheckSquare } from 'react-icons/fa';
import {
  fetchCategories,
  postCategory,
  putCategory,
  fetchSubcategories,
  deleteCategory,
} from '../../../api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '715px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminCategories() {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [newCategoryNL, setNewCategoryNL] = useState('');
  const [newCategoryEN, setNewCategoryEN] = useState('');
  const [newCategoryDE, setNewCategoryDE] = useState('');
  const [newCategoryFR, setNewCategoryFR] = useState('');
  const [newPrefix, setNewPrefix] = useState('');
  const [newUniveral, setNewUniveral] = useState(false);

  const [unassignedSubcats, setUnassignedSubcats] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [updatingCategoryId, setUpdatingCategoryId] = useState('');

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  function handleUpdateCategory(category) {
    setNewCategoryNL(category.nameNl);
    setNewCategoryDE(category.nameDe);
    setNewCategoryFR(category.nameFr);
    setNewCategoryEN(category.nameEn);
    setNewPrefix(category.prefix);
    setNewUniveral(category.universal);

    let unassignedSubCategories = subcategories;
    let selectedSubcats = [];
    category.subcategories.forEach((subcatId) => {
      const foundSubcat = subcategories.filter(
        (subcategory) => subcategory.value === subcatId
      )[0];
      if (foundSubcat !== undefined) {
        selectedSubcats.push(foundSubcat);
        const index = unassignedSubCategories.indexOf(foundSubcat);
        unassignedSubCategories.splice(index, 1);
      }
    });
    setSelectedSubcategories(selectedSubcats);
    setUnassignedSubcats(unassignedSubCategories);
    setUpdatingCategoryId(category.id);
    openModal();
  }

  async function handleDeleteCategory(id) {
    const deleteJson = await deleteCategory(id);
    if (deleteJson.success === true) {
      const filteredCats = categories.filter((cat) => {
        return cat.id !== id;
      });
      setCategories(filteredCats);
      toastSuccess(translate('toast_adminCategoryDeleteSuccess'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  useEffect(() => {
    async function fetchCategoryData() {
      const getJson = await fetchCategories();
      setCategories(getJson);
    }
    async function fetchSubCategoryData() {
      const getJson = await fetchSubcategories();
      const selectCategories = getJson.map((category) => {
        return { value: category.id, label: category.nameNl };
      });
      setSubcategories(selectCategories);
    }
    fetchCategoryData();
    fetchSubCategoryData();
  }, []);

  async function onSubmitPressed(e) {
    e.preventDefault();

    let subcategories = [];
    if (selectedSubcategories) {
      subcategories = selectedSubcategories.map((subcat) => subcat.value);
    }

    const jsonBody = JSON.stringify({
      nameNl: newCategoryNL.trim(),
      nameEn: newCategoryEN.trim(),
      nameDe: newCategoryDE.trim(),
      nameFr: newCategoryFR.trim(),
      prefix: newPrefix,
      universal: newUniveral,
      subcategories,
    });
    if (updatingCategoryId.length > 0) {
      const putJson = await putCategory(updatingCategoryId, jsonBody);
      if (putJson.success === true) {
        const filteredCat = categories.filter(
          (category) => category.id === updatingCategoryId
        )[0];
        const index = categories.indexOf(filteredCat);
        let editingCategories = categories;
        editingCategories[index] = putJson.data;
        setCategories(editingCategories);
        toastSuccess(translate('toast_adminCategoryUpdateSuccess'));
        closeModal();
      } else {
        toastError(putJson.error);
        console.error(putJson);
      }
    } else {
      const postJson = await postCategory(jsonBody);
      if (postJson.success === true) {
        setCategories((previousCats) => [...previousCats, postJson.data]);
        toastSuccess(translate('toast_adminCategoryAddSuccess'));
        closeModal();
      } else {
        toastError(postJson.error);
        console.error(postJson);
      }
    }
  }

  useEffect(() => {
    let assignedSubcats = [];
    categories.forEach((category) => {
      assignedSubcats = [...assignedSubcats, ...category.subcategories];
    });
    const unassigned = subcategories.filter((subcat) => {
      return assignedSubcats.includes(subcat.value) === false;
    });
    setUnassignedSubcats(unassigned);
  }, [categories, subcategories]);

  function resetInputFields() {
    setNewCategoryNL('');
    setNewCategoryDE('');
    setNewCategoryFR('');
    setNewCategoryEN('');
    setNewPrefix('');
    setNewUniveral(false);
    setSelectedSubcategories([]);
    setUpdatingCategoryId('');
  }

  function onSubcategoryChange(selectedOptions) {
    setSelectedSubcategories(selectedOptions);
  }

  return (
    <div>
      {/* http://reactcommunity.org/react-modal/ */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            {updatingCategoryId.length > 0 ? (
              <h2 className="text-center w-full">Categorie aanpassen</h2>
            ) : (
              <h2 className="text-center w-full">Categorie toevoegen</h2>
            )}
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-nl"
                >
                  Name NL
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name-nl"
                  id="name-nl"
                  type="text"
                  value={newCategoryNL}
                  onChange={(e) => setNewCategoryNL(e.target.value)}
                  required
                  autoFocus
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-fr"
                >
                  Name FR
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name-fr"
                  id="name-fr"
                  type="text"
                  value={newCategoryFR}
                  onChange={(e) => setNewCategoryFR(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-en"
                >
                  Name EN
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name-en"
                  id="name-en"
                  type="text"
                  value={newCategoryEN}
                  onChange={(e) => setNewCategoryEN(e.target.value)}
                  required
                />
              </div>
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-de"
                >
                  Name DE
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="name-de"
                  id="name-de"
                  type="text"
                  value={newCategoryDE}
                  onChange={(e) => setNewCategoryDE(e.target.value)}
                  required
                />
              </div>

              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="prefix"
                >
                  Prefix
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="prefix"
                  id="prefix"
                  type="text"
                  value={newPrefix}
                  onChange={(e) => setNewPrefix(e.target.value)}
                  required
                />
              </div>

              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold"
                  htmlFor="highlighted"
                >
                  Universal
                </label>
                <input
                  name="universal"
                  id="universal"
                  type="checkbox"
                  checked={newUniveral}
                  onChange={(e) => setNewUniveral(e.target.checked)}
                />
              </div>

              <div className="w-full px-3 py-1 md:mb-0">
                <h3 className="mb-2">Subcategories</h3>
                <Select
                  isMulti
                  options={unassignedSubcats}
                  value={selectedSubcategories}
                  onChange={(selectedOptions) =>
                    onSubcategoryChange(selectedOptions)
                  }
                  closeMenuOnSelect={false}
                />
              </div>
            </div>

            <div className="w-full mt-6 mb-4 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                {translate('cancel')}
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                {updatingCategoryId.length > 0
                  ? translate('edit')
                  : translate('save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('categories')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Prefix
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Naam
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Aantal Subcats
                </th>
                <th className="py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Universeel
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {categories.length > 0 &&
                categories.map((category) => {
                  return (
                    <tr key={category.id}>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {category.prefix}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {category.nameNl}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap text-center">
                          {category.subcategories.length}
                        </p>
                      </td>
                      <td className="border-b border-gray-200">
                        {category.universal === true && (
                          <span className="text-2xl text-green-500">
                            <FaCheckSquare />
                          </span>
                        )}
                      </td>
                      <td className="py-3 px-5 bg-white border-b border-gray-200 text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleUpdateCategory(category)}
                          className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('edit')}
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteCategory(category.id)}
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
