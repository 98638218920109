import { Locales } from '../locales';

export default {
  [Locales.German]: {
    // navbar
    shop: 'Geschäft',
    aboutUs: 'Über uns',
    gallery: 'Galerie',
    contact: 'Kontakt',
    approvedByCMA: 'Genehmigt durch CMA',
    login: 'Einloggen',

    // footer
    footer_termsAndConditions: 'Geschäftsbedingungen',
    footer_followUs: 'Folge uns',
    footer_poweredBy: 'Mit der Unterstützung von',

    // contact
    contact_name: 'Name',
    contact_message: 'Botschaft',
    contact_whichCarQuestion: 'Um welches Auto geht es bei Ihrer Frage?',
    contact_howDoYouKnowCMA: 'Woher kennst du CMA?',
    contact_sendMeACopy: 'Sende mir eine Kopie',
    contact_previousPurchase: 'Ein vorheriger Kauf',
    contact_aFriend: 'Ein Freund',
    contact_aCarMeet: 'Ein Autotreffen',

    // login
    login_title: 'Einloggen',
    login_dontHaveAnAccount: 'Keinen account?',
    login_forgotPassword: 'Passwort vergessen?',

    // sign up
    signup_title: 'Ein Konto Erstellen',
    signup_confirmPassword: 'Kennwort bestätigen',
    signup_birthday: 'Geburtsdatum',

    // forgot password
    forgotpassword_title: 'Passwort Vergessen',

    // shop
    shop_comeBackLater:
      'Keine Produkte gefunden. Wir aktualisieren den Shop jeden Tag. Bitte komm bald zurück!',

    // product details
    details_alwaysInStock: 'Immer auf Lager',
    details_outOfStock: 'Nicht vorrättig!',
    details_xInStock: '{amount} auf Lager',
    details_articleNr: 'Artikelnummer:',
    details_details: 'einzelheiten',
    details_shop: 'geschäft',
    details_askQuestion: 'Frage zu diesem Produkt',
    details_availableForCars: 'Passend für folgende Autos:',
    details_totalPrice: 'Gesamtpreis',
    details_notAllVariantsSelected: 'Bitte wählen Sie aus allen Feldern',
    details_selectAnOption: '-- wähle eine Option --',
    details_customerPriceWithVAT: 'Kundenpreis mit Mehrwertsteuer',
    details_resellerPriceWithoutVAT: 'Wiederverkäuferpreis ohne Mehrwertsteuer',
    details_resellerPriceWithVAT: 'Wiederverkäuferpreis mit Mehrwertsteuer',
    details_margeWithoutVAT: 'Marge ohne Mehrwertsteuer',
    details_freeShipment: 'Kostenloser Versand',
    details_youMightAlsoLike: 'Das könnte dir auch gefallen',
    details_youMightAlsoNeed: 'Möglicherweise benötigen Sie auch',

    // admin
    admin_orders: 'Aufträge',
    admin_users: 'Benutzer',
    admin_products: 'Produkte',
    admin_settings: 'Einstellungen',
    admin_cars: 'Autos',
    admin_social: 'Sozial',
    admin_aboutus: 'Über uns',
    admin_approvedBy: 'Genehmigt durch',
    admin_gallery: 'Galerie',
    admin_articleNumber: 'Artikel nummer',
    admin_productName: 'Name',
    admin_price: 'Preis',
    admin_discount: 'Reduzierter Preis',
    admin_variants: 'Variants',
    admin_extraInfo: 'Zusatzinformation',
    admin_termsAndConditions: 'Geschäftsbedingungen',
    admin_supplier: 'Lieferant',
    admin_supplierProducts: 'Lieferantenprodukte',
    admin_customer: 'Kunde',
    admin_generalImages: 'Allgemeine Bilder',
    admin_purchases: 'Käufe',
    admin_sales: 'Verkaufen',
    admin_car: 'Auto',
    admin_productName: 'Produktname',

    // profile
    profile_general: 'Allgemeines',
    profile_addresses: 'Adressen',
    profile_changePassword: 'Passwort ändern',
    profile_createAddress: 'Adresse erstellen',
    profile_noAddressAvailable:
      'Noch keine Adressen verfügbar! Bitte erstellen Sie eine.',
    profile_defaultLanguageDescription:
      'Achtung: Wenn Sie die Sprache im Navigationsbereich ändern, wird die Standardsprache in Ihrem Profil nicht aktualisiert.',

    // basket
    basket_totalArticles: 'Artikel insgesamt',
    basket_deliveryCosts: 'Versandkosten',
    basket_total: 'Gesamt',
    basket_paymentMethod: 'Bezahlverfahren',
    basket_delivery: 'Lieferung',
    basket_pickUp: 'Abholen',
    basket_addressOutOfReach:
      'Die Lieferadresse kann außerhalb der möglichen Lieferzone liegen. Bitte kontaktieren Sie, um die Versandkosten zu besprechen.',
    basket_basket: 'Einkaufswagen',
    basket_noItems:
      'Es sind noch keine Artikel in Ihrem Warenkorb! Wenn sich Artikel in Ihrem Warenkorb befinden sollten, diese jedoch nicht sichtbar sind, besteht die Änderung darin, dass diese Artikel möglicherweise aus dem Geschäft entfernt wurden.',
    basket_confirmation: 'Bestätigung',
    basket_orderProcessingTitle: '-|--> Die Zahlung wird bearbeitet',
    basket_orderSuccessTitle: '✅ Bezahlung erfolgreich',
    basket_orderFailedTitle: '❌ Bezahlung fehlgeschlagen',
    basket_orderProcessingDescription:
      'Ihre Bestellung wird bearbeitet. Sie können Ihren Bestellstatus in Ihrem Profil anzeigen.',
    basket_orderSuccessDescription:
      'Ihre Bestellung und Zahlung gut erhalten! Wir werden so schnell wie möglich mit Ihrer Bestellung beginnen. Sie können Ihren Bestellstatus in Ihrem Profil anzeigen.',
    basket_orderFailedDescription:
      'Bei Ihrer Zahlung ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre Bestellung. Sie können dies in Ihrem Profil tun.',
    basket_loginToProceed:
      'Sie müssen angemeldet sein, um diese Bestellung fortzusetzen.',
    basket_outsideDeliveryZone: 'Außerhalb der Lieferzone',
    basket_calculatedAtNextStep: 'Berechnet im nächsten Schritt',
    basket_customer: 'Kunde',
    basket_proceedForCustomer: 'Weiter für Kunden',

    // sidebar
    sidebar_profile: 'Profil',
    sidebar_admin: 'Admin',
    sidebar_orderHistory: 'Aufträge',

    // toast
    toast_mailSuccess: 'E-Mail erfolgreich gesendet',
    toast_mailFailed: 'E-Mail gesendet fehlgeschlagen',
    toast_passwordChangeSuccess: 'Passwort erfolgreich aktualisiert',
    toast_passwordChangeFailed: 'Passwortaktualisierung fehlgeschlagen',
    toast_addressAddSuccess: 'Adresse erfolgreich hinzugefügt',
    toast_addressDeleteSuccess: 'Adresse erfolgreich gelöscht',
    toast_addressAddFailed: 'Adressaddition fehlgeschlagen',
    toast_basketUpdatedSuccess: 'Korb erfolgreich aktualisiert',
    toast_basketUpdatedFailed: 'Korbaktualisierung fehlgeschlagen',
    toast_generalInformationSuccess:
      'Allgemeine Informationen erfolgreich aktualisiert',
    toast_generalInformationError:
      'Aktualisierung der allgemeinen Informationen fehlgeschlagen',
    toast_adminOrderUpdatedSuccess: 'Bestellung erfolgreich aktualisiert',
    toast_adminAboutUsUpdatedSuccess: 'Über uns erfolgreich aktualisiert',
    toast_adminSocialDeleteSuccess: 'Social erfolgreich gelöscht',
    toast_adminSocialAddSuccess: 'Social erfolgreich hinzugefügt',
    toast_adminCarDeleteSuccess: 'Auto erfolgreich gelöscht',
    toast_adminCarAddSuccess: 'Auto erfolgreich hinzugefügt',
    toast_adminCarUpdateSuccess: 'Auto erfolgreich aktualisiert',
    toast_adminCategoryDeleteSuccess: 'Kategorie erfolgreich gelöscht',
    toast_adminCategoryAddSuccess: 'Kategorie erfolgreich aktualisiert',
    toast_adminCategoryUpdateSuccess: 'Kategorie erfolgreich hinzugefügt',
    toast_adminSubcategoryDeleteSuccess: 'Unterkategorie erfolgreich gelöscht',
    toast_adminSubcategoryAddSuccess: 'Unterkategorie erfolgreich hinzugefügt',
    toast_adminSubcategoryEditSuccess:
      'Unterkategorie erfolgreich aktualisiert',
    toast_adminProductDeleteSuccess: 'Produkt erfolgreich gelöscht',
    toast_adminProductAddSuccess: 'Produkt erfolgreich hinzugefügt',
    toast_adminProductUpdateSuccess: 'Produkt erfolgreich aktualisiert',
    toast_adminGalleryDeleteSuccess: 'Galerie erfolgreich gelöscht',
    toast_adminGalleryAddSuccess: 'Galerie erfolgreich hinzugefügt',
    toast_adminApprovedByUpdateSuccess:
      'Genehmigt von erfolgreich aktualisiert',
    toast_adminApprovedByDeleteSuccess: 'Genehmigt von erfolgreich gelöscht',
    toast_adminApprovedByAddSuccess: 'Genehmigt von erfolgreich hinzugefügt',
    toast_registerSuccess: 'Registrierung erfolgreich',
    toast_basketUpdateFailed:
      'Erfolgreich angemeldet, aber beim Aktualisieren des Warenkorbs ist ein Fehler aufgetreten.',
    toast_forgotPasswordSuccessful:
      'Das Zurücksetzen des Passworts wurde erfolgreich an Ihre E-Mail-Adresse gesendet',
    toast_defaultAddressSuccess: 'Standardadresse erfolgreich aktualisiert',
    toast_logoutFailed:
      'Abmeldung fehlgeschlagen. Versuchen Sie es später noch einmal...',
    toast_adminUserUpdateSuccess: 'Benutzer erfolgreich aktualisiert',

    // orders
    orders_manageOrder: 'Bestellung verwalten',
    orders_generalInfo: 'Allgemeine Information',
    orders_updated: 'Letzte Aktualisierung',
    orders_status: 'Status',
    orders_order: 'Befehl',
    orders_delivery: 'Lieferung',
    orders_addressBuyer: 'Adresse Käufer',
    orders_products: 'Produkte',
    orders_product: 'Produkt',
    orders_variants: 'Varianten',
    orders_amount: 'Betragen',
    orders_total: 'Gesamt',
    orders_paymentMethod: 'Bezahlverfahren',
    orders_date: 'Datum',
    orders_orderNumber: 'Bestellnummer',
    orders_completed: 'Vollendet',
    orders_orderDeletedSuccess: 'Bestellung erfolgreich gelöscht',

    // generics
    email: 'Email',
    password: 'Passwort',
    description: 'Beschreibung',
    loading: 'Wird geladen...',
    brand: 'Marke',
    model: 'Model',
    type: 'Typ',
    firstName: 'Vorname',
    lastName: 'Familienname',
    company: 'Firma',
    vat: 'Umsatzsteuer-Identifikationsnummer',
    phone: 'Telefon',
    street: 'Straße',
    streetNumber: 'Nmr',
    streetBox: 'Box',
    postal: 'Postleitzahl',
    city: 'Platz',
    country: 'Land',
    highlighted: 'Hervorgehobene Produkte',
    language: 'Sprache',
    defaultLanguage: 'Standardsprache',
    categories: 'Kategorien',
    subcategories: 'Unterkategorien',
    category: 'Kategorie',
    subcategory: 'Unterkategorie',
    amount: 'Menge',
    yes: 'Jawohl',
    no: 'Nein',

    // buttons
    send: 'Versenden',
    signIn: 'Anmelden',
    signUp: 'Registrieren',
    signOut: 'Ausloggen',
    resetPassword: 'Passwort Zurücksetzen',
    back: 'Zurück',
    inBasket: 'Im Einkaufswagen',
    admin: 'Admin',
    cancel: 'Stornieren',
    save: 'Speichern',
    edit: 'Bearbeiten',
    new: '+ Neu',
    makeDefault: 'Machen Standard',
    questionAboutProduct: 'Frage zu diesem Produkt?',
    delete: 'Löschen',
    proceed: 'Vorgehen',
    pay: 'Zahlen',
    copy: 'Kopieren',
    filter: 'Filter',
    details: 'Einzelheiten',
    close: 'Nah dran',

    // errors
    error_fieldRequired: 'Dieses Feld wird benötigt',
    error_passwordsNotMatching: 'Passwörter stimmen nicht überein',
    error_passwordsEmpty: 'Eines der Passwörter ist leer',
    error_birthdayNotCorrect: 'Ungültiger Geburtstag',
  },
};
