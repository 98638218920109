// api = 'https://www.testfuninfuncfun.com/api/v1';
const api = '/api/v1';
export const endpoints = {
  PRODUCTS: '/products',
  PRODUCTDETAILS: '/products/details',
  PRODUCTSSIMPLE: '/products/simple',
  PRODUCTSGROUPED: '/products/grouped',
  PRODUCTSSIMPLEMYBASKET: '/products/simplemybasket',
  PRODUCTSSIMPLEBYCARS: '/products/simplebycars',
  PRODUCTSHIGHLIGHTS: '/products/highlights',
  PHOTO: '/photo',
  PRODUCTCARS: '/productcars',
  CATEGORIES: '/categories',
  SUBCATEGORIES: '/subcategories',
  USERS: '/users',
  ADDRESSES: '/addresses',
  BASKET: '/baskets',
  PAYMENTS: '/payments',
  ORDERS: '/orders',
  CONTACT: '/contact',
  SOCIAL: '/socials',
  ABOUTUS: '/aboutus',
  APPROVEDBY: '/approvedby',
  GALLERY: '/galleries',
  VARIANTS: '/variants',
  PRODUCTVARIANTS: '/productvariants',
  EXTRAINFO: '/extrainfos',
  TERMSANDCONDITIONS: '/termsandconditions',
  GENERALIMAGES: '/generalimages',
  SUPPLIERS: '/suppliers',
  SUPPLIERPRODUCTS: '/supplierproducts',
  PURCHASES: '/purchases',
  SALES: '/sales',
  LOGIN: '/auth/login',
  ME: '/auth/me',
  REGISTER: '/auth/register',
  FORGOTPASSWORD: '/auth/forgotpassword',
  CHANGEPASSWORD: '/auth/changepassword',
  LOGOUT: '/auth/logout',
};

const cookieType = 'include'; // todo: change to `same-origin` before final deployment
const getRequestOptions = {
  method: 'GET',
  credentials: cookieType, // this makes it possible to send stored cookies with requests
};
const deleteRequestOptions = {
  method: 'DELETE',
  credentials: cookieType,
};
const putRequestOptions = {
  method: 'PUT',
  headers: {},
  credentials: cookieType,
};
const postRequestOptions = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  credentials: cookieType,
};

// ?=============?
//    ?GENERAL?
// ?=============?

/**
 * Fetch data and returns it as a JSON object
 * @param {string} endpoint Endpoint where data will be fetched from
 */
export async function getRequest(endpoint) {
  const res = await fetch(`${api}${endpoint}`, getRequestOptions);
  return await res.json();
}

/**
 * Post data and returns the response as a JSON object
 * @param {string} endpoint Endpoint where data will be posted to
 * @param {json} body Stringified JSON data containing key-values
 */
export async function postRequest(endpoint, body = undefined) {
  let requestOptions = postRequestOptions;
  if (body !== undefined) {
    requestOptions = { ...postRequestOptions, body };
  }
  const res = await fetch(`${api}${endpoint}`, requestOptions);
  return await res.json();
}

/**
 * Puts (update) data and returns the response as a JSON object
 * @param {string} endpoint Endpoint where data will be updated to
 * @param {any} body Any type. E.g. FormData, or a stringified JSON data object
 * @param {bool} contentType Default true. When false, content-type will not be set in the header
 */
export async function putRequest(endpoint, body, includeContentType = true) {
  let requestOptions = putRequestOptions;
  if (includeContentType === true) {
    requestOptions.headers['Content-Type'] = 'application/json';
  } else {
    delete requestOptions.headers['Content-Type']; // specify no content type
  }
  const extendedRequestOptions = { ...requestOptions, body };
  const res = await fetch(`${api}${endpoint}`, extendedRequestOptions);
  return await res.json();
}

/**
 * Delete data and returns the response as a JSON object
 * @param {string} endpoint Endpoint where data will be deleted from
 */
export async function deleteRequest(endpoint) {
  const res = await fetch(`${api}${endpoint}`, deleteRequestOptions);
  return await res.json();
}
