import { endpoints, getRequest, putRequest, deleteRequest } from '../helpers';

// !==========!
//   !ORDERS!
// !==========!

/**
 * Fetch all orders
 */
export async function fetchOrders() {
  return await getRequest(endpoints.ORDERS);
}

/**
 * Fetch details of a single order
 * @param {string} id - Id of an order. This is a UUID value.
 */
export async function fetchOrderDetails(id) {
  return await getRequest(`${endpoints.ORDERS}/${id}`);
}

/**
 * Fetch a single order item
 * @param {string} id - Id of an order. This is a UUID value.
 */
export async function fetchOrder(id) {
  return await getRequest(`${endpoints.ORDERS}/${id}`);
}

/**
 * Fetch all products single order item
 * @param {string} id - Id of an order. This is a UUID value.
 */
export async function fetchOrderProducts(id) {
  return await getRequest(`${endpoints.ORDERS}/${id}/products`);
}

/**
 * Fetch all orders of the current logged in user
 */
export async function fetchMyOrders() {
  return await getRequest(`${endpoints.ORDERS}/me`);
}

/**
 * Fetch order status
 * @param {string} id - Id of an order. This is a UUID value.
 */
export async function fetchOrderStatus(id) {
  return await getRequest(`${endpoints.ORDERS}/${id}/status`);
}

/**
 * Put an order as handled
 * @param {string} id - Id of an order. This is a UUID value.
 */
export async function putOrderHandleState(id) {
  return await putRequest(`${endpoints.ORDERS}/${id}/handled`);
}

/**
 * Delete an order
 * @param {string} id - Id of an order. This is a UUID value.
 */
export async function deleteOrder(id) {
  return await deleteRequest(`${endpoints.ORDERS}/${id}`);
}
