import React from 'react';

/**
 * A Button component that also returns an onClick action
 * @param {string} text - Text displayed in the button
 * @param {string} type - Type of button. Default: `button`
 * @param {any} handleClick - Action when clicked on the button
 */
export default function ButtonActionOutlinePrimary({
  text,
  type = 'button',
  handleClick,
  disabled = false,
}) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={
        'border-2 border-cmagreen text-cmagreen font-bold py-2 px-4 ' +
        (disabled
          ? 'cursor-not-allowed opacity-50'
          : 'hover:text-cmagreenhover hover:border-cmagreenhover')
        //cmagreen
      }
      onClick={handleClick}
    >
      {text}
    </button>
  );
}
