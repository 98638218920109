import React, { useState, createContext } from 'react';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState({
    id: undefined,
    firstName: '',
    lastName: '',
    password: '',
    role: '',
    currency: '',
    email: '',
    phone: null,
    defaultAddressId: null,
    company: '',
    vat: '',
    language: '',
  });

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};
