import { endpoints, getRequest, postRequest, deleteRequest } from '../helpers';

// !==========!
//   !SALES!
// !==========!

/**
 * Fetch all sales
 */
export async function fetchSales() {
  return await getRequest(endpoints.SALES);
}

/**
 * Fetch a single sale
 * @param {string} id - Id of a sale. This is a UUID value.
 */
export async function fetchSale(id) {
  return await getRequest(`${endpoints.SALES}/${id}`);
}

/**
 * Post a new sale
 * @param {json} body - Stringified JSON data containing sale key-values
 */
export async function postSale(body) {
  return await postRequest(endpoints.SALES, body);
}

/**
 * Put a new sale
 * @param {json} body - Stringified JSON data containing sale key-values
 * @param {string} id - Id of a sale item. This is a UUID value.
 */
export async function putSale(id, body) {
  return await postRequest(`${endpoints.SALES}/${id}`, body);
}

/**
 * Delete a single sale based on the given id value
 * @param {string} id - Id of a sale item. This is a UUID value.
 */
export async function deleteSale(id) {
  return await deleteRequest(`${endpoints.SALES}/${id}`);
}
