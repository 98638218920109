import React, { useState, useEffect, useContext } from 'react';
import { FilterContext } from '../../../context/FilterContext';
import {
  fetchProductCarsLinkedToProducts,
  fetchCategories,
  fetchSubcategories,
} from '../../../api';
import Select from 'react-select';
import translate from '../../../i18n/translate';

export default function Filter() {
  const [, setFilter] = useContext(FilterContext);

  const [productCars, setProductCars] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [types, setTypes] = useState([]);

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableSubcategories, setAvailableSubcategories] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');

  useEffect(() => {
    // ?product data
    async function fetchData() {
      const getJson = await fetchProductCarsLinkedToProducts();
      setProductCars(getJson);
    }
    fetchData();
  }, []);

  useEffect(() => {
    // ?category data
    const language = localStorage.getItem('language');
    async function fetchCategoryData() {
      const getJson = await fetchCategories();
      const selectCategories = getJson.map((category) => {
        let name = '';
        switch (language) {
          case 'en-US':
            name = category.nameEn;
            break;
          case 'de-DE':
            name = category.nameDe;
            break;
          case 'fr-BE':
            name = category.nameFr;
            break;
          default:
            name = category.nameNl;
        }
        return {
          value: category.id,
          label: name,
        };
      });
      setCategories(getJson);
      setAvailableCategories(selectCategories);
    }
    async function fetchSubCategoryData() {
      const getJson = await fetchSubcategories();
      const selectCategories = getJson.map((subcategory) => {
        let name = '';
        switch (language) {
          case 'en-US':
            name = subcategory.nameEn;
            break;
          case 'de-DE':
            name = subcategory.nameDe;
            break;
          case 'fr-BE':
            name = subcategory.nameFr;
            break;
          default:
            name = subcategory.nameNl;
        }
        return { value: subcategory.id, label: name };
      });
      setSubcategories(selectCategories);
    }
    fetchCategoryData();
    fetchSubCategoryData();
  }, []);

  useEffect(() => {
    const localCategory = localStorage.getItem('filter_category');
    const localSelectedCategory = availableCategories.filter(
      (cat) => cat.value === localCategory
    )[0];
    if (localSelectedCategory === undefined) {
      setSelectedCategory('');
      setSelectedSubcategory('');
      return;
    }
    setSelectedCategory(localSelectedCategory);
  }, [availableCategories]);

  useEffect(() => {
    const localSubcategory = localStorage.getItem('filter_subcategory');
    const localSelectedSubcategory = availableSubcategories.filter(
      (cat) => cat.value === localSubcategory
    )[0];
    if (localSelectedSubcategory === undefined) {
      setSelectedSubcategory('');
      return;
    }
    setSelectedSubcategory(localSelectedSubcategory);
  }, [availableSubcategories]);

  useEffect(() => {
    if (!productCars.length) {
      return;
    }
    // brands
    const brands = productCars.map((car) => car.brand);
    const uniqueBrands = [...new Set(brands)];
    const localBrand = localStorage.getItem('filter_brand');
    let localSelectBrand;
    const selectBrands = uniqueBrands.map((brand) => {
      const selectItem = { value: brand, label: brand };
      if (brand === localBrand) {
        localSelectBrand = selectItem;
      }
      return selectItem;
    });
    setBrands(selectBrands);
    if (localSelectBrand !== undefined) {
      setSelectedBrand(localSelectBrand);
    }
  }, [productCars]);

  useEffect(() => {
    // selectedBrand.value = id (UUID)
    // selectedBrand.label = text representation
    setModels([]); // reset selected models
    setSelectedModel('');
    setTypes([]); // reset selected types
    setSelectedType('');
    if (!selectedBrand) {
      return;
    }

    const productCarsOfBrand = productCars.filter((car) => {
      return car.brand === selectedBrand.label;
    });
    if (productCarsOfBrand) {
      let models = productCarsOfBrand.flatMap((car) => {
        if (car.model && car.model.length > 0) {
          return car.model;
        }
        return null;
      });
      models = models.filter((model) => model); // removing all null values
      const uniqueModels = [...new Set(models)];
      const localModel = localStorage.getItem('filter_model');
      let localSelectModel;
      const selectModels = uniqueModels.flatMap((model) => {
        if (model) {
          const selectItem = { value: model, label: model };
          if (model === localModel) {
            localSelectModel = selectItem;
          }
          return selectItem;
        }
        return null;
      });
      setModels(selectModels);
      if (localSelectModel !== undefined) {
        setSelectedModel(localSelectModel);
      }
    }
  }, [selectedBrand]);

  useEffect(() => {
    setTypes([]); // reset selected types
    setSelectedType('');
    if (!selectedModel) {
      return;
    }
    const productCarsOfBrandAndModel = productCars.filter((car) => {
      return (
        car.brand === selectedBrand.label && car.model === selectedModel.label
      );
    });
    if (productCarsOfBrandAndModel) {
      let types = productCarsOfBrandAndModel.map((car) => {
        if (car.type && car.type.length > 0) {
          return car.type;
        }
        return null;
      });
      types = types.filter((type) => type); // removing all null values
      const uniqueTypes = [...new Set(types)];
      const localType = localStorage.getItem('filter_type');
      let localSelectType;
      const selectTypes = uniqueTypes.map((type) => {
        const selectItem = { value: type, label: type };
        if (type === localType) {
          localSelectType = selectItem;
        }
        return selectItem;
      });
      setTypes(selectTypes);
      if (localSelectType !== undefined) {
        setSelectedType(localSelectType);
      }
    }
  }, [selectedModel]);

  useEffect(() => {
    let brand = '';
    let model = '';
    let type = '';
    let categoryId = '';
    let subcategoryId = '';
    if (selectedBrand) {
      brand = selectedBrand.label;
    }
    if (selectedModel) {
      model = selectedModel.label;
    }
    if (selectedType) {
      type = selectedType.label;
    }
    if (selectedCategory) {
      categoryId = selectedCategory.value;
    }
    if (selectedSubcategory) {
      subcategoryId = selectedSubcategory.value;
    }
    const car = productCars.filter(
      (car) => car.brand === brand && car.model === model && car.type === type
    )[0];
    if (car) {
      setFilter({
        brand,
        model,
        type,
        categoryId,
        subcategoryId,
        carId: car.id,
      });
    } else {
      setFilter({ brand, model, type, categoryId, subcategoryId });
    }
  }, [selectedType, selectedCategory, selectedSubcategory]);

  useEffect(() => {
    setAvailableSubcategories([]);
    setSelectedSubcategory('');
    if (!selectedCategory) {
      return;
    }
    const category = categories.filter((cat) => {
      return cat.id === selectedCategory.value;
    })[0];
    if (category) {
      const filteredSubcats = subcategories.filter((subcat) => {
        return category.subcategories.includes(subcat.value);
      });
      setAvailableSubcategories(filteredSubcats);
    }
  }, [selectedCategory, subcategories]);

  function handleBrandChange(option, meta) {
    setSelectedBrand(option);
    if (meta.action === 'clear') {
      localStorage.setItem('filter_brand', undefined);
      localStorage.setItem('filter_model', undefined);
      localStorage.setItem('filter_type', undefined);
    } else {
      localStorage.setItem('filter_brand', option.value);
    }
  }

  function handleModelChange(option, meta) {
    setSelectedModel(option);
    if (meta.action === 'clear') {
      localStorage.setItem('filter_model', undefined);
      localStorage.setItem('filter_type', undefined);
    } else {
      localStorage.setItem('filter_model', option.value);
    }
  }

  function handleTypeChange(option, meta) {
    setSelectedType(option);
    if (meta.action === 'clear') {
      localStorage.setItem('filter_type', undefined);
    } else {
      localStorage.setItem('filter_type', option.value);
    }
  }

  function handleCategoryChange(option, meta) {
    setSelectedCategory(option);
    if (meta.action === 'clear') {
      localStorage.setItem('filter_category', undefined);
      localStorage.setItem('filter_subcategory', undefined);
    } else {
      localStorage.setItem('filter_category', option.value);
    }
  }

  function handleSubcategoryChange(option, meta) {
    setSelectedSubcategory(option);
    if (meta.action === 'clear') {
      localStorage.setItem('filter_subcategory', undefined);
    } else {
      localStorage.setItem('filter_subcategory', option.value);
    }
  }

  return (
    <div className="bg-cmabrown">
      <div className="flex flex-col w-full md:flex-row md:justify-center pt-5 px-3">
        <Select
          className="md:w-1/5 mr-1 md:mt-0"
          placeholder={translate('brand')}
          options={brands}
          value={selectedBrand}
          isSearchable
          isClearable
          onChange={(selectedOptions, actionMeta) =>
            handleBrandChange(selectedOptions, actionMeta)
          }
        />
        <Select
          className="md:w-1/5 mr-1 mt-2 md:mt-0"
          placeholder={translate('model')}
          options={models}
          value={selectedModel}
          isSearchable
          isClearable
          onChange={(selectedOptions, actionMeta) =>
            handleModelChange(selectedOptions, actionMeta)
          }
        />
        <Select
          className="md:w-1/5 mr-1 mt-2 md:mt-0"
          placeholder={translate('type')}
          options={types}
          value={selectedType}
          isSearchable
          isClearable
          onChange={(selectedOptions, actionMeta) =>
            handleTypeChange(selectedOptions, actionMeta)
          }
        />
      </div>
      <div className="flex flex-col w-full md:flex-row md:justify-center pb-5 px-3 md:mt-2">
        <Select
          className="md:w-1/5 mr-1 mt-2 md:mt-0"
          placeholder={translate('category')}
          options={availableCategories}
          value={selectedCategory}
          isSearchable
          isClearable
          onChange={(selectedOptions, actionMeta) =>
            handleCategoryChange(selectedOptions, actionMeta)
          }
        />
        <Select
          className="md:w-1/5 mr-1 mt-2 md:mt-0"
          placeholder={translate('subcategory')}
          options={availableSubcategories}
          value={selectedSubcategory}
          isSearchable
          isClearable
          onChange={(selectedOptions, actionMeta) =>
            handleSubcategoryChange(selectedOptions, actionMeta)
          }
        />
      </div>
    </div>
  );
}
