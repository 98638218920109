import { Locales } from '../locales';

export default {
  [Locales.Dutch]: {
    // navbar
    shop: 'Shop',
    aboutUs: 'Over ons',
    gallery: 'Gallerij',
    contact: 'Contact',
    approvedByCMA: 'Goedgekeurd door CMA',
    login: 'Log in',

    // footer
    footer_termsAndConditions: 'Voorwaarden',
    footer_followUs: 'Volg ons',
    footer_poweredBy: 'Gerealiseerd met de steun van',

    // contact
    contact_name: 'Naam',
    contact_message: 'Boodschap',
    contact_whichCarQuestion: 'Over welke auto gaat uw vraag?',
    contact_howDoYouKnowCMA: 'Hoe kent u CMA?',
    contact_sendMeACopy: 'Stuur mij een kopie',
    contact_previousPurchase: 'Een vorige aankoop',
    contact_aFriend: 'Een vriend',
    contact_aCarMeet: 'Een auto-ontmoeting',

    // login
    login_title: 'Log In',
    login_dontHaveAnAccount: 'Heb je geen account?',
    login_forgotPassword: 'Wachtwoord vergeten?',

    // sign up
    signup_title: 'Account Aanmaken',
    signup_confirmPassword: 'Bevestig wachtwoord',
    signup_birthday: 'Geboortedatum',

    // forgot password
    forgotpassword_title: 'Wachtwoord Vergeten',

    // shop
    shop_comeBackLater:
      'Geen producten gevonden. We updaten de winkel elke dag. Kom snel terug!',

    // product details
    details_alwaysInStock: 'Altijd op voorraad',
    details_outOfStock: 'Uitverkocht!',
    details_xInStock: '{amount} op voorraad',
    details_articleNr: 'Artikelnummer:',
    details_details: 'details',
    details_shop: 'shop',
    details_askQuestion: 'Vraag over dit product',
    details_availableForCars: 'Past op volgende wagens:',
    details_totalPrice: 'Totale prijs',
    details_notAllVariantsSelected: 'Gelieve alle velden te selecteren',
    details_selectAnOption: ' -- kies een optie -- ',
    details_customerPriceWithVAT: 'Klantenprijs incl. BTW',
    details_resellerPriceWithoutVAT: 'Resellerprijs excl. BTW',
    details_resellerPriceWithVAT: 'Resellerprijs incl. BTW',
    details_margeWithoutVAT: 'Marge excl. BTW',
    details_freeShipment: 'Gratis levering',
    details_youMightAlsoLike: 'Misschien vind je dit ook leuk',
    details_youMightAlsoNeed: 'Misschien heb je dit ook nodig',

    // admin
    admin_orders: 'Bestellingen',
    admin_users: 'Gebruikers',
    admin_products: 'Producten',
    admin_settings: 'Instellingen',
    admin_cars: 'Wagens',
    admin_social: 'Social',
    admin_aboutus: 'Over ons',
    admin_approvedBy: 'Goedgekeurd door CMA',
    admin_gallery: 'Gallerij',
    admin_articleNumber: 'Artikelnummer',
    admin_productName: 'Naam',
    admin_price: 'Prijs',
    admin_discount: 'Kortingsprijs',
    admin_variants: 'Variants',
    admin_extraInfo: 'Extra info',
    admin_termsAndConditions: 'Algemene voorwaarden',
    admin_supplier: 'Leverancier',
    admin_supplierProducts: 'Leveranciersproducten',
    admin_customer: 'Klant',
    admin_generalImages: 'Algemene afbeeldingen',
    admin_purchases: 'Aankopen',
    admin_sales: 'Verkopen',
    admin_car: 'Wagen',
    admin_productName: 'Productnaam',

    // profile
    profile_general: 'Algemeen',
    profile_addresses: 'Adressen',
    profile_changePassword: 'Wijzig wachtwoord',
    profile_createAddress: 'Adres aanmaken',
    profile_noAddressAvailable:
      'Nog geen adressen beschikbaar! Gelieve een aan te maken.',
    profile_defaultLanguageDescription:
      'Let op: wanneer u de taal in het navigatiegedeelte wijzigt, wordt de standaardtaal in uw profiel niet bijgewerkt.',

    // basket
    basket_totalArticles: 'Totaal artikelen',
    basket_deliveryCosts: 'Verzendkosten',
    basket_total: 'Totaal',
    basket_paymentMethod: 'Betaalmethode',
    basket_delivery: 'Levering',
    basket_pickUp: 'Afhalen',
    basket_addressOutOfReach:
      'Zendadres ligt mogelijks buiten de mogelijke leverzone. Neem contact op om de leveringskosten te bespreken.',
    basket_basket: 'Winkelmand',
    basket_noItems:
      'Er zijn nog geen items in je winkelmand. Als er normaal items in je winkelmand zitten, maar niet zichtbaar, kan het zijn dat deze items verwijderd zijn.',
    basket_confirmation: 'Bevestiging',
    basket_orderProcessingTitle: '-|--> Betaling in verwerking',
    basket_orderSuccessTitle: '✅ Betaling gelukt!',
    basket_orderFailedTitle: '❌ Betaling mislukt',
    basket_orderProcessingDescription:
      'Je bestelling is in verwerking. Je kan je bestelstatus bekijken in je profiel.',
    basket_orderSuccessDescription:
      'Je bestelling en betaling goed ontvangen! We gaan zo spoedig mogelijk van slag met je bestelling. Je kan je bestelstatussen bekijken in je profiel.',
    basket_orderFailedDescription:
      'Er is iets fout gelopen met je betaling. Gelieve je bestelling te controleren. Dit kan je doet in je profiel.',
    basket_loginToProceed:
      'Je moet ingelogd zijn om verder te kunnen gaan met je bestelling.',
    basket_outsideDeliveryZone: 'Buiten leveringszone',
    basket_calculatedAtNextStep: 'Berekend bij de volgende stap',
    basket_customer: 'Klant',
    basket_proceedForCustomer: 'Verder voor klant',

    // sidebar
    sidebar_profile: 'Profiel',
    sidebar_admin: 'Admin',
    sidebar_orderHistory: 'Bestellingen',

    // toast
    toast_mailSuccess: 'Mail succesvol verzonden',
    toast_mailFailed: 'E-mail verzonden mislukt',
    toast_passwordChangeSuccess: 'Wachtwoord succesvol bijgewerkt',
    toast_passwordChangeFailed: 'Wachtwoord bijwerken mislukt',
    toast_addressAddSuccess: 'Adres succesvol toegevoegd',
    toast_addressDeleteSuccess: 'Adres succesvol verwijderd',
    toast_addressAddFailed: 'Adres toevoegen mislukt',
    toast_basketUpdatedSuccess: 'Winkelmand succesvol bijgewerkt',
    toast_basketUpdatedFailed: 'Update winkelmand mislukt',
    toast_generalInformationSuccess: 'Algemene informatie succesvol bijgewerkt',
    toast_generalInformationError: 'Algemene informatie bijwerken mislukt',
    toast_adminOrderUpdatedSuccess: 'Bestelling succesvol bijgewerkt',
    toast_adminAboutUsUpdatedSuccess: 'Over ons succesvol bijgewerkt',
    toast_adminSocialDeleteSuccess: 'Social succesvol verwijderd',
    toast_adminSocialAddSuccess: 'Social succesvol toegevoegd',
    toast_adminCarDeleteSuccess: 'Wagen succesvol verwijderd',
    toast_adminCarAddSuccess: 'Wagen succesvol toegevoegd',
    toast_adminCarUpdateSuccess: 'Wagen succesvol bijgewerkt',
    toast_adminCategoryDeleteSuccess: 'Categorie succesvol verwijderd',
    toast_adminCategoryAddSuccess: 'Categorie succesvol toegevoegd',
    toast_adminCategoryUpdateSuccess: 'Categorie succesvol bijgewerkt',
    toast_adminSubcategoryDeleteSuccess: 'Subcategorie succesvol verwijderd',
    toast_adminSubcategoryAddSuccess: 'Subcategorie succesvol toegevoegd',
    toast_adminSubcategoryEditSuccess: 'Subcategorie succesvol bijgewerkt',
    toast_adminProductDeleteSuccess: 'Product succesvol verwijderd',
    toast_adminProductAddSuccess: 'Product succesvol toegevoegd',
    toast_adminProductUpdateSuccess: 'Product succesvol bijgewerkt',
    toast_adminGalleryDeleteSuccess: 'Gallerij succesvol verwijderd',
    toast_adminGalleryAddSuccess: 'Gallerij succesvol toegevoegd',
    toast_adminApprovedByUpdateSuccess: 'Goedgekeurd door succesvol bijgewerkt',
    toast_adminApprovedByDeleteSuccess: 'Goedgekeurd door succesvol verwijderd',
    toast_adminApprovedByAddSuccess: 'Goedgekeurd door succesvol toegevoegd',
    toast_registerSuccess: 'Registratie succesvol',
    toast_basketUpdateFailed:
      'Met succes ingelogd, maar er ging iets fout bij het updaten van de winkelmand.',
    toast_forgotPasswordSuccessful:
      'Wachtwoord reset succesvol verzonden naar uw e-mailadres',
    toast_defaultAddressSuccess: 'Standaardadres succesvol bijgewerkt',
    toast_logoutFailed: 'Uitloggen mislukt. Probeer later opnieuw...',
    toast_adminUserUpdateSuccess: 'Gebruiker succesvol bijgewerk',

    // orders
    orders_manageOrder: 'Bestelling beheren',
    orders_generalInfo: 'Algemene info',
    orders_updated: 'Laatst aangepast',
    orders_status: 'Status',
    orders_order: 'Order',
    orders_delivery: 'Levering',
    orders_addressBuyer: 'Adres koper',
    orders_products: 'Producten',
    orders_product: 'Product',
    orders_variants: 'Varianten',
    orders_amount: 'Aantal',
    orders_total: 'Totaal',
    orders_paymentMethod: 'Betaalmethode',
    orders_date: 'Datum',
    orders_orderNumber: 'Bestelnummer',
    orders_completed: 'Afgewerkt',

    // generics
    email: 'Email',
    password: 'Wachtwoord',
    description: 'Beschrijving',
    loading: 'Laden...',
    brand: 'Merk',
    model: 'Model',
    type: 'Type',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    company: 'Bedrijf',
    vat: 'Btw-nummer',
    phone: 'Telefoon',
    street: 'Straat',
    streetNumber: 'Nmr',
    streetBox: 'Bus',
    postal: 'Postcode',
    city: 'Plaats',
    country: 'Land',
    highlighted: 'Uitgelichte producten',
    language: 'Taal',
    defaultLanguage: 'Standaardtaal',
    categories: 'Categorieën',
    subcategories: 'Subcategorieën',
    category: 'Categorie',
    subcategory: 'Subcategorie',
    amount: 'Aantal',
    yes: 'Ja',
    no: 'Nee',

    // buttons
    send: 'Versturen',
    signIn: 'Inloggen',
    signUp: 'Registreren',
    signOut: 'Uitloggen',
    resetPassword: 'Wachtwoord Herstellen',
    back: 'Terug',
    inBasket: 'In Winkelmand',
    admin: 'Admin',
    cancel: 'Annuleren',
    save: 'Opslaan',
    edit: 'Aanpassen',
    new: '+ Nieuw',
    makeDefault: 'Maak Standaard',
    questionAboutProduct: 'Vraag over dit product?',
    delete: 'Verwijderen',
    proceed: 'Verder',
    pay: 'Betalen',
    copy: 'Kopiëren',
    filter: 'Filteren',
    details: 'Details',
    close: 'Sluiten',

    // errors
    error_fieldRequired: 'Dit veld is verplicht',
    error_passwordsNotMatching: 'Wachtwoorden komen niet overeen',
    error_passwordsEmpty: 'Een van de wachtwoorden is leeg',
    error_birthdayNotCorrect: 'Geboortedatum ongeldig',
  },
};
