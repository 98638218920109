import {
  endpoints,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../helpers';

// !=================!
//   !SUBCATEGORIES!
// !=================!

/**
 * Fetch all subcategories
 */
export async function fetchSubcategories() {
  return await getRequest(endpoints.SUBCATEGORIES);
}

/**
 * Fetch a single subcategory
 * @param {string} id - Id of a subcategory. This is a UUID value.
 */
export async function fetchSubcategory(id) {
  return await getRequest(`${endpoints.SUBCATEGORIES}/${id}`);
}

/**
 * Post a new subcategory
 * @param {json} body - Stringified JSON data containing subcategory key-values
 */
export async function postSubcategory(body) {
  return await postRequest(endpoints.SUBCATEGORIES, body);
}

/**
 * Put an existing subcategory
 * @param {string} id - Id of a product. This is a UUID value.
 * @param {json} body - Stringified JSON data containing subcategory key-values
 */
export async function putSubcategory(id, body) {
  return await putRequest(`${endpoints.SUBCATEGORIES}/${id}`, body);
}

/**
 * Delete a single subcategory based on the given id value
 * @param {string} id - Id of a subcategory. This is a UUID value.
 */
export async function deleteSubcategory(id) {
  return await deleteRequest(`${endpoints.SUBCATEGORIES}/${id}`);
}
