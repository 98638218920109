import React from 'react';
import { Link } from 'react-router-dom';

/**
 * A routable button component that navigates to another page
 * @param {string} to - Route to navigate to
 * @param {string} text - Text displayed in the button
 */
export default function ButtonRoutable({ to, text }) {
  return (
    <Link to={to}>
      <span className="bg-cmagreen border-2 border-cmagreen hover:border-cmagreenhover hover:bg-cmagreenhover text-white font-bold py-2 px-4">
        {text}
      </span>
    </Link>
  );
}
