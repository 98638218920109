import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { BasketContext } from '../../context/BasketContext';
import translate from '../../i18n/translate';
import Progress from '../UIKit/Progress';
import BasketSmallScreen from './components/BasketSmallScreen';
import BasketLargeScreen from './components/BasketLargeScreen';
import BasketUserManagement from './components/BasketUserManagement';
import { calculateLeverageCosts } from '../../utils';
import UserContactAddress from '../user/components/UserContactAddress';
import ButtonActionOutlinePrimary from '../UIKit/Buttons/ButtonActionOutlinePrimary';
import ButtonActionPrimary from '../UIKit/Buttons/ButtonActionPrimary';
import ButtonRoutable from '../UIKit/Buttons/ButtonRoutable';
import { toastError } from '../UIKit/Toastify';
import TextHead from '../UIKit/Labels/TextHead';
import {
  putMyBasket,
  deleteMyBasketItem,
  fetchMyAddress,
  fetchAddresses,
  postPayment,
  fetchSimpleProductsMyBasket,
  fetchBasketFullProducts,
} from '../../api';

function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
}

export default function Checkout(props) {
  const forceUpdate = useForceUpdate();
  const [user] = useContext(UserContext);
  const [basket, setBasket] = useContext(BasketContext);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [simpleProducts, setSimpleProducts] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [allAddresses, setAllAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('bancontact');
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceArticles, setTotalPriceArticles] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [leverageCosts, setLeverageCosts] = useState(0);
  const [hasBimmer, setHasBimmer] = useState(false);
  const [userSellerId, setUserSellerId] = useState('');
  const [proceedForUser, setProceedForUser] = useState(false);

  const [delivery, setDelivery] = useState(true);
  const [proceedEnabled, setProceedEnabled] = useState(true);
  const [showContactInformation, setShowContactInformation] = useState(false);
  const [showNotLoggedInInformation, setShowNotLoggedInInformation] =
    useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    setSelectedLanguage(props.selectedLocale);
    setSelectedUser(user);
  }, [props, user]);

  useEffect(() => {
    async function fetchAllAddresses() {
      let getJson = await fetchAddresses();
      setAllAddresses(getJson);
    }
    fetchAllAddresses();
  }, []);

  useEffect(() => {
    const shouldEnable = step === 1 || showContactInformation === false;
    setProceedEnabled(shouldEnable);
  }, [step, showContactInformation]);

  function totalBasketItemPrice(basketItem) {
    const product = basketItem.product;
    const variant = basketItem.variant;
    let price = parseFloat(
      product.priceDiscount ? product.priceDiscount : product.price
    );
    if (variant) {
      price += parseFloat(variant.price);
    }
    const totalPrice = parseFloat(price * basketItem.count);
    return totalPrice.toFixed(2);
  }

  useEffect(() => {
    if (!simpleProducts || !simpleProducts.length) {
      setTotalPriceArticles(0);
      return;
    }
    let totalArticle = simpleProducts.reduce((currentTotal, basketItem) => {
      return (
        parseFloat(currentTotal) + parseFloat(totalBasketItemPrice(basketItem))
      );
    }, 0);
    setTotalPriceArticles(totalArticle.toFixed(2));
  }, [simpleProducts, forceUpdate]);

  useEffect(() => {
    let totalWeight = simpleProducts.reduce((currentWeight, fullProduct) => {
      return currentWeight + parseFloat(fullProduct.product.weight);
    }, 0);
    setTotalWeight(totalWeight.toFixed(2));
    const bimmer = simpleProducts.filter((fullProduct) => {
      return fullProduct.product.bimmerproduct === true;
    });
    setHasBimmer(bimmer.length === simpleProducts.length);
  }, [simpleProducts, forceUpdate]);

  useEffect(() => {
    if (delivery === false) {
      setLeverageCosts(parseFloat(0).toFixed(2));
      setShowContactInformation(false);
      return;
    }
    const address = fullAddress();
    if (address === undefined) {
      setLeverageCosts(parseFloat(0).toFixed(2));
      return;
    }
    const cost = calculateLeverageCosts(
      address.country,
      address.postal,
      hasBimmer,
      totalWeight
    );
    if (cost === undefined) {
      // show message user to contact CMA for leverage cost
      setShowContactInformation(delivery === true);
      setLeverageCosts(parseFloat(0).toFixed(2));
    } else {
      setLeverageCosts(cost.toFixed(2));
      setShowContactInformation(false);
      setProceedEnabled(true);
    }
  }, [selectedAddress, totalWeight, hasBimmer, delivery]);

  useEffect(() => {
    if (step !== 2) {
      // do not count leverage costs as this is not visible for the user yet
      setTotalPrice(parseFloat(totalPriceArticles).toFixed(2));
      return;
    }
    const total = parseFloat(totalPriceArticles) + parseFloat(leverageCosts);
    setTotalPrice(total.toFixed(2));
  }, [totalPriceArticles, leverageCosts, step]);

  useEffect(() => {
    if (basket.length > 0) {
      if (user.id !== undefined) {
        fetchBasketData();
      } else {
        fetchLocalBasketData();
      }
    }
  }, [basket]);

  async function fetchLocalBasketData() {
    const localBasket = localStorage.getItem('basket');
    if (localBasket) {
      const getJson = await fetchBasketFullProducts(
        JSON.stringify({ baskets: localBasket })
      );
      if (getJson.length > 0) {
        const mappedProducts = getJson.map((basketItem) => {
          return {
            basketId: basketItem.id,
            product: basketItem.product,
            variant: basketItem.variant,
            car: basketItem.car,
            count: basketItem.count,
          };
        });
        mappedProducts.sort((a, b) => (a.basketId > b.basketId ? 1 : -1));
        setSimpleProducts(mappedProducts);
      }
    }
  }

  async function fetchBasketData() {
    const getJson = await fetchSimpleProductsMyBasket();
    if (getJson.length > 0) {
      const mappedProducts = getJson.map((basketItem) => {
        return {
          basketId: basketItem.id,
          product: basketItem.product,
          variant: basketItem.variant,
          car: basketItem.car,
          count: basketItem.count,
        };
      });
      mappedProducts.sort((a, b) => (a.basketId > b.basketId ? 1 : -1));
      setSimpleProducts(mappedProducts);
    }
  }

  useEffect(() => {
    setShowNotLoggedInInformation(user.id === undefined);
  }, [user]);

  useEffect(() => {
    async function fetchAddressData() {
      const addresses = await fetchMyAddress();
      setAddresses(addresses);
    }
    if (user.id) {
      fetchAddressData();
    }
  }, [user]);

  useEffect(() => {
    if (user.defaultAddressId && addresses.length > 0) {
      const address = addresses.filter(
        (address) => address.id === user.defaultAddressId
      )[0];
      if (address) {
        setSelectedAddress(address.id);
      } else {
        setSelectedAddress('');
      }
    } else if (addresses.length > 0) {
      setSelectedAddress(addresses[0].id);
    }
  }, [user, addresses]);

  function handleBackClick() {
    if (step === 2) {
      setUserSellerId('');
    }
    setStep((prev) => setStep((prev -= 1)));
  }

  async function handleProceedClick() {
    if (step === 2) {
      // move to last step => payment
      await handlePay();
      return;
    }
    
    setStep((prev) => setStep((prev += 1)));
  }

  async function handleProceedCustomerClick() {
    setUserSellerId(user.id);
    setStep((prev) => setStep((prev += 1)));
  }

  async function handleDeleteProduct(basketItem) {
    const basketId = basketItem.basketId;
    if (user.id !== undefined) {
      // ?user is logged in. remove from server.
      const deleteJson = await deleteMyBasketItem(basketId);
      if (deleteJson.success === true) {
        const localBasketItems = simpleProducts.filter(
          (product) => product.basketId !== basketId
        );
        setBasket(deleteJson.data);
        localBasketItems.sort((a, b) => (a.basketId > b.basketId ? 1 : -1));
        setSimpleProducts(localBasketItems);
        forceUpdate();
      } else {
        toastError(deleteJson.error);
        console.error(deleteJson);
      }
    } else {
      // ?user is not logged in. remove from localStorage.
      let localBasket = JSON.parse(localStorage.getItem('basket'));
      localBasket = localBasket.filter((item) => item.basketId !== basketId);
      const localBasketItems = simpleProducts.filter(
        (product) => product.basketId !== basketId
      );
      localStorage.setItem('basket', JSON.stringify(localBasketItems));
      setBasket(localBasket);
      localBasketItems.sort((a, b) => (a.order > b.order ? 1 : -1));
      setSimpleProducts(localBasketItems);
      forceUpdate();
    }
  }

  function handleSelectedAddressChange(addressId) {
    if (addressId.length > 0) {
      setSelectedAddress(addressId);
    } else {
      setSelectedAddress('');
    }
  }

  function handleAddressListChange(newAddresses) {
    setAddresses(newAddresses)
  }

  function handleSelectedUserChange(user) {
    if (user) {
      setSelectedUser(user);
    } else {
      setSelectedUser({});
    }
    // skipping delivery
    setStep((prev) => setStep((prev += 2)));
  }

  function fullAddress() {
    if (user === undefined || user.id === undefined) {
      return undefined;
    }
    const address = addresses.filter(
      (address) => address.id === selectedAddress
    )[0];
    if (address === undefined) {
      return undefined;
    }
    return address;
  }

  async function handlePay() {
    const address = fullAddress();
    if (address === undefined) {
      return;
    }
    const value = `${totalPrice}`;
    const currency = 'EUR';
    const locale = selectedLanguage.replace('-', '_');
    let fullStreet = `${address.street} ${address.number} ${address.box}`;
    if (address.box) {
      fullStreet = `${fullStreet} box ${address.box}`;
    }
    const body = {
      value,
      currency,
      locale,
      firstName: address.firstName,
      lastName: address.lastName,
      street: fullStreet,
      postal: address.postal,
      city: address.city,
      country: address.country,
      method: selectedPaymentMethod,
      delivery,
      deliveryCost: leverageCosts,
      basket: JSON.stringify(simpleProducts),
    };

    if (userSellerId.length > 0) {
      body.userSellerId = userSellerId;
    }

    const postJson = await postPayment(JSON.stringify(body));
    if (postJson.success === true) {
      window.location.replace(postJson.checkoutUrl);
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  function handleProductAmountChange(value, basketId) {
    // basketId is used as failsave to check we have the correct item
    const localBasket = simpleProducts;
    const basket = simpleProducts.filter(
      (basket) => basket.basketId === basketId
    )[0];
    const index = simpleProducts.indexOf(basket);
    if (basket === undefined) {
      return;
    }

    const numberValue = parseInt(value);
    let absValue = Math.abs(numberValue);
    if (isNaN(absValue)) {
      absValue = 1;
    } else if (absValue > 1000) {
      absValue = 1000;
    }
    basket.count = absValue;
    localBasket[index] = basket;
    if (user.id !== undefined) {
      handleProductLogginInUserAmountChange(absValue, basketId, localBasket);
    } else {
      handleProductLocalStorageAmountChange(absValue, basketId, localBasket);
    }
  }

  function handleProductLocalStorageAmountChange(
    value,
    basketId,
    simpleBasket
  ) {
    const localBasket = JSON.parse(localStorage.getItem('basket'));
    const basket = localBasket.filter(
      (basket) => basket.basketId === basketId
    )[0];
    const index = localBasket.indexOf(basket);
    if (basket !== undefined) {
      basket.count = value;
      localBasket[index] = basket;
      localStorage.setItem('basket', JSON.stringify(localBasket));
      localBasket.sort((a, b) => (a.basketId > b.basketId ? 1 : -1));
      setSimpleProducts(simpleBasket);
      setBasket(localBasket);
      forceUpdate();
    }
  }

  async function handleProductLogginInUserAmountChange(
    value,
    basketId,
    localBasket
  ) {
    const putJson = await putMyBasket(
      JSON.stringify({ basketId, count: value })
    );
    if (putJson.success === true) {
      localBasket.sort((a, b) => (a.basketId > b.basketId ? 1 : -1));
      setSimpleProducts(localBasket);
      setBasket(putJson.data);
      forceUpdate();
    } else {
      toastError(translate('toast_basketUpdatedFailed'));
      console.error(putJson);
    }
  }

  function proceedText() {
    if (step === 2) {
      return translate('pay');
    }
    return translate('proceed');
  }

  return (
    <div className="container mx-auto mt-12 px-4">
      <Progress
        step={step}
        userRole={user.role}
        proceedForCustomer={userSellerId.length > 0}
      />
      <div className="md:my-6">
        {step === 1 && (
          <>
            <TextHead text={translate('basket_basket')} />
            <div className="block sm:hidden">
              <BasketSmallScreen
                basketItems={simpleProducts}
                handleDeleteProduct={handleDeleteProduct}
                handleProductAmountChange={handleProductAmountChange}
                selectedLanguage={selectedLanguage}
              />
            </div>
            <div className="sm:block hidden">
              <BasketLargeScreen
                basketItems={simpleProducts}
                handleDeleteProduct={handleDeleteProduct}
                handleProductAmountChange={handleProductAmountChange}
                selectedLanguage={selectedLanguage}
              />
            </div>
          </>
        )}
        {step === 2 && userSellerId.length > 0 && (
          <BasketUserManagement
            addresses={allAddresses}
            onUserSelectChange={handleSelectedUserChange}
          />
        )}
        {step === 2 && userSellerId.length === 0 && (
          <UserContactAddress
            selectedAddressId={selectedAddress}
            onAddressSelectChange={handleSelectedAddressChange}
            onAddressListChange={handleAddressListChange}
          />
        )}
      </div>
      {step < 3 && simpleProducts && simpleProducts.length > 0 && (
        <>
          <div className="flex lg:flex-row flex-col my-2">
            <div className="bg-green-200 lg:w-4/12 w-12/12">
              <table className="table-fixed w-11/12 m-4">
                <thead>
                  <tr>
                    <th className="w-5/12"></th>
                    <th className="w-7/12"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-1">
                      {translate('basket_totalArticles')}
                    </td>
                    <td className="text-right">€ {totalPriceArticles}</td>
                  </tr>
                  <tr className="border-b border-black border-solid">
                    <td className="py-1">
                      {translate('basket_deliveryCosts')}
                    </td>
                    <td className="text-right">
                      {step === 1
                        ? translate('basket_calculatedAtNextStep')
                        : showContactInformation
                        ? translate('basket_outsideDeliveryZone')
                        : '€' + leverageCosts}
                    </td>
                  </tr>
                  <tr className="font-bold">
                    <td className="py-1">{translate('basket_total')}</td>
                    <td className="text-right">€ {totalPrice}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex my-4">
                        {step === 2 && (
                          <div className="mr-2">
                            <ButtonActionOutlinePrimary
                              text={translate('back')}
                              handleClick={handleBackClick}
                            />
                          </div>
                        )}
                        {user.id !== undefined && (
                          <ButtonActionPrimary
                            text={proceedText()}
                            handleClick={handleProceedClick}
                            disabled={proceedEnabled === false}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="lg:ml-12">
              {step === 1 && (
                <section>
                  <TextHead text={translate('basket_paymentMethod')} />
                  <div className="flex">
                    <span
                      className="flex mx-1 cursor-pointer"
                      onClick={() => setSelectedPaymentMethod('bancontact')}
                    >
                      <input
                        type="radio"
                        value="bancontact"
                        readOnly
                        checked={selectedPaymentMethod === 'bancontact'}
                      />
                      <img
                        loading="lazy"
                        src="/images/payments/bancontact_logo.png"
                        alt="bancontact"
                        className="h-8 mx-1"
                      />
                    </span>
                    <span
                      className="flex mx-1 cursor-pointer"
                      onClick={() => setSelectedPaymentMethod('ideal')}
                    >
                      <input
                        type="radio"
                        value="ideal"
                        readOnly
                        checked={selectedPaymentMethod === 'ideal'}
                      />
                      <img
                        loading="lazy"
                        src="/images/payments/ideal_logo.png"
                        alt="iDeal"
                        className="h-8 mx-1"
                      />
                    </span>
                    <span
                      className="flex mx-1 cursor-pointer"
                      onClick={() => setSelectedPaymentMethod('banktransfer')}
                    >
                      <input
                        type="radio"
                        value="banktransfer"
                        readOnly
                        checked={selectedPaymentMethod === 'banktransfer'}
                      />
                      <img
                        loading="lazy"
                        src="/images/payments/banktransfer_logo.png"
                        alt="banktransfer"
                        className="h-8 mx-1"
                      />
                    </span>
                    <span
                      className="flex items-center mx-1 cursor-pointer"
                      onClick={() => setSelectedPaymentMethod('creditcard')}
                    >
                      <input
                        type="radio"
                        value="creditcard"
                        readOnly
                        checked={selectedPaymentMethod === 'creditcard'}
                      />
                      <img
                        loading="lazy"
                        src="/images/payments/mastercard_logo.png"
                        alt="mastercard"
                        className="h-8 mx-1"
                      />
                      <img
                        loading="lazy"
                        src="/images/payments/visa_logo.png"
                        alt="visa"
                        className="h-6 mx-1"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    {user.role !== 'user' && (
                      <ButtonActionOutlinePrimary
                        text={translate('basket_proceedForCustomer')}
                        handleClick={handleProceedCustomerClick}
                        disabled={proceedEnabled === false}
                      />
                    )}
                  </div>
                </section>
              )}

              {step === 2 && (
                <section>
                  <TextHead text={translate('basket_delivery')} />
                  <div className="flex">
                    <span
                      className="flex mx-1 cursor-pointer"
                      onClick={() => setDelivery(false)}
                    >
                      <input
                        type="radio"
                        value="non-delivery"
                        readOnly
                        checked={delivery === false}
                      />
                      <span className="mx-1">{translate('basket_pickUp')}</span>
                    </span>
                    <span
                      className="flex mx-1 cursor-pointer"
                      onClick={() => setDelivery(true)}
                    >
                      <input
                        type="radio"
                        value="delivery"
                        readOnly
                        checked={delivery === true}
                      />
                      <span className="mx-1">
                        {translate('basket_delivery')}
                      </span>
                    </span>
                  </div>
                </section>
              )}
            </div>
          </div>
          <section>
            {step === 3 && showContactInformation === true && (
              <>
                <div className="mt-6">
                  {translate('basket_addressOutOfReach')}
                </div>
                <div className="my-3">
                  <a
                    href="mailto:info@cma1stock0.eu"
                    className="no-underline my-2"
                  >
                    <span className="bg-cmagreen hover:bg-cmagreenhover text-white font-bold py-2 px-4 rounded">
                      {translate('contact')}
                    </span>
                  </a>
                </div>
              </>
            )}
            {showNotLoggedInInformation === true && (
              <div className="my-6">
                <p className="mb-3">{translate('basket_loginToProceed')}</p>
                <ButtonRoutable to="/login" text={translate('login')} />
              </div>
            )}
          </section>
        </>
      )}
    </div>
  );
}
