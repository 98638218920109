import React, { useState, useEffect } from 'react';
import translate from '../../i18n/translate';

export default function Progress({ step }) {
  const [colorStep1, setColorStep1] = useState('bg-green-200'); // basket
  const [colorStep2, setColorStep2] = useState('bg-green-200'); // user management or delivery selection
  const [colorStep3, setColorStep3] = useState('bg-green-200'); // confirmation

  useEffect(() => {
    const activeColor = 'cmagreen';
    const inactiveColor = 'green-200';

    if (step === 3) {
      setColorStep1(activeColor);
      setColorStep2(activeColor);
      setColorStep3(activeColor);
    } else if (step === 2) {
      setColorStep1(activeColor);
      setColorStep2(activeColor);
      setColorStep3(inactiveColor);
    } else if (step === 1) {
      setColorStep1(activeColor);
      setColorStep2(inactiveColor);
      setColorStep3(inactiveColor);
    } else {
      setColorStep1(inactiveColor);
      setColorStep2(inactiveColor);
      setColorStep3(inactiveColor);
    }
  }, [step]);

  return (
    <>
      <div className="w-full md:hidden block container">
        <span className={'text-sm text-' + colorStep1}>
          {translate('basket_basket')}
        </span>
        <span className={'mx-2 text-' + colorStep2}>&gt;</span>
        <span className={'text-sm text-' + colorStep2}>
          {translate('basket_delivery')}
        </span>
        <span className={'mx-2 text-' + colorStep2}>&gt;</span>
        <span className={'text-sm text-' + colorStep3}>
          {translate('basket_confirmation')}
        </span>
      </div>

      <div className="w-full hidden md:flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <span
            className={
              'mx-2 w-10 h-10 rounded-full text-white font-bold flex justify-center items-center text-sm bg-' +
              colorStep1
            }
          ></span>
          <span className="text-xs text-center my-1 text-cmagreen">
            {translate('basket_basket')}
          </span>
        </div>

        <div className="flex flex-col justify-center items-center">
          <span
            className={
              'rounded-lg w-40 h-1 transition duration-200 bg-' + colorStep2
            }
          ></span>
          <span className="h-6"></span>
        </div>

        <div className="flex flex-row justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <span
              className={
                'mx-2 w-10 h-10 rounded-full text-white font-bold flex justify-center items-center text-sm transition duration-200 bg-' +
                colorStep2
              }
            ></span>
            <span className="text-xs text-center my-1 text-cmagreen">
              {translate('basket_delivery')}
            </span>
          </div>

          <div className="flex flex-col justify-center items-center">
            <span
              className={
                'rounded-lg w-40 h-1 transition duration-200 bg-' + colorStep3
              }
            ></span>
            <span className="h-6"></span>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center">
          <span
            className={
              'mx-2 w-10 h-10 rounded-full text-white font-bold flex justify-center items-center text-sm transition duration-200 bg-' +
              colorStep3
            }
          ></span>
          <span className="text-xs text-center my-1 text-cmagreen">
            {translate('basket_confirmation')}
          </span>
        </div>
      </div>
    </>
  );
}
