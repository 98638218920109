import React, { useState, useEffect } from 'react';
import { fetchTermsAndConditions } from '../api';
import { textToHTML } from '../utils';
import translate from '../i18n/translate';
import TextLoading from './UIKit/Labels/TextLoading';
import TextHead from './UIKit/Labels/TextHead';

export default function TermsAndConditions(props) {
  const [termsAndConditions, setTermsAndConditions] = useState({});
  const [
    translatedTermsAndConditions,
    setTranslatedTermsAndConditions,
  ] = useState('');

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchTermsAndConditions();
      setTermsAndConditions(getJson);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (termsAndConditions) {
      const language = localStorage.getItem('language');
      switch (language) {
        case 'en-US':
          setTranslatedTermsAndConditions(termsAndConditions.nameEn);
          break;
        case 'de-DE':
          setTranslatedTermsAndConditions(termsAndConditions.nameDe);
          break;
        case 'fr-BE':
          setTranslatedTermsAndConditions(termsAndConditions.nameFr);
          break;
        default:
          setTranslatedTermsAndConditions(termsAndConditions.nameNl);
      }
    }
  }, [termsAndConditions, props]);

  if (termsAndConditions.id === undefined) return <TextLoading />;

  return (
    <div className="container mx-auto mt-8 px-4">
      <TextHead text={translate('admin_termsAndConditions')} center={true} />
      <p
        className="mt-4 text-left"
        dangerouslySetInnerHTML={{
          __html: textToHTML(translatedTermsAndConditions),
        }}
      />
    </div>
  );
}
