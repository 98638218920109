import React, { useState, useEffect } from 'react';
import translate from '../../../i18n/translate';
import { fetchUsers, putUser } from '../../../api';
import Modal from 'react-modal';
import { toastSuccess, toastError } from '../../UIKit/Toastify';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '80%',
    height: '620px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [fetchedUsers, setFetchedUsers] = useState([]); // used to restore user list from filter
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userCompany, setUserCompany] = useState('');
  const [userVAT, setUserVAT] = useState('');
  const [userRole, setUserRole] = useState('');

  const [filterEmail, setFilterEmail] = useState('');
  const [filterFirstName, setFilterFirstName] = useState('');
  const [filterLastName, setFilterLastName] = useState('');
  const [filterRole, setFilterRole] = useState('placeholder');

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  useEffect(() => {
    async function fetchData() {
      const getJson = await fetchUsers();
      setUsers(getJson);
      setFetchedUsers(getJson);
    }
    fetchData();
  }, []);

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  function resetInputFields() {
    setUserId('');
    setUserEmail('');
    setUserFirstName('');
    setUserLastName('');
    setUserPhone('');
    setUserCompany('');
    setUserVAT('');
    setUserRole('');
  }

  function handleEditUser(user) {
    setUserId(user.id);
    setUserEmail(user.email);
    setUserFirstName(user.firstName);
    setUserLastName(user.lastName);
    setUserPhone(user.phone);
    setUserCompany(user.company ?? '');
    setUserVAT(user.vat ?? '');
    setUserRole(user.role);
    openModal();
  }

  async function onSubmitPressed(e) {
    e.preventDefault();
    const userToBeSaved = {
      id: userId,
      email: userEmail,
      firstName: userFirstName,
      lastName: userLastName,
      phone: userPhone,
      company: userCompany,
      vat: userVAT,
      role: userRole,
    };
    const putJson = await putUser(userId, JSON.stringify(userToBeSaved));
    if (putJson.success === true) {
      let editingUsers = users;
      const filteredUser = editingUsers.filter((user) => user.id === userId)[0];
      if (!filteredUser) {
        toastError(
          'User could not be updated locally. Could not be found anymore.'
        );
        setUsers({ ...users, userToBeSaved });
        setFetchedUsers({ ...users, userToBeSaved });
        return;
      }
      const index = users.indexOf(filteredUser);
      editingUsers[index] = putJson.data;
      setUsers(editingUsers);
      setFetchedUsers(editingUsers);
      toastSuccess(translate('toast_adminUserUpdateSuccess'));
      closeModal();
    } else {
      toastError(putJson.error);
      console.error(putJson);
    }
  }

  function onFilterPressed() {
    let filteringUsers = fetchedUsers;
    if (filterEmail.trim().length > 0) {
      filteringUsers = filteringUsers.filter((user) =>
        user.email.toLowerCase().includes(filterEmail.toLowerCase())
      );
    }
    if (filterFirstName.trim().length > 0) {
      filteringUsers = filteringUsers.filter((user) =>
        user.firstName.toLowerCase().includes(filterFirstName.toLowerCase())
      );
    }
    if (filterLastName.trim().length > 0) {
      filteringUsers = filteringUsers.filter((user) =>
        user.lastName.toLowerCase().includes(filterLastName.toLowerCase())
      );
    }
    if (filterRole !== 'placeholder') {
      filteringUsers = filteringUsers.filter(
        (user) => user.role === filterRole
      );
    }
    setUsers(filteringUsers);
  }

  function onResetFilter() {
    setFilterEmail('');
    setFilterFirstName('');
    setFilterLastName('');
    setFilterRole('placeholder');
    setUsers(fetchedUsers);
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            <h2 className="text-center w-full">User</h2>
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="email"
                  id="email"
                  type="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  required
                  autoFocus
                />
              </div>
              <div className="w-full flex flex-row space-x-1">
                <div className="w-1/2 px-3 py-1 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="first-name"
                  >
                    First name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="first-name"
                    id="first-name"
                    type="text"
                    value={userFirstName}
                    onChange={(e) => setUserFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="w-1/2 px-3 py-1 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="last-name"
                  >
                    Last name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="last-name"
                    id="last-name"
                    type="text"
                    value={userLastName}
                    onChange={(e) => setUserLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="w-full flex flex-row space-x-1">
                <div className="w-1/2 px-3 py-1 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="company"
                  >
                    Company
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="company"
                    id="company"
                    type="text"
                    value={userCompany}
                    onChange={(e) => setUserCompany(e.target.value)}
                  />
                </div>
                <div className="w-1/2 px-3 py-1 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="vat"
                  >
                    VAT
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="vat"
                    id="vat"
                    type="text"
                    value={userVAT}
                    onChange={(e) => setUserVAT(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="phone"
                >
                  Phone
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="phone"
                  id="phone"
                  type="tel"
                  value={userPhone}
                  onChange={(e) => setUserPhone(e.target.value)}
                  required
                />
              </div>

              <div className="px-3 py-1 md:mb-0 flex flex-col">
                <span className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Role
                </span>
                <div className="relative">
                  <select
                    className="w-full rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-indigo-500 text-base pl-3 pr-10"
                    onChange={(e) => setUserRole(e.target.value)}
                    defaultValue={userRole}
                  >
                    <option value="user">User</option>
                    <option value="reseller">Reseller</option>
                    <option value="admin">Admin</option>
                  </select>
                  <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <div className="w-full mt-6 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                Annuleren
              </button>

              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                Opslaan
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* filter */}

      <h2 className="mt-6 text-lg font-semibold leading-tight">Filter</h2>
      <div className="h-10 mt-4 mb-8 flex flex-row space-x-2 items-center">
        <input
          className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          name="filter-email"
          id="filter-email"
          type="text"
          placeholder="Email"
          value={filterEmail}
          onChange={(e) => setFilterEmail(e.target.value)}
        />
        <input
          className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          name="filter-first-name"
          id="filter-first-name"
          type="text"
          placeholder="First name"
          value={filterFirstName}
          onChange={(e) => setFilterFirstName(e.target.value)}
        />
        <input
          className="appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          name="filter-last-name"
          id="filter-last-name"
          type="text"
          placeholder="Last name"
          value={filterLastName}
          onChange={(e) => setFilterLastName(e.target.value)}
        />
        <div className="px-3 py-1 md:mb-0 flex flex-col">
          <div className="relative">
            <select
              className="w-40 rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-indigo-500 text-base pl-3 pr-10"
              onChange={(e) => setFilterRole(e.target.value)}
              defaultValue={filterRole}
              value={filterRole}
            >
              <option selected value="placeholder">
                - select role -
              </option>
              <option value="user">User</option>
              <option value="reseller">Reseller</option>
              <option value="admin">Admin</option>
            </select>
            <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4"
                viewBox="0 0 24 24"
              >
                <path d="M6 9l6 6 6-6"></path>
              </svg>
            </span>
          </div>
        </div>
        <button
          type="button"
          onClick={() => onFilterPressed()}
          className="border border-blue-500 bg-blue-500 hover:bg-blue-700 hover:border-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {translate('filter')}
        </button>
        <button
          type="button"
          onClick={() => onResetFilter()}
          className="border border-blue-500 text-blue-500 py-2 px-4 rounded"
        >
          Reset
        </button>
      </div>

      {/* content */}
      <div>
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('admin_users')}
        </h2>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('email')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('name')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {translate('admin_role')}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {users.length > 0 &&
                users.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {user.email}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {user.firstName} {user.lastName}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {
                          <span
                            className={
                              'relative inline-block px-3 py-1 font-semibold leading-tight ' +
                              (user.role === 'admin'
                                ? 'text-green-900'
                                : user.role === 'reseller'
                                ? 'text-purple-900'
                                : 'text-blue-900')
                            }
                          >
                            <span
                              aria-hidden
                              className={
                                'absolute inset-0 opacity-50 rounded-full ' +
                                (user.role === 'admin'
                                  ? 'bg-green-200'
                                  : user.role === 'reseller'
                                  ? 'bg-purple-200'
                                  : 'bg-blue-200')
                              }
                            ></span>
                            <span className="relative">{user.role}</span>
                          </span>
                        }
                      </td>

                      <td className="py-5 px-5 border-b border-gray-200 bg-white text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleEditUser(user)}
                          className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('edit')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
