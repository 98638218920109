import {
  endpoints,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../helpers';

// !================!
//    !CATEGORIES!
// !================!

/**
 * Fetch all categories
 */
export async function fetchCategories() {
  return await getRequest(endpoints.CATEGORIES);
}

/**
 * Fetch a single category
 * @param {string} id - Id of a category. This is a UUID value.
 */
export async function fetchCategory(id) {
  return await getRequest(`${endpoints.CATEGORIES}/${id}`);
}

/**
 * Post a new category
 * @param {json} body - Stringified JSON data containing category key-values
 */
export async function postCategory(body) {
  return await postRequest(endpoints.CATEGORIES, body);
}

/**
 * Put an existing category
 * @param {string} id - Id of a category. This is a UUID value.
 * @param {json} body - Stringified JSON data containing category key-values
 */
export async function putCategory(id, body) {
  return await putRequest(`${endpoints.CATEGORIES}/${id}`, body);
}

/**
 * Delete a single category based on the given id value
 * @param {string} id - Id of a category. This is a UUID value.
 */
export async function deleteCategory(id) {
  return await deleteRequest(`${endpoints.CATEGORIES}/${id}`);
}
