import {
  endpoints,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../helpers';

// !=============!
//   !VARIANTS!
// !=============!

/**
 * Fetch all variants
 */
export async function fetchAllVariants() {
  return await getRequest(endpoints.VARIANTS);
}

/**
 * Fetch a single variant item
 * @param {string} id - Id of a variant. This is a UUID value.
 */
export async function fetchVariantForProduct(id) {
  return await getRequest(`${endpoints.VARIANTS}/${id}`);
}

/**
 * Post a new variant item
 * @param {json} body - Stringified JSON data containing variant key-values
 */
export async function postVariant(body) {
  return await postRequest(endpoints.VARIANTS, body);
}

/**
 * Put a variant item
 * @param {json} body - Stringified JSON data containing variant key-values
 */
export async function putVariant(id, body) {
  return await putRequest(`${endpoints.VARIANTS}/${id}`, body);
}

/**
 * Delete a single variant item based on the given id value
 * @param {string} id - Id of an variant item. This is a UUID value.
 */
export async function deleteVariant(id) {
  return await deleteRequest(`${endpoints.VARIANTS}/${id}`);
}
