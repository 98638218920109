import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Product from './components/Product';
import TextLoading from '../UIKit/Labels/TextLoading';
import TextHead from '../UIKit/Labels/TextHead';
import TextHeadProduct from '../UIKit/Labels/TextHeadProduct';
import Filter from './components/Filter';
import { FilterContext } from '../../context/FilterContext';
import translate from '../../i18n/translate';
import { fetchSimpleProductsByCars, fetchGroupedProducts } from '../../api';

export default function Shop(props) {
  const [filter] = useContext(FilterContext);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [groupedHighlightedProducts, setGroupedHighlightedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setSelectedLanguage(props.selectedLocale);
  }, [props]);

  useEffect(() => {
    async function fetchFilteredData() {
      const getJson = await fetchSimpleProductsByCars(filter);
      setFilteredProducts(getJson);
      setLoading(false);
    }
    if (filter.type.length > 0 || filter.categoryId.length > 0) {
      fetchFilteredData();
    } else {
      setFilteredProducts([]);
      setLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    async function fetchProducts() {
      const getJson = await fetchGroupedProducts();
      setGroupedHighlightedProducts(getJson);
      setLoading(false);
    }
    fetchProducts();
  }, []);

  if (loading)
    return (
      <>
        <Filter />
        <TextLoading />
      </>
    );

  if (filteredProducts.length === 0 && filter.type.length === 0 && !loading) {
    return (
      <>
        <Filter />
        <div className="container mx-auto">
          <div className="flex justify-center w-full">
            <img
              loading="lazy"
              src="/images/shop/shop_splash.png"
              alt="cma1stock0"
              className="mt-8 mb-16 h-80 justify-center object-cover"
            />
          </div>
          {groupedHighlightedProducts.length > 0 && (
            <div className="mx-2">
              <TextHead text={translate('highlighted')} />
              <div className="flex flex-col space-y-4">
                {groupedHighlightedProducts.map((groupedProduct) => (
                  <div key={groupedProduct.id}>
                    <TextHeadProduct text={groupedProduct.displayName} />
                    <div className="flex flex-wrap">
                      {groupedProduct.products.map((product) => (
                        <div
                          className="lg:w-1/4 md:w-1/2 w-full px-4 pb-4"
                          key={`${groupedProduct.id}_${product.id}`}
                        >
                          <Link
                            to={'/shop/' + product.slug}
                            className="my-2 px-2 overflow-hidden"
                          >
                            <Product
                              product={product}
                              selectedLocale={selectedLanguage}
                            />
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else if (
    filteredProducts.length === 0 &&
    filter.type.length > 0 &&
    !loading
  ) {
    return (
      <>
        <Filter />
        <div className="container mx-auto">
          <span className="w-full my-12 px-2 block text-lg text-center">
            {translate('shop_comeBackLater')}
          </span>
          {groupedHighlightedProducts.length > 0 && (
            <div className="mx-2">
              <TextHead text={translate('highlighted')} />
              <div className="flex flex-col space-y-4">
                {groupedHighlightedProducts.map((groupedProduct) => (
                  <div key={groupedProduct.id}>
                    <TextHeadProduct text={groupedProduct.displayName} />
                    <div className="flex flex-wrap">
                      {groupedProduct.products.map((product) => (
                        <div
                          className="lg:w-1/4 md:w-1/2 w-full px-4 pb-4"
                          key={`${groupedProduct.id}_${product.id}`}
                        >
                          <Link
                            to={'/shop/' + product.slug}
                            className="my-2 px-2 overflow-hidden"
                          >
                            <Product
                              product={product}
                              selectedLocale={selectedLanguage}
                            />
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Filter />
        <div className="flex flex-wrap container mx-auto">
          {filteredProducts.length > 0 &&
            filteredProducts.map((product) => (
              <div className="lg:w-1/4 md:w-1/2 w-full p-4" key={product.id}>
                <Link
                  to={'/shop/' + product.slug}
                  className="my-2 px-2 overflow-hidden"
                >
                  <Product
                    product={product}
                    selectedLocale={selectedLanguage}
                  />
                </Link>
              </div>
            ))}
        </div>
      </>
    );
  }
}
