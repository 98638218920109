import React from 'react';
import { FormattedMessage } from 'react-intl';

// `value` is used to have formatted strings with parameters
const translate = (id, value = {}) => (
  <FormattedMessage id={id} values={{ ...value }} />
);

export default translate;

/*
 ? USAGE (assuming selected language is dutch)
 ? ===========================================
    import translate from 'path/to/translate';

    ? EXAMPLE 1:
    ! E.g. in i18n/messages/nl-BE.js
        ! ~ { welcome: 'Welkom op onze website' }
    <p>{translate('welcome')}</p>
    ^^^^^ RESULT: <p>Welkom op onze website</p>

    ? EXAMPLE 2:
    ! E.g. in i18n/messages/nl-BE.js
        ! ~ { welcome: 'Welkom {name}' }
    <p>{translate('welcome', {name: "Steven"})}</p> <!-- `name` corresponds with the item specified in the translation file -->
    ^^^^^ RESULT: <p>Welkom Steven</p>

    can also be used with html tags:
    {translate('welcome', {name: "<code>Steven</code>"})}
*/
