import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toastSuccess, toastError } from '../../UIKit/Toastify';
import { textToHTML } from '../../../utils';
import translate from '../../../i18n/translate';
import {
  fetchExtraInfos,
  postExtraInfo,
  putExtraInfo,
  deleteExtraInfo,
} from '../../../api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '850px',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

export default function AdminExtraInfo() {
  const [extraInfos, setExtraInfos] = useState([]);
  const [newExtraInfoNL, setNewExtraInfoNL] = useState('');
  const [newExtraInfoEN, setNewExtraInfoEN] = useState('');
  const [newExtraInfoDE, setNewExtraInfoDE] = useState('');
  const [newExtraInfoFR, setNewExtraInfoFR] = useState('');
  const [newPrefix, setNewPrefix] = useState('');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [updatingExtraInfoId, setUpdatingExtraInfoId] = useState('');

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    resetInputFields();
    setModalIsOpen(false);
  }

  function handleUpdateExtraInfo(extraInfo) {
    setNewExtraInfoNL(extraInfo.nameNl);
    setNewExtraInfoDE(extraInfo.nameDe);
    setNewExtraInfoFR(extraInfo.nameFr);
    setNewExtraInfoEN(extraInfo.nameEn);
    setNewPrefix(extraInfo.prefix);
    setUpdatingExtraInfoId(extraInfo.id);
    openModal();
  }

  async function handleDeleteExtraInfo(id) {
    const deleteJson = await deleteExtraInfo(id);
    if (deleteJson.success === true) {
      const filtered = extraInfos.filter((info) => {
        return info.id !== id;
      });
      setExtraInfos(filtered);
      toastSuccess(translate('delete success'));
    } else {
      toastError(deleteJson.error);
      console.error(deleteJson);
    }
  }

  useEffect(() => {
    async function fetchExtraInfoData() {
      const getJson = await fetchExtraInfos();
      setExtraInfos(getJson);
    }
    fetchExtraInfoData();
  }, []);

  async function onSubmitPressed(e) {
    e.preventDefault();

    const jsonBody = JSON.stringify({
      nameNl: newExtraInfoNL.trim(),
      nameEn: newExtraInfoEN.trim(),
      nameDe: newExtraInfoDE.trim(),
      nameFr: newExtraInfoFR.trim(),
      prefix: newPrefix,
    });
    if (updatingExtraInfoId.length > 0) {
      // updating
      const putJson = await putExtraInfo(updatingExtraInfoId, jsonBody);
      if (putJson.success === true) {
        const filtered = extraInfos.filter(
          (info) => info.id === updatingExtraInfoId
        )[0];
        const index = extraInfos.indexOf(filtered);
        let editingExtraInfo = extraInfos;
        editingExtraInfo[index] = putJson.data;
        setExtraInfos(editingExtraInfo);
        toastSuccess(translate('successful updated'));
        closeModal();
      } else {
        toastError(putJson.error);
        console.error(putJson);
      }
    } else {
      // creating
      const postJson = await postExtraInfo(jsonBody);
      if (postJson.success === true) {
        setExtraInfos((previous) => [...previous, postJson.data]);
        toastSuccess(translate('successful added'));
        closeModal();
      } else {
        toastError(postJson.error);
        console.error(postJson);
      }
    }
  }

  function resetInputFields() {
    setNewExtraInfoNL('');
    setNewExtraInfoDE('');
    setNewExtraInfoFR('');
    setNewExtraInfoEN('');
    setNewPrefix('');
    setUpdatingExtraInfoId('');
  }

  return (
    <div>
      {/* http://reactcommunity.org/react-modal/ */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal popup"
      >
        <div className="w-full">
          <div className="p-2 mb-2 w-full">
            {updatingExtraInfoId.length > 0 ? (
              <h2 className="text-center w-full">Extra info aanpassen</h2>
            ) : (
              <h2 className="text-center w-full">Extra info toevoegen</h2>
            )}
          </div>

          <form className="w-full" onSubmit={onSubmitPressed}>
            <div className="w-full flex flex-wrap -mx-3">
              <div className="w-full px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-nl"
                >
                  Name NL
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-nl"
                  id="name-nl"
                  value={newExtraInfoNL}
                  onChange={(e) => setNewExtraInfoNL(e.target.value)}
                  required
                  autoFocus
                ></textarea>
              </div>
              <div className="w-full my-4 px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-fr"
                >
                  Name FR
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-fr"
                  id="name-fr"
                  value={newExtraInfoFR}
                  onChange={(e) => setNewExtraInfoFR(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="w-full my-4 px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-en"
                >
                  Name EN
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-en"
                  id="name-en"
                  value={newExtraInfoEN}
                  onChange={(e) => setNewExtraInfoEN(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="w-full my-4 px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name-de"
                >
                  Name DE
                </label>
                <textarea
                  className="appearance-none resize-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="6"
                  name="name-de"
                  id="name-de"
                  value={newExtraInfoDE}
                  onChange={(e) => setNewExtraInfoDE(e.target.value)}
                  required
                ></textarea>
              </div>

              <div className="w-full my-4 px-3 py-1 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="prefix"
                >
                  Prefix
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded p-3 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="prefix"
                  id="prefix"
                  type="text"
                  value={newPrefix}
                  onChange={(e) => setNewPrefix(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="w-full mt-6 mb-4 flex justify-end">
              <button
                type="button"
                onClick={() => closeModal(false)}
                className="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
              >
                {translate('cancel')}
              </button>

              <button
                type="button"
                onClick={(e) => onSubmitPressed(e)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded"
              >
                {updatingExtraInfoId.length > 0
                  ? translate('edit')
                  : translate('save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold leading-tight">
          {translate('extraInfo')}
        </h2>
        <button
          className="px-4 py-2 leading-none rounded bg-green-400 text-white hover:bg-green-600"
          onClick={() => openModal()}
        >
          {translate('new')}
        </button>
      </div>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Prefix
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Naam
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {extraInfos.length > 0 &&
                extraInfos.map((extraInfo) => {
                  return (
                    <tr key={extraInfo.id}>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {extraInfo.prefix}
                        </p>
                      </td>
                      <td className="py-3 px-5 border-b border-gray-200 bg-white text-sm">
                        <p
                          className="text-gray-900 whitespace-no-wrap"
                          dangerouslySetInnerHTML={{
                            __html: textToHTML(
                              extraInfo.nameNl.substring(0, 200)
                            ),
                          }}
                        />
                      </td>
                      <td className="py-3 px-5 bg-white border-b border-gray-200 text-sm flex justify-end">
                        <button
                          type="button"
                          onClick={() => handleUpdateExtraInfo(extraInfo)}
                          className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('edit')}
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteExtraInfo(extraInfo.id)}
                          className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          {translate('delete')}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
