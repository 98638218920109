import React from 'react';
import TextLabel from '../Labels/TextLabel';

export default function LabeledInputFieldNumber({
  name = 'input-field-number',
  label,
  value,
  placeholder,
  onChange,
  required,
  disabled = false,
  min = 0,
  max = 999999,
}) {
  return (
    <div className="w-full py-3">
      {label !== undefined && <TextLabel name={name} label={label} />}
      <input
        className={
          'w-full p-3 rounded ' +
          (disabled ? 'cursor-not-allowed text-gray-600 bg-gray-100' : 'border')
        }
        name={name}
        id={name}
        type="number"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        min={min}
        max={max}
      />
    </div>
  );
}
