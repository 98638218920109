import React, { useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import translate from '../i18n/translate';
import { postContact } from '../api';
import { toastSuccess, toastError } from './UIKit/Toastify';
import ButtonRoutable from './UIKit/Buttons/ButtonRoutable';
import LabeledInputFieldText from './UIKit/Inputs/LabeledInputFieldText';
import LabeledInputAreaText from './UIKit/Inputs/LabeledInputAreaText';
import TextHead from './UIKit/Labels/TextHead';
import TextLabel from './UIKit/Labels/TextLabel';
import ButtonActionPrimary from './UIKit/Buttons/ButtonActionPrimary';

export default function Contact() {
  const [user] = useContext(UserContext);
  const [message, setMessage] = useState('');

  const [car, setCar] = useState('');
  const [howDoYouKnowCMA, setHowDoYouKnowCMA] = useState('');
  const [sendCopy, setSendCopy] = useState(false);

  async function onSubmitPressed(e) {
    e.preventDefault();
    const postJson = await postContact(
      JSON.stringify({
        message,
        car,
        howDoYouKnowCMA,
        sendCopy,
      })
    );

    if (postJson.success === true) {
      toastSuccess(translate('toast_mailSuccess'));
      resetInputFields();
    } else {
      toastError(translate('toast_mailFailed'));
      console.error(postJson);
    }
  }

  function resetInputFields() {
    setMessage('');
    setCar('');
    setHowDoYouKnowCMA('');
    setSendCopy(false);
  }

  return (
    <div className="container mx-auto mt-8 px-4">
      {user.id === undefined ? (
        <>
          <p className="my-4">
            You need to be logged in to use the contact form
          </p>
          <ButtonRoutable to="/login" text={translate('login')} />
        </>
      ) : (
        <form className="w-full max-w-lg" onSubmit={onSubmitPressed}>
          <TextHead text={translate('contact')} center={false} />
          <LabeledInputAreaText
            name="message"
            label={translate('contact_message')}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required={true}
          />
          <LabeledInputFieldText
            name="car"
            type="text"
            label={translate('contact_whichCarQuestion')}
            value={car}
            onChange={(e) => setCar(e.target.value)}
            required={true}
          />
          <div className="pt-3 mb-6">
            <TextLabel
              name="howDoYouKnowCMA"
              label={translate('contact_howDoYouKnowCMA')}
            />
            {inputRadio('Facebook', 'Facebook')}
            {inputRadio('Instagram', 'Instagram')}
            {inputRadio(
              'previous_purchase',
              translate('contact_previousPurchase')
            )}
            {inputRadio('a_friend', translate('contact_aFriend'))}
            {inputRadio('a_car_meet', translate('contact_aCarMeet'))}
          </div>
          <div className="flex items-center mt-12 mb-6">
            <ButtonActionPrimary text={translate('send')} type="submit" />
            <label className="inline-flex items-center mx-3 cursor-pointer">
              <input
                type="checkbox"
                className="h-6 w-6 cursor-pointer"
                checked={sendCopy}
                onChange={(e) => setSendCopy(e.target.checked)}
              />
              <span className="ml-2">{translate('contact_sendMeACopy')}</span>
            </label>
          </div>
        </form>
      )}
    </div>
  );
  function inputRadio(key, label) {
    return (
      <span
        className="flex space-x-2 cursor-pointer"
        onClick={() => setHowDoYouKnowCMA(key)}
      >
        <input
          type="radio"
          value={key}
          readOnly
          checked={howDoYouKnowCMA === key}
          className="cursor-pointer"
        />
        <span>{label}</span>
      </span>
    );
  }
}
