import React from 'react';
import { Link } from 'react-router-dom';

/**
 * A routable button component that navigates to another page
 * @param {string} to - Route to navigate to
 * @param {string} text - Text displayed in the button
 */
export default function ButtonRoutableOutlineWhite({ to, text }) {
  return (
    <Link to={to}>
      <span className="border-2 border-white text-white hover:bg-white hover:text-black font-bold py-2 px-4">
        {text}
      </span>
    </Link>
  );
}
