import {
  endpoints,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../helpers';

// !=============!
//   !PRODUCTS!
// !=============!

/**
 * Fetch all products with full data
 */
export async function fetchProducts() {
  return await getRequest(endpoints.PRODUCTS);
}

/**
 * Fetch all products with a limited dataset
 */
export async function fetchSimpleProducts() {
  return await getRequest(endpoints.PRODUCTSSIMPLE);
}

/**
 * Fetch all products, grouped by cars
 */
export async function fetchGroupedProducts() {
  return await getRequest(endpoints.PRODUCTSGROUPED);
}

/**
 * GET - Fetch products based on ids with a limited dataset. This response also includes all related car data.
 */
export async function fetchSimpleProductsMyBasket() {
  return await getRequest(endpoints.PRODUCTSSIMPLEMYBASKET);
}

/**
 * GET - Fetch products based on car query with a limited dataset.
 * @param {any} filter - Object containing `carId`, `categoryId` and `subcategoryId`
 */
export async function fetchSimpleProductsByCars(filter) {
  let queryItemPrefix = '?';
  let queryString = '';
  if (filter.carId && filter.carId.length) {
    queryString = `${queryString}${queryItemPrefix}carId=${filter.carId}`;
    queryItemPrefix = '&';
  }
  if (filter.categoryId && filter.categoryId.length) {
    queryString = `${queryString}${queryItemPrefix}categoryId=${filter.categoryId}`;
    queryItemPrefix = '&';
  }
  if (filter.subcategoryId && filter.subcategoryId.length) {
    queryString = `${queryString}${queryItemPrefix}subcategoryId=${filter.subcategoryId}`;
    queryItemPrefix = '&';
  }
  return await getRequest(`${endpoints.PRODUCTSSIMPLEBYCARS}${queryString}`);
}

/**
 * GET - Fetch highlighted products with a limited dataset.
 */
export async function fetchSimpleHighlightedProducts() {
  return await getRequest(endpoints.PRODUCTSHIGHLIGHTS);
}

/**
 * Fetch a single product based on the given slug value
 * @param {string} slug - Slug value of a product
 */
export async function fetchProductDetails(slug) {
  return await getRequest(`${endpoints.PRODUCTDETAILS}/${slug}`);
}

/**
 * Delete a single product based on the given id value
 * @param {string} id - Id of a product. This is a UUID value.
 */
export async function deleteProduct(id) {
  return await deleteRequest(`${endpoints.PRODUCTS}/${id}`);
}

/**
 * Post a new product
 * @param {json} body - Stringified JSON data containing product key-values
 */
export async function postProduct(body) {
  return await postRequest(endpoints.PRODUCTS, body);
}

/**
 * Put an existing product
 * @param {string} id - Id of a product. This is a UUID value.
 * @param {json} body - Stringified JSON data containing product key-values
 */
export async function putProduct(id, body) {
  return await putRequest(`${endpoints.PRODUCTS}/${id}`, body);
}

/**
 * Put a new product photo and updates the product related to provided id argument.
 * @param {string} id - Id of a product. This is a UUID value.
 * @param {FormData} formData - FormData containing all images
 */
export async function putProductPhoto(id, formData) {
  return await putRequest(
    `${endpoints.PRODUCTS}/${id}${endpoints.PHOTO}`,
    formData,
    false
  );
}
