import {
  endpoints,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '../helpers';

// !================!
//    !EXTRA INFO!
// !================!

/**
 * Fetch all extra infos
 */
export async function fetchExtraInfos() {
  return await getRequest(endpoints.EXTRAINFO);
}

/**
 * Fetch a single extra info
 * @param {string} id - Id of a extra info. This is a UUID value.
 */
export async function fetchExtraInfo(id) {
  return await getRequest(`${endpoints.EXTRAINFO}/${id}`);
}

/**
 * Post a new extra info
 * @param {json} body - Stringified JSON data containing extra info key-values
 */
export async function postExtraInfo(body) {
  return await postRequest(endpoints.EXTRAINFO, body);
}

/**
 * Put an existing extra info
 * @param {string} id - Id of a extra info. This is a UUID value.
 * @param {json} body - Stringified JSON data containing extra info key-values
 */
export async function putExtraInfo(id, body) {
  return await putRequest(`${endpoints.EXTRAINFO}/${id}`, body);
}

/**
 * Delete a single extra info based on the given id value
 * @param {string} id - Id of a extra info. This is a UUID value.
 */
export async function deleteExtraInfo(id) {
  return await deleteRequest(`${endpoints.EXTRAINFO}/${id}`);
}
