import React from 'react';

const Approved = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap mb-20">
        {/* left side */}
        <div className="w-2/5">
          <ul>
            <li>
              <h2>Steven Verheyen inc.</h2>
            </li>
            <li>Schuinestraat 1</li>
            <li>BE-3582 Koersel</li>
            <li>
              <h3>Gsm</h3>
            </li>
            <li>
              <a href="tel:0032491881189">+32 (0) 491 88 11 89</a> (Steven)
            </li>
            <li>
              <h3>E-mail</h3>
            </li>
            <li>
              <a href="mailto:info@stevenverheyen.be">info@stevenverheyen.be</a>
            </li>
            <li>
              <h3>Opening Hours</h3>
            </li>
            <li>Alle dagen open</li>
            <li>
              <h3>Website</h3>
            </li>
            <li>
              <a href="http://www.stevenverheyen.be" rel="noopener noreferrer">
                www.stevenverheyen.be
              </a>
            </li>
          </ul>
        </div>

        {/* right side */}
        <div className="w-3/5">
          <img
            loading="lazy"
            className="object-contain h-64 w-full"
            src="./logo512.png"
            alt="test"
          />
        </div>
      </div>

      {/* REMOVE REMOVE REMOVE REMOVE REMOVE REMOVE */}
      <div className="flex flex-row flex-wrap mb-20">
        {/* left side */}
        <div className="w-2/5">
          <ul>
            <li>
              <h2>Steven Verheyen inc.</h2>
            </li>
            <li>Schuinestraat 1</li>
            <li>BE-3582 Koersel</li>
            <li>
              <h3>Gsm</h3>
            </li>
            <li>
              <a href="tel:0032491881189">+32 (0) 491 88 11 89</a> (Steven)
            </li>
            <li>
              <h3>E-mail</h3>
            </li>
            <li>
              <a href="mailto:info@stevenverheyen.be">info@stevenverheyen.be</a>
            </li>
            <li>
              <h3>Opening Hours</h3>
            </li>
            <li>Alle dagen open</li>
            <li>
              <h3>Website</h3>
            </li>
            <li>
              <a href="http://www.stevenverheyen.be" rel="noopener noreferrer">
                www.stevenverheyen.be
              </a>
            </li>
          </ul>
        </div>

        {/* right side */}
        <div className="w-3/5">
          <img
            className="object-contain h-64 w-full"
            src="./logo512.png"
            alt="test"
          />
        </div>
      </div>

      {/* REMOVE REMOVE REMOVE REMOVE REMOVE REMOVE */}
      <div className="flex flex-row flex-wrap mb-20">
        {/* left side */}
        <div className="w-2/5">
          <ul>
            <li>
              <h2>Steven Verheyen inc.</h2>
            </li>
            <li>Schuinestraat 1</li>
            <li>BE-3582 Koersel</li>
            <li>
              <h3>Gsm</h3>
            </li>
            <li>
              <a href="tel:0032491881189">+32 (0) 491 88 11 89</a> (Steven)
            </li>
            <li>
              <h3>E-mail</h3>
            </li>
            <li>
              <a href="mailto:info@stevenverheyen.be">info@stevenverheyen.be</a>
            </li>
            <li>
              <h3>Opening Hours</h3>
            </li>
            <li>Alle dagen open</li>
            <li>
              <h3>Website</h3>
            </li>
            <li>
              <a href="http://www.stevenverheyen.be" rel="noopener noreferrer">
                www.stevenverheyen.be
              </a>
            </li>
          </ul>
        </div>

        {/* right side */}
        <div className="w-3/5">
          <img
            className="object-contain h-64 w-full"
            src="./logo512.png"
            alt="test"
          />
        </div>
      </div>

      {/* REMOVE REMOVE REMOVE REMOVE REMOVE REMOVE */}
      <div className="flex flex-row flex-wrap mb-20">
        {/* left side */}
        <div className="w-2/5">
          <ul>
            <li>
              <h2>Steven Verheyen inc.</h2>
            </li>
            <li>Schuinestraat 1</li>
            <li>BE-3582 Koersel</li>
            <li>
              <h3>Gsm</h3>
            </li>
            <li>
              <a href="tel:0032491881189">+32 (0) 491 88 11 89</a> (Steven)
            </li>
            <li>
              <h3>E-mail</h3>
            </li>
            <li>
              <a href="mailto:info@stevenverheyen.be">info@stevenverheyen.be</a>
            </li>
            <li>
              <h3>Opening Hours</h3>
            </li>
            <li>Alle dagen open</li>
            <li>
              <h3>Website</h3>
            </li>
            <li>
              <a href="http://www.stevenverheyen.be" rel="noopener noreferrer">
                www.stevenverheyen.be
              </a>
            </li>
          </ul>
        </div>

        {/* right side */}
        <div className="w-3/5">
          <img
            className="object-contain h-64 w-full"
            src="./logo512.png"
            alt="test"
          />
        </div>
      </div>
    </div>
  );
};

export default Approved;
