import React, { useState, useEffect } from 'react';
import TextLoading from '../../UIKit/Labels/TextLoading';
import TextHeadProduct from '../../UIKit/Labels/TextHeadProduct';
import { discountAmount } from '../../../utils';
import { useIntl } from 'react-intl';

export default function Product(props) {
  const [product, setProduct] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [imgSrc, setImgSrc] = useState('');
  const [loading, setLoading] = useState(true);

  const [translatedTitle, setTranslatedTitle] = useState('');
  const intl = useIntl();

  useEffect(() => {
    if (props.product.photo) {
      setImgSrc('/uploads/products/thumbnails/' + props.product.photo);
    } else {
      setImgSrc('/uploads/products/template.jpg');
    }
    setProduct(props.product);
    setSelectedLanguage(props.selectedLocale);
  }, [props]);

  useEffect(() => {
    setLoading(false);
  }, [product]);

  useEffect(() => {
    if (selectedLanguage.length === 0) {
      // language not read yet
      return;
    }
    if (product.id) {
      switch (selectedLanguage) {
        case 'en-US':
          setTranslatedTitle(product.titleEn);
          break;
        case 'de-DE':
          setTranslatedTitle(product.titleDe);
          break;
        case 'fr-BE':
          setTranslatedTitle(product.titleFr);
          break;
        default:
          setTranslatedTitle(product.titleNl);
      }
    }
  }, [product, selectedLanguage]);

  if (loading) return <TextLoading />;

  return (
    <div className="xl:w-72 rounded-lg cursor-pointer border hover:border-gray-500 overflow-hidden flex flex-col items-center">
      {parseInt(product.weight) === 0 && (
        <div className="bg-cmagreen w-full h-6 flex justify-center items-center text-white text-xs font-bold">
          {intl.formatMessage({ id: 'details_freeShipment' })}
        </div>
      )}

      <img
        loading="lazy"
        className="w-full h-40 object-contain mt-3 px-2"
        src={imgSrc}
        alt="Product"
      />

      <TextHeadProduct text={translatedTitle} center={true} />

      <div className="w-full m-2 flex items-center justify-center">
        <span
          className={
            product.priceDiscount
              ? 'line-through text-gray-500'
              : 'text-lg font-bold text-center text-gray-800 mb-5'
          }
        >
          € {product.price}
        </span>
        {product.priceDiscount && (
          <span className="text-lg font-bold text-gray-800 mx-1">
            € {product.priceDiscount}
          </span>
        )}
      </div>
      {product.priceDiscount && (
        <div className="bg-cmagreen w-full h-6 flex justify-center items-center text-white text-xs font-bold">
          -{discountAmount(product.priceDiscount, product.price)} EUR
        </div>
      )}
    </div>
  );
}
