import { endpoints, postRequest } from '../helpers';

// !===========!
//   !CONTACT!
// !===========!

/**
 * Send a mail to a registered email address on the server
 * @param {json} body - Stringified JSON data containing contact key-values
 */
export async function postContact(body) {
  return await postRequest(endpoints.CONTACT, body);
}
