import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { BasketContext } from '../../context/BasketContext';
import Profile from './components/Profile';
import OrderHistory from './components/OrderHistory';
import { postLogout } from '../../api';
import translate from '../../i18n/translate';
import { FaUser, FaWallet } from 'react-icons/fa';
import ButtonActionSignout from '../UIKit/Buttons/ButtonActionSignout';
import TextHead from '../UIKit/Labels/TextHead';
import { toastError } from '../UIKit/Toastify';

export default function User(props) {
  const [, setUser] = useContext(UserContext);
  const [, setBasket] = useContext(BasketContext);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    setSelectedLanguage(props.selectedLocale);
  }, [props]);

  const handleSideTap = (id) => {
    setSelectedItem(id);
  };

  async function signOut() {
    const postJson = await postLogout();
    if (postJson.success === true) {
      setUser({});
      setBasket([]);
      props.history.push('/shop');
    } else {
      toastError(translate('toast_logoutFailed'));
      console.error(postJson);
    }
  }

  const panelItems = [
    { icon: <FaUser />, title: 'sidebar_profile' },
    { icon: <FaWallet />, title: 'sidebar_orderHistory' },
  ];

  return (
    <div className="sm:flex flex-col sm:flex-row w-full">
      <div className="sm:w-64">
        <nav className="flex-grow sm:block px-4 pb-4 sm:pb-0 sm:overflow-y-auto">
          <TextHead text={translate('sidebar_profile')} center={true} />
          {panelItems.map((item, index) => {
            return (
              <button
                className="inline-flex focus:outline-none items-center w-full text-left px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-lg hover:bg-gray-400"
                onClick={() => handleSideTap(index)}
                key={item.title}
              >
                <span className="mr-2">{item.icon}</span>
                <span className="pr-2">{translate(item.title)}</span>
              </button>
            );
          })}
          <div className="mt-2">
            <ButtonActionSignout
              text={translate('signOut')}
              handleClick={signOut}
            />
          </div>
        </nav>
      </div>

      <div className="w-full sm:ml-3 px-4">
        {selectedItem === 0 && <Profile />}
        {selectedItem === 1 && <OrderHistory selectedLanguage={selectedLanguage} />}
      </div>
    </div>
  );
}
