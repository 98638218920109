import React, { useState } from 'react';
import translate from '../../i18n/translate';
import { postForgotPassword } from '../../api';
import { toastSuccess, toastError } from '../UIKit/Toastify';
import ButtonActionSecondary from '../UIKit/Buttons/ButtonActionSecondary';
import ButtonRoutableClear from '../UIKit/Buttons/ButtonRoutableClear';
import LabeledInputFieldText from '../UIKit/Inputs/LabeledInputFieldText';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');

  async function onSubmitPressed(e) {
    e.preventDefault();
    const postJson = await postForgotPassword(JSON.stringify({ email }));
    if (postJson.success === true) {
      toastSuccess(translate('toast_forgotPasswordSuccess'));
      resetInputFields();
    } else {
      toastError(postJson.error);
      console.error(postJson);
    }
  }

  function resetInputFields() {
    setEmail('');
  }

  return (
    <div className="mx-auto max-w-sm py-8 px-4">
      <div className="bg-white rounded shadow">
        <div className="border-b py-8 font-bold text-black text-center text-xl tracking-widest uppercase">
          {translate('forgotpassword_title')}
        </div>

        <form
          className="sm:mx-10 mx-4 my-8"
          onSubmit={(e) => onSubmitPressed(e)}
        >
          <LabeledInputFieldText
            name="email"
            type="email"
            label={translate('email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="jane@doe.com"
            required={true}
          />

          <div className="flex justify-between items-center py-6">
            <ButtonActionSecondary
              text={translate('resetPassword')}
              type="submit"
            />
            <ButtonRoutableClear
              to="/sign-up"
              text={translate('login_dontHaveAnAccount')}
              right={true}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
