import React, { useState, useContext } from 'react';
import { BasketContext } from '../../../context/BasketContext';
import Progress from '../../UIKit/Progress';
import { useEffect } from 'react';
import { fetchOrderStatus } from '../../../api';
import translate from '../../../i18n/translate';
import { toastError } from '../../UIKit/Toastify';

export default function Confirmation({ match }) {
  const [basket, setBasket] = useContext(BasketContext);
  const [orderStatus, setOrderStatus] = useState('OPEN');
  const [statusChecked, setStatusChecked] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const orderId = match.params.id;
      const getJson = await fetchOrderStatus(orderId);
      if (getJson.success === true) {
        setBasket([]);
        setOrderStatus(getJson.data);
        setStatusChecked(true);
      } else {
        toastError(getJson.error);
        console.error(getJson);
      }
    }
    fetchData();
  }, [match.params.id]);

  return (
    <div className="container mx-auto mt-8 px-4">
      <Progress step={3} />

      {statusChecked === true && (
        <div className="w-full mt-12 leading-normal">
          {orderStatus === 'PAID' && (
            <>
              <h2 className="text-2xl font-semibold my-4">
                {translate('basket_orderSuccessTitle')}
              </h2>
              <p>{translate('basket_orderSuccessDescription')}</p>
              {/* <h2 className="text-2xl font-semibold mt-8 my-4">Overzicht</h2>
        <p>lijst producten hier...</p> */}
            </>
          )}
          {orderStatus === 'OPEN' && (
            <>
              <h2 className="text-2xl font-semibold leading-tight my-4">
                {translate('basket_orderProcessingTitle')}
              </h2>
              <p>{translate('basket_orderProcessingDescription')}</p>
            </>
          )}
          {orderStatus === 'FAILED' && (
            <>
              <h2 className="text-2xl font-semibold leading-tight my-4">
                {translate('basket_orderFailedTitle')}
              </h2>
              <p>{translate('basket_orderFailedDescription')}</p>
            </>
          )}
        </div>
      )}
    </div>
  );
}
